import React, { useContext, useState, useRef, useCallback, useEffect } from "react";

// Util
import { Handle } from "reactflow";
import {useParams, useNavigate} from "react-router";
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
import { hexToPastel } from "../../../../../../utils/HexToPastelUtil";

// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";

// Custom components
import NodeDescription from "../../../NodeDescription";
import CanvasReloadDialog from "../../../CanvasReloadDialog";
import NodeSettingsMenuBar from './ComponentNodeUtil/NodeSettingsMenuBar';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';

// MUI
import { Tooltip, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

// Icons
import Error from "@mui/icons-material/Error";

// MobX
import { inject, observer } from "mobx-react";
import InstructionsInfo from "../../../InstructionInfo";
import { COMPONENT_ICON_ALT } from "../../../../../../utils/CanvasConstants";
import {CustomDialog} from "../../../../../Component/Dialog";

import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";

import { PRODUCTION_CANVAS_VIEW_MODE, CAN_OPEN_IN_NEW_TAB } from "../../../../../../utils/CanvasConstants";

const ProductionCanvasComponent = inject("CanvasStore")(
  observer(({ id, data, classes, selected, CanvasStore }) => {

    const { modeInfo } = useContext(CanvasContext);
    if (modeInfo === undefined) {
      return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
    }

    const parentRef = useRef(null);
    const { nodes } = CanvasStore;
    const [mode, setMode] = modeInfo;

    useCallback((newInstruction) => {
      const updatedCanvas = nodes.map((component) =>
          component.id === id
              ? { ...component, data: { ...component.data, instruction: newInstruction } }
              : component
      );
      CanvasStore.setNodes(updatedCanvas);
    }, [nodes, id, CanvasStore]);

    return (
        <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
        >
          <div className={classes.nodeWrapper}>
            {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
            )}
            <ComponentNodeView
              classes={classes}
              data={data}
              selected={selected}
              mode={mode}
            />

          </div>
        </div>
    );
  })
);

export default withStyles(styles)(ProductionCanvasComponent);
