import React from "react";
import { Fade, Grid, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { AutoAwesome, Warning, Output, Storage, VpnKey } from "@mui/icons-material";
import './initialMessages.css';
import ContextChips from "./ContextChips";

const BuildComponentMessage = ({
  classes,
  sidebarState,
  setSidebarState,
  messageSent,
}) => {
  const contextChips = [
    { 
      label: "Outputs from all connected components",
      tooltip: "I am aware of all placeholders from upstream components connected into this component.",
      icon: <Output fontSize="small" />
    },
    { 
      label: "Active and connected databases",
      tooltip: "I am aware of any databases actively being used by a component in this workflow, or connected but not actively used. I can choose to use these databases if appropriate.",
      icon: <Storage fontSize="small" />
    },
    { 
      label: "Secrets and credentials",
      tooltip: "I will be aware of any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
      icon: <VpnKey fontSize="small" />
    }
  ];

  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <span className={"bold"}>
                <AutoAwesome
                  className="autoAwesomeIcon"
                />
                Build New Component
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            I will build a new component based on your instructions. Note this will overwrite any existing configuration or settings in the component.
          </Grid>

          <Grid item xs={12}>
            <div className="warningBanner">
              <Warning color="warning" />
              <Typography variant="body2">
                This action will overwrite any existing configuration of the component.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} className="messagePadding">
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            For the best results, include as much information as possible or be
            specific with your requirements for you what you want this component
            to do.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>ℹ️ Context</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
               I will have access to the following context when building this component:
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <ContextChips chips={contextChips} />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

BuildComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
};

export default withStyles(AIBuilderStyles())(BuildComponentMessage);
