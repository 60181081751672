import React from "react";
import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)({
    fontSize: '11px',
    fontWeight: '400 !important',
    lineHeight: '18px',
    textAlign: 'left',
    fontStyle: 'italic !important',
    color: '#00000080'
});

const InstructionsInfo = ({ data }) => {
    const renderInstructionFields = () => {
        return Object.keys(data.instructions).map((instructionKey) => {
            if (data.instructions[instructionKey]) {
                return (
                    <Grid item direction="column" key={instructionKey}>
                      <StyledTypography>
                        {"🪄 " + data.instructions[instructionKey]}
                      </StyledTypography>
                    </Grid>
                );
            } else {
                return null;
            }
        });
    };

    return renderInstructionFields();
}

export default InstructionsInfo;
