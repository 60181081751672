import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from '@mui/material/styles';

const ModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90%',
  backgroundColor: '#fff',
  boxShadow: 24,
  borderRadius: '10px',
  padding: 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const StyledAppBar = styled(AppBar)({
  maxHeight: '56px',
  backgroundColor: '#f1f3f4',
  color: '#000',
});

const StyledToolbar = styled(Toolbar)({
  minHeight: '56px',
  height: '56px',
  padding: '0 16px',
});

const StyledTextField = styled(TextField)({
  flexGrow: 1,
  marginRight: '8px',
  '& .MuiInputBase-root': {
    height: '36px',
    backgroundColor: '#fff',
    borderRadius: '18px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

const ActionButton = styled(IconButton)({
  width: '36px',
  height: '36px',
  marginLeft: '8px',
});

const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
  display: 'block',
  flexGrow: 1,
});

const PortalModal = ({ open, onClose, url }) => {
  const copyUrl = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="form-modal"
      aria-describedby="form-in-modal"
    >
      <ModalBox>
        <StyledAppBar position="static" elevation={0}>
          <StyledToolbar>
            <StyledTextField
              value={url}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <ActionButton
              color="inherit"
              onClick={copyUrl}
              aria-label="copy url"
            >
              <ContentCopyIcon />
            </ActionButton>
            <ActionButton
              color="inherit"
              onClick={() => window.open(url, '_blank')}
              aria-label="open in new tab"
            >
              <OpenInNewIcon />
            </ActionButton>
            <ActionButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </ActionButton>
          </StyledToolbar>
        </StyledAppBar>
        <StyledIframe
          src={url}
          title="Form"
        />
      </ModalBox>
    </Modal>
  );
};

export default PortalModal;