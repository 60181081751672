import { Grid, InputAdornment, TextField, styled } from "@mui/material";
import React, { useState, useEffect } from "react";

import ExclamationIcon from "../Component/Icons/ExclamationIcon";

const StyledTextField = styled(TextField)(({ customStyleProps }) => ({
    ...customStyleProps,
}));

export const CustomGrid = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const NO_SPECIAL_CHARACTERS_ERROR_MESSAGE = "No special characters or spaces allowed";
const EMPTY_FIELD_ERROR_MESSAGE = "This field cannot be empty";

function W86PlaceholderTextField({
    placeholderText, 
    value, 
    defaultValue, 
    handleChange, 
    error, 
    helperText, 
    onBlur, 
    size, 
    className, 
    disabled, 
    customStyleProps,
    fullWidth = false, 
    disableAdornment = false,
    excludePrefix = ""
}) {
    const [errMessage, setErrMessage] = useState("");
    const displayValue = value && excludePrefix && value.startsWith(excludePrefix)
        ? value.slice(excludePrefix.length)
        : value;

    const hasSpecialCharacters = (value) => {
        return /[^a-zA-Z\d\s:_]/.test(value) !== false;
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (hasSpecialCharacters(inputValue)) {
            setErrMessage(NO_SPECIAL_CHARACTERS_ERROR_MESSAGE);
            return;
        }
        setErrMessage("");
        
        const newValue = excludePrefix + inputValue;
        handleChange({ target: { value: newValue } });
    };

    return (
        <StyledTextField
            id="w86-placeholder-textfield"
            customStyleProps={customStyleProps}
            className={className ? className : ""}
            disabled={disabled ? disabled : false}
            size={size ? size : "small"}
            variant="outlined"
            fullWidth ={fullWidth}
            defaultValue={defaultValue && defaultValue}
            value={displayValue}
            error={!displayValue || errMessage}
            helperText={(!displayValue ? EMPTY_FIELD_ERROR_MESSAGE : (helperText || errMessage))}
            onChange={handleInputChange}
            placeholder={placeholderText}
            InputProps={{
                startAdornment: !disableAdornment &&  <InputAdornment position="start">{"${"}</InputAdornment>,
                endAdornment: (
                    <InputAdornment position="end">
                        <Grid container horizontal="center" vertical="center">
                            <CustomGrid item>
                            {!disableAdornment && "}"}
                                {(error || errMessage) && <ExclamationIcon style={{ marginLeft: "4px" }} />}
                            </CustomGrid>
                        </Grid>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default W86PlaceholderTextField;
