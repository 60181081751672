import React, { useState, useEffect } from "react";

// Util
import ShowPHReplace from "./ShowPHReplace";
import { send_request } from "../../../../utils/Request";
import ChangePlaceholderInputs from "./ChangePlaceholderInputs";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import WarningBox from "../../../Component/SelectPlanComponents/WarningBox";

// MUI
import { CheckCircle, ErrorRounded } from "@mui/icons-material";
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from "@mui/material";
import { CustomGrid, Label, StyledAlert, StyledAutocomplete, StyledChip, StyledSkeleton } from "./RenameStyledComponents";

function RenameSourceAndReferences({ projectId, closeDialog, renameGivenPH }) {
    const [allPHs, setAllPHs] = useState(null);
    const [selectedPH, setSelectedPH] = useState(null);
    const [isReplacing, setIsReplacing] = useState(false);
    const [error, setError] = useState("");
    const [renamedValue, setRenamedValue] = useState(null);
    const [hasPrefix, setHasPrefix] = useState(false);

    const [showResult, setShowResult] = useState("");

    useEffect(async () => {
        let url = `component-service/placeholder/${projectId}`;

        const json = await send_request(url, null, null, "GET");

        if (!json || json.data === null) {
            setAllPHs([]);
            return;
        }

        const all = getPHList(json.data);
        setAllPHs(all);

        // Find given placeholder if exists
        if (!renameGivenPH) return;

        // Find given pH and set in state
        for (let pH of all) {
            if (pH["sourceComponentId"] !== renameGivenPH["sourceComponentId"] || pH["key"] !== renameGivenPH["key"]) continue;
            setSelectedPH(pH);
            return;
        }
    }, [showResult]);

    const getPHList = (components) => {
        let res = [];

        for (let i = 0; i < components.length; i++) {
            let componentId = components[i].componentId;
            let componentType = components[i].componentType;
            let pHs = components[i].placeholders.map((pH) => {
                pH["sourceComponentId"] = componentId;
                pH["sourceComponentType"] = componentType;
                return pH;
            });

            if (!pHs) continue;

            res = [...res, ...pHs];
        }

        return res;
    };

    const checkIfSameName = (newVal) => {
        let old = selectedPH.key;

        let oldSplit = old.split(".");
        if (oldSplit.length <= 1 && newVal === old) return true;

        for (let i = 0; i < oldSplit.length; i++) {
            if ((hasPrefix && i === 0) || (!hasPrefix && i === 1)) continue;
            return newVal === oldSplit[i];
        }
    };

    const handleReplace = async () => {
        if (!selectedPH) return;

        setIsReplacing(true);
        // Error checking
        if (!renamedValue) {
            setError("New placeholder cannot be empty");
            setIsReplacing(false);
            return;
        }

        if (checkIfSameName(renamedValue)) {
            setError("New placeholder name cannot be the same as the old one");
            setIsReplacing(false);
            return;
        }

        if (/^[a-zA-Z0-9_]+$/.test(renamedValue) == false) {
            setError("No special characters or spaces allowed");
            setIsReplacing(false);
            return;
        }

        let dto = {
            oldKey: selectedPH.key,
            newKey: renamedValue,
        };

        let sourceComponentId = selectedPH.sourceComponentId;

        let url = `project-service/project_placeholder/replace_placeholders/${projectId}/${sourceComponentId}`;

        send_request(url, dto, null, "POST")
            .then(() => {
                setShowResult("success");
                setIsReplacing(false);
            })
            .catch((e) => {
                console.log(e);
                setShowResult("error");
                setIsReplacing(false);
            });
    };

    const getPlaceholderChip = (item) => {
        const placeholderColor = getPlaceholderColors(item.type, item.length);
        return <StyledChip backgroundColor={placeholderColor.hover} borderColor={placeholderColor.default} icon={placeholderColor.icon}>{`$\{${item.key}\}`}</StyledChip>;
    };

    const getContent = () => {
        return (
            <Grid container marginTop="2px" rowSpacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            {allPHs ? <Label>Select a placeholder to rename</Label> : <StyledSkeleton variant="rectangular" height="25px" width="200px" margin="0px 0px 8px 0px" />}
                        </Grid>
                        {allPHs ? (
                            <StyledAutocomplete
                                disablePortal
                                fullWidth
                                value={selectedPH}
                                options={allPHs}
                                disabled={isReplacing}
                                getOptionLabel={(option) => option.key}
                                onChange={(event, newValue) => {
                                    if (selectedPH === newValue) return;
                                    setError("");
                                    setSelectedPH(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} size="small" placeholder="Rename the source and references to a placeholder" />}
                                renderOption={(props, option) => <li {...props}>{getPlaceholderChip(option)}</li>}
                            />
                        ) : (
                            <StyledSkeleton variant="rectangular" height="40px" />
                        )}
                    </Grid>
                </Grid>
                {selectedPH && (
                    <ChangePlaceholderInputs
                        selectedPH={selectedPH}
                        isReplacing={isReplacing}
                        error={error}
                        setError={setError}
                        handleReplace={handleReplace}
                        StyledSkeleton={StyledSkeleton}
                        renamedValue={renamedValue}
                        setRenamedValue={setRenamedValue}
                        setHasPrefix={setHasPrefix}
                        projectId={projectId}
                    />
                )}
            </Grid>
        );
    };

    const getResultContent = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <StyledAlert
                        severity={showResult}
                        icon={showResult === "success" ? <CheckCircle /> : <ErrorRounded />}
                        borderColor={showResult === "success" ? "rgba(85, 167, 122, 1)" : "rgba(225, 0, 0, 1)"}
                        bgColor={showResult === "success" ? "rgba(85, 167, 122, 0.1)" : "rgba(225, 0, 0, 0.1)"}
                    >
                        <Grid container>
                            <Grid item>
                                {showResult === "success"
                                    ? "Placeholder has been successfully changed"
                                    : "We were unable to make the following change. Try again or contact us if this issue persists."}
                            </Grid>
                        </Grid>
                    </StyledAlert>
                </Grid>
                <Grid item xs={12}>
                    <ShowPHReplace renamedValue={renamedValue} selectedPH={selectedPH} hasPrefix={hasPrefix} isSuccess={showResult === "success"} />
                </Grid>
                {showResult === "success" && (
                    <Grid item xs={12}>
                        <CustomGrid item xs={12} margin="8px 0px 0px 0px">
                            <WarningBox text="Any external references (such as placeholders in uploaded document templates) will need to be changed manually.  " />
                        </CustomGrid>
                    </Grid>
                )}
            </Grid>
        );
    };

    const getContentButtons = () => {
        return (
            <>
                <Button variant="outlined" onClick={closeDialog} disabled={isReplacing}>
                    Close
                </Button>
                <Button variant="contained" onClick={handleReplace} disabled={isReplacing || allPHs === null}>
                    Change
                    {isReplacing && <CircularProgress size={24} className="buttonProgress" />}
                </Button>
            </>
        );
    };

    const getResultButtons = () => {
        return (
            <>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setShowResult("");
                        setAllPHs(null);
                        setSelectedPH(null);
                    }}
                    disabled={isReplacing}
                >
                    Change Another
                </Button>
                <Button variant="contained" onClick={closeDialog} disabled={isReplacing}>
                    Close
                </Button>
            </>
        );
    };

    return (
        <>
            <DialogTitle>Rename placeholder</DialogTitle>
            <DialogContent>{!showResult ? getContent() : getResultContent()}</DialogContent>
            <DialogActions> {!showResult ? getContentButtons() : getResultButtons()}</DialogActions>
        </>
    );
}

export default RenameSourceAndReferences;
