import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {StylesProvider} from "@mui/styles";
import FadeIn from "react-fade-in";
import MaterialGrid from "@mui/material/Grid";
import MaterialCard from "@mui/material/Card";
import MaterialCardContent from "@mui/material/CardContent";
import MaterialTypography from "@mui/material/Typography";
import MaterialCardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {CircularProgress, MenuItem, Select} from "@mui/material";
import {send_request} from "../../utils/Request";
import {Navigate} from "react-router-dom";
import CustomSignUp from "./CustomSignUp";
import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import {timezones} from "../../utils/timezones";
import TextFieldMUI from "@mui/material/TextField";
import {companySizes} from "../../utils/companysize";

class PaymentSuccess extends Component {

  state = {
    signInOpen: false
  }

  handleStart = () => {
    this.setState({
      signInOpen: true
    })
  }
  render() {
    const {signInOpen} = this.state
    if (signInOpen){
      return <Navigate to='/'  />
    }
    return (
      <Grid container spacing={2} className="main-page">
        <Grid item xs={4} style={{background: "url(/images/background.jpg) 50% / cover"}}/>
        <Grid item xs={12} sm={8} xl={6} lg={8} md={8} spacing={1} className="main-form-login">
          <div className="mainDiv">
            <React.Fragment>
              <Typography component="div">
                <h4 style={{color: "black"}}>👍 Your account is ready to go</h4>
              </Typography>
              <div className="inputArea">
                <p className="inputDescription">
                  Your account has been set up and you are now ready to start building workflows. Click the button below to launch Workflow86.
                </p>
              </div>
              <div className="actionArea">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button className="resetButton" variant={"contained"} color={"primary"}  type="submit"
                          onClick={this.handleStart}>
                    LAUNCH 🚀
                  </Button>
                </div>
              </div>
            </React.Fragment>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default PaymentSuccess;
