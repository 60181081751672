import React, { Component } from "react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import { autorun, toJS, reaction, trace } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import AddIcon from "@mui/icons-material/Add";
import * as date from "date-and-time";
import { getCurrentTime } from "../../utils/getCurrentTime";
import CalcultorStore from "./CalculatorStore";
import styles from "./CalculatorComponents/CalculatorStyle";
import ItemCalculation from "./CalculatorComponents/ItemCalculation";
import projectStore from "../ProjectCanvas/ProjectStore";
import { Skeleton } from "@mui/material";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

class CalculatorTemplate extends Component {
  state = {
    error: false,
    loading: false,
      description:null,
      status:null
  };
  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              CalcultorStore.setCalculatorData(components[0]);
              this.props.onComponentNameChanged(CalcultorStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(CalcultorStore.lastModified);
              this.setState({ loading: false });
            }
          }

          // Check if aiComponentBuilder is given and if so, set the draft data
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            CalcultorStore.setTemplateData(this.props.aiComponentBuilderData.data);
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });
          }

          this.props.setChildTemplateLoaded(true);
          this.setState({ loading: false });
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveCalculatorTemplate();
    this.reactToPlaceHolder();
  }

  setCalculatorName = autorun(() => {
    CalcultorStore.setCalculatorName(this.props.ComponentName);
  });

  saveCalculatorTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }

      const lastModified = getCurrentTime();
      CalcultorStore.setLastModified(lastModified);
      const template = toJS(CalcultorStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified,
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };

      send_component_save_request(`component-service/calculator/data`, data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style, isNotPlaceholder);
      this.addPlaceholder(style.default, key, style);
    }
  );

  addPlaceholder = (label, text, style) => {
    let { placeholders } = CalcultorStore;
    placeholders.push({ label, text, style });
    CalcultorStore.setPlaceholders(placeholders);
  };

  insertPlaceholder = (label, text, style, isNotPlaceholder) => {
    const template = toJS(CalcultorStore.template);
    const index = template.position;
    let { operator, perform } = template.data[index];

    if (isNotPlaceholder !== true) {
      text = "${" + text + "}";
    }

    if (perform == "FUNCTION") {
      CalcultorStore.setCalculation(text, index, "operator");
    } else if (perform == "DATETIME") {
      CalcultorStore.setCalculation(text, index, CalcultorStore.fieldFocus);
    }
  };

  handleKeyChanges = (error) => {
    this.setState({ error });
  };

  render() {
    const { classes } = this.props;
    const { data } = toJS(CalcultorStore);
    if (this.state && !this.state.loading) {
      return (
        <div style={{ width: "100%" }}>
          {data.map((calculation, index) => (
            <ItemCalculation
              key={calculation.keyRandom}
              calculation={calculation}
              indexCalculation={index}
              availablePlaceholders={this.props.availablePlaceholders}
              handleKeyChanges={this.handleKeyChanges}
              onPlaceholderSelected={this.props.onPlaceholderSelected}
              isExpanded={this.props.isExpanded}
            />
          ))}
          <div className={classes.wrapFlexCenter}>
            <div
              className={classes.textContentCenter}
              onClick={() => CalcultorStore.addCalculation()}
            >
              <AddIcon /> Add Calculation
            </div>
          </div>
        </div>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(CalculatorTemplate))
);
