import React, { useContext, useState, useRef, useCallback, useEffect } from "react";

// Util
import { Handle } from "reactflow";
import {useParams, useNavigate} from "react-router";
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
import { hexToPastel } from "../../../../../../utils/HexToPastelUtil";

// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";

// Custom components
import NodeDescription from "../../../NodeDescription";
import CanvasReloadDialog from "../../../CanvasReloadDialog";
import NodeSettingsMenuBar from './ComponentNodeUtil/NodeSettingsMenuBar';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';

// MUI
import { Tooltip, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

// Icons
import Error from "@mui/icons-material/Error";

// MobX
import { inject, observer } from "mobx-react";
import InstructionsInfo from "../../../InstructionInfo";
import { COMPONENT_ICON_ALT } from "../../../../../../utils/CanvasConstants";
import {CustomDialog} from "../../../../../Component/Dialog";

import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";

import { PRODUCTION_CANVAS_VIEW_MODE, CAN_OPEN_IN_NEW_TAB } from "../../../../../../utils/CanvasConstants";

const ProductionCanvasComponent = inject("CanvasStore")(
  observer(({ id, data, classes, selected, CanvasStore }) => {

    const { modeInfo } = useContext(CanvasContext);
    if (modeInfo === undefined) {
      return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
    }

    const parentRef = useRef(null);
    const ref = useRef(null);
    const { nodes } = CanvasStore;
    const { edit, forceStart, handleProductionCanvasMount } = useContext(CanvasContext);
    const { productionVersion } = useContext(CanvasContext);
    const params = useParams();
    const projectId = params.id;
    const navigate = useNavigate();
    const [mode, setMode] = modeInfo;


    // State hooks
    const [editComponent, setEditComponent] = edit ? edit : useState({});
    const [forceStartComponent, setForceStartComponent] = forceStart ? forceStart : useState({});
    const [isSelectedComponent, setIsSelectedComponent] = useState(false);
    const [isSelectedForceStart, setIsSelectedForceStart] = useState(false);
    const [svgSelectedClass, setSvgSelectedClass] = useState("");
    const [showCheckingLatestDialog, setShowCheckingLatestDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const hasInput = data.hasInput;
    const hasOutput = data.hasOutput;


    // Effect hooks
    useEffect(() => {
      const dataComponentId = (data && data.componentId) ? data.componentId : id;
      setIsSelectedComponent((editComponent && dataComponentId === editComponent.componentId));
    }, [editComponent, data, id]);

    useEffect(() => {
      setIsSelectedForceStart(selected || (forceStartComponent && !selected && id === forceStartComponent.componentId));
    }, [forceStartComponent, selected, id]);

    useEffect(() => {
      const isTrue = selected || isSelectedComponent || isSelectedForceStart;
      setSvgSelectedClass(isTrue ? classes.selectedIcon : "");
    }, [isSelectedComponent, isSelectedForceStart, selected, classes.selectedIcon]);



    // Handlers
    const handlePreview = useCallback(() => {
      ProjectStore.state.production = true;
      ProjectStore.state.canvasView = false;
      if (edit && !editComponent) {
        setEditComponent({ ...data, componentId: id });
      }
    }, [edit, editComponent, data, id]);

    const handleRun = useCallback(() => {
      if (!data) return;
      setShowCheckingLatestDialog(true);
    }, [data]);

    const handleInstructionChange = useCallback((newInstruction) => {
      const updatedCanvas = nodes.map((component) =>
        component.id === id
          ? { ...component, data: { ...component.data, instruction: newInstruction } }
          : component
      );
      CanvasStore.setNodes(updatedCanvas);
    }, [nodes, id, CanvasStore]);


    const handleOpenEditable = async () => {
      let url;

      if (data.fromWorkflowPath)
        url = `/project/canvas/${projectId}?view=${data.parentId}`;
      else {
        url = `/project/canvas/${projectId}?view=${id}`;
      }

      ProjectStore.state.canvasView = false;
      ProjectStore.state.production = false;

      navigate(url);
    };

    const nodeSettingsMenuItems = [
      {
        title: "Run from this component",
        icon: "play_circle",
        onClick: handleRun
      },
      {
        title: "View component",
        icon: "preview",
        onClick: handlePreview
      },
      {
        title: "Edit this component",
        icon: "edit",
        onClick: () => setShowEditDialog(true)
      }
    ];

    return (
        <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
        >
          <div className={classes.nodeWrapper}>
            {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
            )}
            <ComponentNodeView
              classes={classes}
              data={data}
              selected={selected}
              isSelectedComponent={isSelectedComponent}
              isSelectedForceStart={isSelectedForceStart}
              isProdCanvas={true}
              mode={mode}
              forceStartComponent={forceStartComponent}
            />

            <NodeSettingsMenuBar
                selected={selected}
                actions={nodeSettingsMenuItems}
                classes={classes}
                data={data}
            />

            {data.instructions &&
                (data.instructions.buildInstruction ||
                    data.instructions.editInstruction) && (
                    <InstructionsInfo
                        data={data}
                        parent={parentRef}
                        textRef={ref}
                    />
                )}
          </div>
          {/* Dialogs */}
          {showEditDialog && (
              <CustomDialog
                  isOpen={showEditDialog}
                  title="↩️ You will be switched to the draft canvas"
                  contents={
                    <p>
                      To edit this component, you will be switched back to the
                      draft canvas. Make sure to save and publish your changes
                      before switching back to the production canvas.
                    </p>
                  }
                  buttons={
                    <>
                      <Button
                          onClick={() => {
                            setShowEditDialog(false);
                          }}
                          variant="outlined"
                      >
                        Cancel
                      </Button>
                      <Button
                          onClick={() => {
                            handleOpenEditable().then(() => {});
                          }}
                          variant="contained"
                      >
                        Continue
                      </Button>
                    </>
                  }
              />
          )}
          {showCheckingLatestDialog && (
              <CanvasReloadDialog
                  projectId={projectId}
                  currentProdVersion={productionVersion}
                  setShowCheckingLatestDialog={setShowCheckingLatestDialog}
                  data={data}
                  id={id}
                  forceStartComponent={forceStartComponent}
                  setForceStartComponent={setForceStartComponent}
                  handleProductionCanvasMount={handleProductionCanvasMount}
              />
          )}
        </div>
    );
  })
);

export default withStyles(styles)(ProductionCanvasComponent);
