import React, { useEffect, useState } from "react";

// MUI
import { Grid, Skeleton, Slide, styled, Drawer, Pagination, TextField } from "@mui/material";
import { send_request } from "../../../../utils/Request";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Inputs
import ListInput from "./ListInput";
import DatetimeInput from "./DatetimeInput";
import DefaultInput from "./DefaultInput";
import SelectSessionDropdown from "./SelectSessionDropdown";
import { useLocation } from "react-router";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "10px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    alignItems: vertical,
    justifyContent: horizontal,
}));

const PlaceholderBox = styled("div")(({ color, borderColor,icon }) => ({
    backgroundColor: color,
    border: `1px solid ${borderColor}`,
    display: "inline-block",
    padding: "4px 4px 4px 38px",
    borderRadius: "4px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    fontSize: "14px",
    '&::before':{
        content: '""',
        background: `url(${icon})`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '20px',
        height: '20px',
        marginLeft: '-30px'
    }
/*    `&:before: {
    content: '',
    background: url(${icon}),
    backgroundSize: 'cover',
    position: 'absolute',
    width: '20px',
    height: '20px',
    marginLeft: '-30px'
}`*/

}));

const Text = styled("span")(({ textColor }) => ({
    fontSize: "14px",
    color: textColor,
}));

const PAGE_SIZE = 25;

const SearchField = styled(TextField)({
    marginBottom: '16px',
    width: '100%',
});

function InputPlaceholdersBox({ placeholders, setPlaceholders, values, setValues, setIsLoading, componentId, projectId, version, inputDataLocation, setInputDataLocation, queryParams, isTrigger, isTest, type, step, fetchTestOutput, setFetchTestOutput, loadingInputPHs, setLoadingInputPHs }) {

    const [initSelected, setInitSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPlaceholders, setFilteredPlaceholders] = useState([]);

    useEffect(async () => {
        await fetchPlaceholders(false);
    }, []);

    useEffect(async () => {
        if (!fetchTestOutput) return;

        if (step !== 2) {
            setFetchTestOutput(false);
            return;
        }

        // Handle re-fetching
        setTimeout(() => {
            setLoadingInputPHs(true);
        }, 0);

        await wait(1000); // Wait 1 second
        await fetchPlaceholders(true);

        setTimeout(() => {
            setLoadingInputPHs(false);
        }, 0);

        setFetchTestOutput(false);
    }, [fetchTestOutput]); 

    useEffect(() => {
        if (inputDataLocation === "manually-set") {
            handleSettingPHValues(placeholders);
        } else setValues(null);
    }, [inputDataLocation]);

    // Used for cleanup
    useEffect(() => {
        return () => {
            setPlaceholders(null);
            setValues(null);
            setIsLoading(false);
        };
    }, []);

    const wait = (ms) =>
    new Promise((res) => {
      return setTimeout(res, ms);
    });

    const fetchPlaceholders = async (keepOldVals) => {
        let url = `component-service/placeholder/${projectId}/${componentId}`;

        let reqData = null;
        if (version) reqData = { workflowVersion: version };

        if (isTrigger) url = `component-service/placeholder/get_trigger_placeholders/${projectId}/${componentId}`;
        let json = await send_request(url, null, reqData, "get");

        if (!json || !json.data || !json.data.placeholders) return;
        let pHs = json.data.placeholders;

        handleSettingPHValues(pHs, keepOldVals);

        let selectedSessionId = queryParams.get("selectedSession");
        if (!selectedSessionId) {
            setPlaceholders(pHs);
            return;
        };

        setInputDataLocation("existing-session");
        setInitSelected(selectedSessionId);
        setPlaceholders(pHs);
    }

    const handleSettingPHValues = (pHs, keepOldVals) => {
        if (!pHs) return;

        let vals = {};

        // Go over placeholders
        for (let pH of pHs) {
            let type = pH.type;

            if (keepOldVals && pH.key in values) {
                vals[pH.key] = values[pH.key];
                continue;
            }

            vals[pH.key] = {
                type: type,
            };

            if (type === "datetime")
                vals[pH.key]["value"] = {
                    rawValue: null,
                    format: "yyyy-MM-DD hh:MM:ssZ",
                    value: "",
                };
            else if (type === "list") vals[pH.key]["value"] = [];
            else vals[pH.key]["value"] = "";
        }


        setValues(vals);
    };

    const capitalise = (s) => {
        try {
            return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
        } catch (e) {
            return s;
        }
    };

    const getPlaceholderBox = (pH) => {
        const col = getPlaceholderColors(pH.type, 1);
        return (
            <PlaceholderBox color={col.hover} borderColor={col.default} icon={col.icon}>
                <Text>{`\$\{${pH.key}\}`}</Text>
            </PlaceholderBox>
        );
    };

    const getCorrectInput = (pH) => {
        let type = pH.type;
        switch (type) {
            case "datetime":
                return <DatetimeInput pH={pH} values={values} setValues={setValues} />;
            case "list":
                return <ListInput pH={pH} values={values} setValues={setValues} />;
            default:
                return <DefaultInput pH={pH} values={values} setValues={setValues} />;
        }
    };

    const getLoader = () => {
        return (
            <Grid container rowGap={2}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                    return (
                        <Grid item xs={12}>
                            <StyledSkeleton variant="rectangular" height="50px" />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    useEffect(() => {
        if (!placeholders) return;
        
        const filtered = placeholders.filter(pH => 
            pH.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (values && values[pH.key] && values[pH.key].value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredPlaceholders(filtered);
        setPage(1);
    }, [searchTerm, placeholders, values]);

    return (
        <Grid container gap={2}>
            <Grid item xs={12}>
                <b>{!isTrigger ? "Set input data" : "Set output data"}</b>
            </Grid>
            <Grid item xs={12}>
                <Text>{!isTrigger ? "The values below are all placeholders that are available as inputs into this component. You can edit these values before running the component." : "The values below are placeholders that are outputs from this component to any connected components. You can manually specify the value of these placeholders when manually starting this component"}</Text>
            </Grid>
            <Grid item xs={12}>
                {placeholders ? (
                    <RadioGroup
                        value={inputDataLocation}
                        onChange={(e) => {
                            setInputDataLocation(e.target.value);
                            setValues(null);
                        }}
                    >
                        <FormControlLabel
                            value="manually-set"
                            control={<Radio size="small" />}
                            label={!isTrigger ? "Manually set input values" : "Manually set output values"}
                        />
                        <FormControlLabel
                            value="existing-session"
                            control={<Radio size="small" />}
                            label="Use values from an existing session"
                        />
                    </RadioGroup>
                ) : (
                    <StyledSkeleton variant="rectangular" height="35px" width="75%" />
                )}
            </Grid>
            {inputDataLocation === "existing-session" && (
                <Grid item xs={12}>
                    {placeholders ? (
                        <SelectSessionDropdown
                            projectId={projectId}
                            componentId={componentId}
                            StyledSkeleton={StyledSkeleton}
                            setValues={setValues}
                            placeholders={placeholders}
                            setIsLoading={setIsLoading}
                            setLoadingInputPHs={setLoadingInputPHs}
                            initSelected={initSelected}
                            isTest={isTest}
                            version={version}
                        />
                    ) : (
                        <StyledSkeleton variant="rectangular" height="35px" width="75%" />
                    )}
                </Grid>
            )}
            {placeholders && !loadingInputPHs ? (
                <Grid item xs={12}>
                    <SearchField
                        variant="outlined"
                        size="small"
                        placeholder="Search placeholders or values..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {values && (
                        <Grid container rowGap={"4px"}>
                            {Object.keys(values).length > 0 && (
                                <Grid item xs={12}>
                                    <Grid container marginBottom={"16px"} columnSpacing={"16px"}>
                                        <Grid item xs={5}>
                                            <Text textColor="rgba(126, 126, 126, 1)">Placeholder</Text>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Text textColor="rgba(126, 126, 126, 1)">Value</Text>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {filteredPlaceholders.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).map((pH, idx) => {
                                return (
                                    <Grid item xs={12}>
                                        <Grid container marginBottom="8px" columnSpacing={"16px"}>
                                            <CustomGrid item xs={5} vertical="center" horizontal="flex-start">
                                                {getPlaceholderBox(pH)}
                                            </CustomGrid>
                                            <CustomGrid item xs={7} vertical="center" horizontal="flex-start">
                                                {getCorrectInput(pH)}
                                            </CustomGrid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            <Grid item xs={12}>
                                <Grid container display="flex" alignItems="flex-end" justifyContent="flex-end" marginTop="8px">
                                    <Pagination 
                                        color="primary" 
                                        size="small"
                                        showFirstButton
                                        showLastButton
                                        count={Math.ceil(filteredPlaceholders.length / PAGE_SIZE)} 
                                        page={page} 
                                        onChange={(e, value) => setPage(value)} 
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ) : (
                getLoader()
            )}
        </Grid>
    );
}

export default InputPlaceholdersBox;
