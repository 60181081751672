import React from 'react';
import { Tooltip, Grid } from "@mui/material";

const NodeSettingsMenuBar = ({ selected, actions, classes, data }) => {
  if (!selected || data.fromWorkflowPath) return null;

  return (
    <div className={classes.nodeInfoBackground}>
      <div className={classes.settings}>
        <Grid container>
          <Grid item xs={12} className={classes.centerGrid}>
            {actions.map((action, index) => (
              <Tooltip key={index} title={action.title}>
                <i
                  className="material-icons icon"
                  onClick={action.onClick}
                >
                  {action.icon}
                </i>
              </Tooltip>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NodeSettingsMenuBar;