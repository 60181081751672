import React from 'react';

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import "../stylesheet/dashboardPage.css";

import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import "../Achievement/AchievementStyle.css";

function UsefulLinksAdminCard(props) {

  let {ProjectStore} = props;

  let usefulLinksAdminComponent = (<Card className="cardStyleUsefulLinks">
        <CardContent style={{ padding: "32px" }}>
            <Typography
            variant="h4"
            component="p"
            className="cardHeader"
            >
            Useful links
            </Typography>
        </CardContent>
        <CardContent
            className="textContent"
            style={{ textAlign: "left", padding: "0px 32px" }}
        >
            <FadeIn transitionDuration="1000">
            <Typography
                variant="h0"
                component="p"
                className="activeNumber"
            >
                <div>
                <a
                    target="_blank"
                    href="https://docs.workflow86.com/docs/getting-started/first_workflow"
                    className="useful-links"
                >
                    <p>🚀 Run your first workflow in 5 mins</p>
                </a>
                <Link to="/project" className="useful-links">
                    <p>🔀 Create a new workflow</p>
                </Link>
                <Link
                    to="/marketplace"
                    className="useful-links"
                >
                    <p>
                    🛒 Download a template from the
                    marketplace
                    </p>
                </Link>
                <Link
                    className="useful-links"
                    to=""
                    onClick={() => {
                    ProjectStore.openWelcomePageTour();
                    }}
                >
                    <p>🚩 Run the product tour </p>
                </Link>
                <a
                    target="_blank"
                    href="https://docs.workflow86.com/docs/components/component_intro"
                    className="useful-links"
                >
                    <p>
                    🧱 Everything you need to know about
                    components
                    </p>
                </a>
                <Link to="/profile" className="useful-links">
                    <p>🧑 Changing your account details</p>
                </Link>
                </div>
            </Typography>
            </FadeIn>
        </CardContent>
        </Card>);


  return usefulLinksAdminComponent;
}

export default UsefulLinksAdminCard;