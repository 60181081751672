import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import "./WebhookImport.css";
import Paper from "@mui/material/Paper";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import WebhookImportStore from "./WebhookImportStore";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import SelectMethod from "./component/SelectMethod";
import UploadSample from "./component/UploadSample";
import SampleReceived from "./component/SampleReceived";
import SetUpPlaceholders from "./component/SetUpPlaceholders";
import SetEndpointUrl from "./component/SetEndpointURL";
import SetToken from "./component/SetToken";
import StepButton from "@mui/material/StepButton";
import Summary from "./component/Summary";
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import OpenEndpointStep from "./component/OpenEndpointStep";
class WebhookImportTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      checkKey: false,
      data: {},
      token: "",
      index: 0,
      checkRoutingKey: false,
      loading: false,
      tab: 0,
      open: false,
      error: false,
      placeholdersJson: [],
      generateOpen: false,
      regenerateOpen: false,
      showPassword: false,
      setShowPassword: false,
      loadingCircle: false,
      hasToken: false,
      deleteLoadingCircle: false,
      checkKeyUpload: false,
      indexError: [],
      indexError2: [],
      activeStep: 0,
      webhookStep: 0,
      value: null,
      method: null,
      status: null,
      description: null,
      customResponse: false
    };
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props
          .component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then(response => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              WebhookImportStore.setComponentData(components[0]);
              
              if(WebhookImportStore.name){
                this.props.onComponentNameChanged(WebhookImportStore.name);
              }

              this.props.onComponentDescriptionChanged(components[0].description);
              
              this.props.onLastModifiedChanged(WebhookImportStore.lastModified);
              this.setState({
                status: components[0].status,
                customResponse: components[0].customResponse
                  ? components[0].customResponse
                  : false
              });
              if (components[0].componentData) {
                if (!components[0].componentData.data.samplePlaceholders) {
                  WebhookImportStore.data.samplePlaceholders = [];
                  for (
                    let i = 0;
                    i < components[0].componentData.data.placeholders.length;
                    i++
                  ) {
                    components[0].componentData.data.placeholders[
                      i
                    ].checked = true;
                    WebhookImportStore.addJsonData(
                      components[0].componentData.data.placeholders[i]
                    );
                  }
                }
                if (components[0].componentData.token) {
                  this.setState({
                    hasToken: true
                  });
                }
                this.setState({
                  placeholders:
                    components[0].componentData.data.samplePlaceholders,
                  method: components[0].componentData.data.type
                });

                // If the user was on open endpoint method, return appropriately
                if (components[0].componentData.data.type === "open_endpoint") {
                  this.setState({ activeStep: 7, webhookStep: 7 });
                } else if (
                  components[0].componentData.token &&
                  components[0].componentData.data.json != "" &&
                  components[0].componentData.data.samplePlaceholders != []
                ) {
                  this.setState({
                    activeStep: 6,
                    webhookStep: 6
                  });
                }
                this.setState({ loading: false });
              } else {
                let lp = [];
                lp.push({
                  index: 0,
                  placeholders: [],
                  key: WebhookImportStore.keyAuto(),
                  incomingKey: ""
                });
                this.setState({ placeholders: lp });
              }
            }
          }

          if (
            this.props.aiComponentBuilderData &&
            this.props.aiComponentBuilderData.data
          ) {
            if(this.props.aiComponentBuilderData["name"]){
              this.props.onComponentNameChanged(
                this.props.aiComponentBuilderData["name"]
              );
            }

            const aiData = this.props.aiComponentBuilderData.data;
            WebhookImportStore.setTemplateData(aiData); // Set in store

            this.setState({
              status: "AI_BUILD_GENERATED",
              method: aiData.type
            });
            this.onChangeState(7, 7);

            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            this.setState({
              description: this.props.aiComponentBuilderData["explanation"]
            });
          }

          this.props.setChildTemplateLoaded(true);
          this.setState({ loading: false });
        })
        .catch(err => {
          throw Error(err.message);
        });
    }
  }

  setWebhookName = autorun(() => {
    WebhookImportStore.setWebhookName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveWebhookImport();
  }

  saveWebhookImport = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      const { placeholderError } = toJS(WebhookImportStore);
      if (this.state.error || placeholderError.length > 0) {
        this.props.showError();
        return;
      }

      let lastModified = getCurrentTime();
      let dataComponent = toJS(WebhookImportStore).data;
      WebhookImportStore.data.placeholders = [];
      for (let i = 0; i < dataComponent.samplePlaceholders.length; i++) {
        if (dataComponent.samplePlaceholders[i].checked == true) {
          let pH = dataComponent.samplePlaceholders[i];
          WebhookImportStore.data.placeholders.push(pH);
        }
      }
      let dataSave = toJS(WebhookImportStore).data;
      let data = {
        componentData: {
          data: dataSave,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        type: WebhookImportStore.template.type,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request("component-service/webhook/data", data, "", "POST", this.props.SaveTrigger)
        .then(response => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          // this.componentDidMount();
          projectStore.savedComponent = true;
        })
        .catch(err => {
          throw err;
        });
    }
  );

  handleRoutingKeyChange = (event, placeholder, index) => {
    WebhookImportStore.onChangeField(
      event.target.value,
      placeholder,
      "content",
      index
    );
  };

  handleJsonBodyChange = value => {
    WebhookImportStore.onChangeField(value, "", "json");
  };

  handleKeyChange = (event, placeholder) => {
    WebhookImportStore.onChangeField(event.target.value, placeholder, "key");
  };

  handleKeyChanges = error => {
    this.setState({ error });
  };

  onChangeState = (webhookStep, activeStep) => {
    this.setState({
      webhookStep,
      activeStep
    });
  };



  getStepContent = (step, isExpanded) => {
    switch (step) {
      case 0:
        return (
          <SelectMethod
            componentStore={WebhookImportStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            method={method => this.setState({ method })}
          />
        );
      case 1:
        return (
          <UploadSample
            componentStore={WebhookImportStore}
            status={this.state.status}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            project_id={this.props.project_id}
            component_id={this.props.component_id}
            method={this.state.method}
            version={this.props.version}
            isExp={isExpanded}
            customResponse={this.state.customResponse}
          />
        );
      case 2:
        return (
          <SampleReceived
            componentStore={WebhookImportStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
          />
        );
      case 3:
        return (
          <SetUpPlaceholders
            componentStore={WebhookImportStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            handleKeyChanges={error => this.setState({ error })}
            method={this.state.method}
            stepStatus={"setPlaceholders"}
          />
        );
      case 4:
        return (
          <SetEndpointUrl
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            component_id={this.props.component_id}
            isExp={isExpanded}
          />
        );
      case 5:
        return (
          <SetToken
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            changeTokenStatus={status => this.setState({ hasToken: status })}
            name={toJS(this.props.ComponentName)}
            component_id={this.props.component_id}
            hasToken={this.state.hasToken}
          />
        );
      case 6:
        return (
          <Summary
            componentStore={WebhookImportStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            changeTokenStatus={status => this.setState({ hasToken: status })}
            name={toJS(this.props.ComponentName)}
            component_id={this.props.component_id}
            stepStatus={"summary"}
            hasToken={this.state.hasToken}
            isExp={isExpanded}
          />
        );
      case 7:
        return (
          <OpenEndpointStep
            componentId={this.props.component_id}
            componentStore={WebhookImportStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)}
            changeTokenStatus={status => this.setState({ hasToken: status })}
            name={toJS(this.props.ComponentName)}
            component_id={this.props.component_id}
            stepStatus={"summary"}
            hasToken={this.state.hasToken}
            isExp={isExpanded}
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleOnClickStep = step => {
    this.setState({
      webhookStep: step,
      activeStep: step
    });
  };

  render() {
    const { data, name } = toJS(WebhookImportStore);
    const { activeStep, webhookStep, method } = this.state;

    const steps = [
      "Select method",
      "Upload sample",
      "Sample received",
      "Set up placeholders",
      "Set Endpoint URL",
      "Set token",
      "Summary"
    ];

    const { tab } = this.state;
    if (this.state && !this.state.loading) {
      return (
        <Grid container xs={12} className="componentGrid">
          <Paper className="componentPaperWebhook">
            {method !== "open_endpoint" &&
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        onClick={() => {
                          this.handleOnClickStep(index);
                        }}
                        {...labelProps}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>}

            <Grid
              container
              padding={"0 24px"}
              paddingTop={method !== "open_endpoint" ? "0px" : "24px"}
              marginTop={this.props.isExpanded ? "40px" : 0}
            >
              {this.getStepContent(webhookStep, this.props.isExpanded)}
            </Grid>
          </Paper>
        </Grid>
      );
    } else return <IndividualComponentLoader />;
  }
}
export default inject("ComponentName", "ComponentDescription", "SaveTrigger")(
  observer(WebhookImportTemplate)
);
