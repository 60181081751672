import React from 'react';
import { Handle } from 'reactflow';
import { COMPONENT_ICON_ALT } from "../../../../../../utils/CanvasConstants";

const BaseComponent = ({ classes, data, isConnectable }) => {
  const hasInput = data.hasInput;
  const hasOutput = data.hasOutput;

  return (
    <div className={classes.nodeWrapper}>
      <div className={classes.node}>
        {hasInput && (
          <Handle
            className={`${classes.port}`}
            type={"target"}
            position={Position.Left}
            isConnectable={true}
          />
        )}
        {!hasInput && <div className={classes.filler} />}
        <img src={data.logo} alt={COMPONENT_ICON_ALT} />
        {hasOutput && (
          <Handle
            className={classes.port}
            position={Position.Right}
            type="source"
            isConnectable={isConnectable}
          />
        )}
        {!hasOutput && <div className={classes.filler} />}
      </div>
      <div className={classes.nodeInfoBackground}>
        <p className={`${classes.rmvMargin} bold`}>
          {data.displayName}
        </p>
        <p className={`${classes.rmvMargin} ${classes.labelText}`}>
          {data.label}
        </p>
      </div>
    </div>
  );
};

export default BaseComponent;