import React, { createContext, useEffect, useRef, useState } from "react";
//mui
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Snackbar,
  styled,
  Tooltip,
} from "@mui/material";
//mui-icons
import { ArrowForward, Science as ScienceIcon } from "@mui/icons-material";
//canvas infrastructure
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import Canvas from "./Canvas";
import Sidebar from "./Sidebar";
import DraftCanvasHeader from "../../ProjectCanvas/Canvas/DraftCanvasHeader";
//component pane
import ComponentPane from "../ComponentPane/ComponentPane";
//dialogs
import FixErrorsDialog from "../../ProjectCanvas/WorkflowValidation/FixErrorsDialog";
import ReadMeDialog from "./../../TextEditor/ReadMeDialog";
import MarketDeploymentDialog from "../../ProjectCanvas/MarketDeploymentDialog";
import { CustomDialog } from "../../Component/Dialog";
import ShareDialog from "./Dialogs/ShareDialog";
//tour
import Tour from "reactour";
import { CanvasPageTourSteps } from "../../../utils/ProductTour";
//utils
import { format } from "../../../utils/Date";
import { getCurrentTime } from "../../../utils/getCurrentTime";
import {
  send_request,
  send_request_graphql,
  send_request_graphql_mutation,
} from "../../../utils/Request";
import { runLoading } from "../../../utils/showLoadingScreen";
//using redirects and router params
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//mobx
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

//styling && css
import "./Canvas.css";
//graphql
import {
  GET_NEXT_COMPONENTS,
  GET_NOTES,
  GET_PRODUCTION_NOTES,
  GET_PRODUCTION_WORKFLOW,
  GET_WORKFLOW,
  GET_WORKFLOW_COMPONENTS,
  SAVE_EVENT,
} from "./CanvasQueries";
import ClosePaneButton from "../ComponentPane/ClosePaneButton";
//saveEvent constructor
import { createSaveEvent } from "../../../utils/createSaveEvent";
import RenamePlaceholdersDialog from "./RenamePlaceholderLogic/RenamePlaceholdersDialog";
import WorkflowPublishedDialog from "./Dialogs/WorkflowPublishedDialog";
import WorkflowPublishComment from "./WorkflowPublishComment";
import { newLineToBreak } from "../../Task/Util";
import ErrorWorkflowDialog from "../../ProjectCanvas/Canvas/ErrorWorkflowDialog";
import TestPanel from "../../WorkflowTest/TestPanel";
import dagre from "dagre";
import { isNode } from "reactflow";
import config from "../../../config";
import {
  getElements,
  extractNodes,
  extractEdges,
} from "../../../utils/CanvasUtil";
import ComponentAIDraftDiscardDialog from "./CanvasUtil/ComponentAIDraftDiscardDialog";
import AIPanel from "./AIBuilder/AIPanel";

const CanvasWrapper = styled(Grid)(({ aiBuilderOpen }) => ({
  height: "100vh !important",
  width: aiBuilderOpen
    ? `calc(100vw - 76px - ${340}px) !important`
    : "calc(100vw) !important",
  position: "absolute !important",
  zIndex: "-100 !important",
}));

const CanvasHeader = styled(Grid)(({ aiBuilderOpen }) => ({
  position: "absolute !important",
  width: aiBuilderOpen ? `calc(94vw - 76px - ${340}px) ` : `94vw !important`,
  marginLeft: "16px !important",
  marginRight: "16px !important",
  marginTop: "16px !important",
}));

//create canvas context to pass the diagram down to children and set state from children
export const CanvasContext = createContext([]);
const ActionButton = styled(Button)(({ bgColor, textColor, borderColor }) => ({
  backgroundColor: bgColor,
  color: textColor,
  border: "1px solid",
  borderColor: borderColor,
  "&:hover": {
    backgroundColor: bgColor,
    color: textColor,
    border: "1px solid",
    borderColor: borderColor,
  },
}));

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const WorkflowCanvas = inject("CanvasStore")(
  observer((props) => {
    //react-router parameters to get workflow id out and use navigation
    const params = useParams();
    const navigate = useNavigate();
    const projectId = params.id;
    //workflow info
    const [workflow, setWorkflow] = useState({});
    const [lastModified, setLastModified] = useState(null);
    const [lastPublished, setLastPublished] = useState("");
    const [draftVersion, setDraftVersion] = useState(null);
    const [publishedVersion, setPublishedVersion] = useState("");
    const [parentFolderId, setParentFolderId] = useState(false);
    const [viewport, setViewport] = useState(null);

    //validation
    const [addressError, setAddressError] = useState(false);
    const [validation, setValidation] = useState(false);
    const [validatingType, setValidatingType] = useState("publish");
    //production mode
    const [production, setProduction] = useState(false);
    const [mode, setMode] = useState("DEFAULT");
    //ui utils
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    //publish
    const [publishDialog, setPublishDialog] = useState(false);
    const [loadPublish, setLoadPublish] = useState(false);
    const [publishConfirmDialog, setPublishConfirmDialog] = useState(false);
    const [publishComment, setPublishComment] = useState("");
    //deploy to marketplace
    const [status, setStatus] = useState(false);
    const [templateId, setTemplateId] = useState("");
    const [deployToMarket, setDeployToMarket] = useState(false);
    const [numConfetti, setNumConfetti] = useState(0);
    //readme dialog
    const [readme, setReadme] = useState(null);
    const [showReadmeDialog, setShowReadmeDialog] = useState(false);
    let readmeText = readme ? readme.readme : "";

    // extract canvas store, nodes & edges
    const { CanvasStore } = props;
    const { nodes, edges } = CanvasStore;

    const [isCanvasLoaded, setIsCanvasLoaded] = useState(false);
    const [notesInfoLoaded, setIsNotesInfoLoaded] = useState(false);
    const [isCanvasLocked, setIsCanvasLocked] = useState(false);
    //setting react flow instance to make sure we are saving the latest diagram
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    //styling
    const classes = props.classes;
    // Share
    const [openShareDialog, setOpenShareDialog] = useState(false);
    //Start
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(null);
    const [openStartDialog, setOpenStartDialog] = useState(false);
    const [renamePlaceholdersDialog, setRenamePlaceholdersDialog] = useState(
      false
    );
    const [openBrokenPHRename, setOpenBrokenPHRename] = useState(false);
    const [renameGivenPH, setRenameGivenPH] = useState(null);
    //access
    const [openAccess, setOpenAccess] = useState(false);
    //to handle unpublished changes
    const [changes, setChanges] = useState(false);
    //this flag is used to determine if we can issue any more events or we have to wait for the previous changes to finish saving
    const [saving, setSaving] = useState(false);
    // Additional query parameters
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const publishQuery = queryParams.get("publish");
    const openTestPanelData = queryParams.get("openTestPanel");

    //Editing components
    const [editComponent, setEditComponent] = useState(null);
    const [openComponentPane, setOpenComponentPane] = useState(false);
    const [pauseSave, setPauseSave] = useState(false); //stopping all save events on the canvas while component is open

    // warning about drawing links with component pane open
    const [linkWarning, setLinkWarning] = useState(false);
    // warning about double clicking into component when pane open
    const [navigationWarning, setNavigationWarning] = useState(false);
    const [validationLoading, setValidationLoading] = useState(false);
    const [showNotes, setShowNotes] = useState(true);
    const [hiddenNotes, setHiddenNotes] = useState([]);
    //we use this flag to ensure component is fully loaded in the pane before issuing a save
    const [childTemplateLoaded, setChildTemplateLoaded] = useState(false);
    //error workflwo dialog
    const [showErrorWorkflowDialog, setShowErrorWorkflowDialog] = useState(
      false
    );
    const [openTestPanel, setOpenTestPanel] = useState(false);
    const [
      openTestPanelAfterValidation,
      setOpenTestPanelAfterValidation,
    ] = useState(false);
    const [openTestTriggerDialog, setOpenTestTriggerDialog] = useState(false);
    const [
      showPublishDialogIfNoProductionVersion,
      setShowPublishDialogIfNoProductionVersion,
    ] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [notesContentArray, setNotesContentArray] = useState([]);
    const [notesLoaded, setNotesLoaded] = useState(false);
    const [testFromCompPanel, setTestFromCompPanel] = useState(false);
    const elementTypeArray = ["component", "dbNode"];

    const [aiBuilderOpen, setAiBuilderOpen] = useState(false);
    const [triggerCloseInChild, setTriggerCloseInChild] = useState(false);
    const [callTriggerCloseInChild, setCallTriggerCloseInChild] = useState(
      false
    );

    const [displayHelperOpen, setDisplayHelperOpen] = useState(false);
    const [aiComponentBuilderOpen, setAiComponentBuilderOpen] = useState(false);
    const [showBuildPrompt, setShowBuildPrompt] = useState(false);

    const [paneSkeleton, setPaneSkeleton] = useState(false);
    const [aiComponentBuilderData, setAiComponentBuilderData] = useState([]);
    const [
      componentInfoForNodeHeader,
      setComponentInfoForNodeHeader,
    ] = useState([]);
    const [showAIBuilder, setShowAIBuilder] = useState(false);

    const [isInit, setIsInit] = useState(null);

    const [executeTest, setExecuteTest] = useState(false);
    const [compForceStartId, setCompForceStartId] = useState("");

    // prebuild preview diagram
    const [openPrebuildPreview, setOpenPrebuildPreview] = useState(false);
    const [mermaidJSContent, setMermaidJSContent] = useState("");

    // Component builder history
    const [openSessionHistory, setOpenSessionHistory] = useState(false);
    const [selectedHistorySession, setSelectedHistorySession] = useState(null);
    const [resetHistory, setResetHistory] = useState(null);

    // Dialog for closing pane when in AI draft
    const [showClosingAIDraftDialog, setShowClosingAIDraftDialog] = useState(
      false
    );
    const [isInAIDraft, setIsInAIDraft] = useState(false);
    const [sidebarState, setSidebarState] = useState("INITIAL_COMP_BUILD");
    const [compSessionId, setCompSessionId] = useState(null);

    // AI Flags
    // if a component with an open ai component session has been closed
    // component pane will set this flag to true and a reaction inside the component panel
    // will close the ai component session
    const [resetAIStateToInitial, setResetAIStateToInitial] = useState(false);

    //Check if note is clicked, because if it is in this state, notes will save when deselected
    const [noteClicked, setNoteClicked] = useState(false);

    //get workflow by id
    useEffect(async () => {
      setLoading(true);
      //deal with project tour
      ProjectStore.closeWelcomePageTour();
      ProjectStore.closeProjectPageTour();
      ProjectStore.closeMarketplacePageTour();
      ProjectStore.closePortalPageTour();
      ProjectStore.closeCredentialPageTour();

      ProjectStore.state.production = false;
      ProjectStore.state.canvasView = false;

      let url = "";
      let notesToggle = false;

      if (ProjectStore.state.production) {
        setProduction(true);
        setMode("PRODUCTION");
      }
      //query address
      url = `project-service/graphql/project/${projectId}`;

      await getLatestVersion(projectId).then(async (response) => {
        await send_request_graphql(
          url,
          ProjectStore.state.production
            ? GET_PRODUCTION_WORKFLOW(projectId)
            : GET_WORKFLOW(projectId)
        )
          .then((res) => {
            let workflowData = ProjectStore.state.production
              ? res.data.publishedProjectById
              : res.data.projectById;

            //Load diagram into the store
            ProjectStore.setWorkflow(workflowData);

            // Show error dialog if status is ERROR
            if (workflowData && workflowData.status === "ERROR")
              setShowErrorWorkflowDialog(true);

            //set diagram into the state
            setWorkflow(workflowData);
            document.title = `${
              workflowData.name ? workflowData.name : "Untitled"
            } | Workflow86`;

            //deal with readme
            let extras = {
              readme: workflowData.extras
                ? workflowData.extras.readme
                : "<p>👋 Welcome to the Read Me page for this workflow. </p><p></p><p>A Read Me is where you can communicate important information about your workflow. This can include what the workflow does, how users can get started and instructions on using the workflow or setting it up. You can edit the Read Me by clicking Edit in the top right. </p>",
              showReadmeDialog: workflowData.extras
                ? workflowData.extras.showReadmeDialog
                : false,
            };

            setReadme(extras);
            setShowReadmeDialog(extras.showReadmeDialog);

            //set parent folder
            setParentFolderId(workflowData.parentFolderId);

            //set last dates
            setLastPublished(workflowData.lastPublished);
            setLastModified(workflowData.lastModified);

            //set viewport
            setViewport(workflowData.viewport);
            notesToggle = workflowData.showNotesToggle;
          })
          .then(() => {
            ProjectStore.canvas = ProjectStore.readDiagram(
              ProjectStore.components
            );
          })
          .then(() => {
            //add the component id in the data
            let eles = ProjectStore.canvas.map((c) => {
              if (elementTypeArray.includes(c.type) && c.data)
                c.data["componentId"] = c.id;
              return c;
            });

            CanvasStore.setNodesAndEdges(eles);

            //render the diagram
            getNotes(notesToggle);
            setLoading(false);
            setValidation(false);
          })
          .then(() => {
            if (publishQuery === "true") {
              setPublishDialog(true);
            }
            if (openTestPanelData !== null) {
              setOpenTestPanel(openTestPanelData);
            }
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      });
    }, []);

    // Method for re-fetching the canvas components
    const refetchComponents = async () => {
      const url = `project-service/graphql/project/${projectId}`;
      const res = await send_request_graphql(
        url,
        GET_WORKFLOW_COMPONENTS(projectId)
      );
      if (!res || !res.data || !res.data.projectById) return;

      let workflowData = res.data.projectById;
      // Grab existing notes and their links
      const notesAndLinks = [
        ...CanvasStore.nodes.filter((item) => item.type === "note"),
        ...CanvasStore.edges.filter((item) => item.label !== "componentLink"),
      ];

      ProjectStore.components = workflowData.components;
      ProjectStore.canvas = ProjectStore.readDiagram(workflowData.components);
      let eles = ProjectStore.canvas.map((c) => {
        if (elementTypeArray.includes(c.type) && c.data)
          c.data["componentId"] = c.id;
        return c;
      });

      CanvasStore.setNodesAndEdges([...eles, ...notesAndLinks]);
    };

    useEffect(async () => {
      if (reactFlowInstance) {
        if (!showNotes) {
          //getting latest position
          let notesAndLinks = getElements(reactFlowInstance).filter(
            (x) =>
              x.type === "note" ||
              (x.type === "link" &&
                x.sourceHandle &&
                x.sourceHandle.startsWith("note"))
          );
          let newElements = getElements(reactFlowInstance).filter(
            (x) =>
              elementTypeArray.includes(x.type) ||
              (x.data && x.data.hasDb) ||
              (x.type === "link" && x.label === "componentLink")
          );

          CanvasStore.setNodesAndEdges(newElements);

          setHiddenNotes(notesAndLinks);
        } else {
          let notes = hiddenNotes ? hiddenNotes : [];

          CanvasStore.setNodesFn((ns) => [...ns, ...extractNodes(notes)]);
          CanvasStore.setEdgesFn((es) => [...es, ...extractEdges(notes)]);
        }
        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let eventData = {
          showNotes: showNotes,
        };
        let saveEvent = createSaveEvent(
          "SAVE_NOTES_TOGGLE",
          projectId,
          viewport,
          getElements(reactFlowInstance),
          eventData
        );

        //send event
        await send_request_graphql_mutation(
          `project-service/graphql/project/save/${projectId}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            setSaving(false);
          })
          .catch((error) => {});
      }
    }, [showNotes]);

    const updateNotesContent = (notes, newElements) => {
      let notesWithContent = [];

      // Go over each existing canvas note
      for (let idx = 0; idx < notes.length; idx++) {
        const note = notes[idx];

        let newNote = { ...note };

        let noteData = notesContentArray.filter((x) => x.id === note.id);

        // If the note data exists, set the content
        if (noteData && noteData.length > 0 && newNote.data) {
          newNote.data.content = noteData[0].content;
        }

        notesWithContent.push(newNote);
      }

      return [...newElements, ...notesWithContent];
    };

    const applyLayout = (elements, parentPosition) => {
      const nodeWidth = 200;
      const nodeHeight = 50;
      const isHorizontal = true;
      dagreGraph.setGraph({ rankdir: "LR" });

      elements.forEach((el) => {
        if (isNode(el) && el.position.x === 0 && el.position.y === 0) {
          dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
          dagreGraph.setEdge(el.source, el.target);
        }
      });

      dagre.layout(dagreGraph);

      return elements.map((el) => {
        if (isNode(el) && el.position.x === 0 && el.position.y === 0) {
          const nodeWithPosition = dagreGraph.node(el.id);
          el.targetPosition = isHorizontal ? "left" : "top";
          el.sourcePosition = isHorizontal ? "right" : "bottom";

          // unfortunately we need this little hack to pass a slightly different position
          // to notify react flow about the change. Moreover we are shifting the dagre node position
          // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
          el.position = {
            x: nodeWithPosition.x + parentPosition.x + Math.random() / 1000,
            y: nodeWithPosition.y + parentPosition.y,
          };
        }

        return el;
      });
    };

    useEffect(async () => {
      if (reactFlowInstance && notesContentArray) {
        // Grab everything not a note
        const nonNotes = nodes.filter((node) => node.type !== "note");

        // Grab everything that is a note
        const existingNotes = nodes.filter((node) => node.type === "note");

        let newElements = updateNotesContent(existingNotes, nonNotes);

        /**
         * Update content in hidden notes in case the notes are hidden initially
         * @type {*[]}
         */
        let newHiddenNotes = [];
        updateNotesContent(hiddenNotes, newHiddenNotes);

        //update canvas
        CanvasStore.setNodes(extractNodes(newElements));
      }

      setNotesLoaded(true);
    }, [notesContentArray]);

    //handle canvas validation, duplicate placeholders and individual placeholder validation
    const validate = async (components) => {
      //clear error array before new validation
      ProjectStore.errors = [];
      ProjectStore.error = false;

      //make request to project service to validate the canvas
      await send_request(
        `project-service/validate/${ProjectStore.project_id}`,
        "",
        "",
        "GET"
      )
        .then((res) => {
          if (res && res.data) {
            //check for placeholder errors
            let d = res.data;
            let errMap = {};
            errMap = addToHashMap(errMap, d.placeholdersNotUnique, "notUnique");
            errMap = addToHashMap(errMap, d.componentErrors, "componentError");
            errMap = addToHashMap(errMap, d.nonExistantPlaceholders, "broken");
            addCanvasErrors(errMap, components);
          }

          //set components object, this helps to validate the canvas
          ProjectStore.components = components;

          //show the error button and put down error state
          if (ProjectStore.errors.length > 0) ProjectStore.error = true;
          else ProjectStore.error = false;
        })
        .finally(() => {})
        .catch((e) => console.log(e));
    };

    const addToHashMap = (errMap, errs, errType) => {
      for (let error of errs) {
        let [first, ...rest] = error.split("_");
        rest = rest.join("_");

        let res = {
          errorMessage: rest,
          errorType: errType,
          isWarning: errType === "notUnique",
        };

        if (first in errMap) errMap[first].push(res);
        else errMap[first] = [res];
      }
      return errMap;
    };

    const addCanvasErrors = (errorMap, components) => {
      let errItems = [];

      for (let i = 0; i < components.length; i++) {
        let c = components[i];

        if (!(c.componentId in errorMap)) continue;
        let errs = errorMap[c.componentId];

        for (let j = 0; j < errs.length; j++) {
          let errMsg = errs[j].errorMessage;

          let item = {};
          item.component = c;
          item.error = errMsg;
          item.type = errs[j].errorType;
          item.isWarning = errs[j].errorType === "notUnique";
          errItems.push(item);
          // c.error = true;
        }
      }

      ProjectStore.errors = errItems;
    };

    const getNotesContent = async () => {
      const url =
        "project-service/project/get_notes_count?projectId=" +
        projectId +
        "&isLatestVersion=true";

      let notesWithContent = [];
      const noteCountRes = await send_request(url, null, null, "GET");
      if (!noteCountRes || !noteCountRes.data) return;
      for (
        let pageNumber = 1;
        pageNumber <= noteCountRes.data.pageSize;
        pageNumber++
      ) {
        const url =
          "project-service/project/get_project_notes_content?projectId=" +
          projectId +
          "&isLatestVersion=true&pageNumber=" +
          pageNumber;
        const noteContent = await send_request(url, null, null, "GET");
        // If data, set into the notesWithContent array
        if (noteContent && noteContent.data)
          notesWithContent = [...notesWithContent, ...noteContent.data];

        // Check if we've reached the total note count
        // If so, set the notes content array
        if (notesWithContent.length >= noteCountRes.data.totalNoteCount) {
          setNotesContentArray(notesWithContent);
          return;
        }
      }

      // After going through all pages,
      // if not all notes are fetched, update the canvas with fetched notes
      if (notesContentArray.length === 0)
        setNotesContentArray(notesWithContent);
    };

    useEffect(async () => {
      // If notes info is loaded, get and set the note content
      if (notesInfoLoaded) getNotesContent();
    }, [notesInfoLoaded]);

    //Retrieves notes
    const getNotes = (notesToggle) => {
      let url = `project-service/graphql/project/${projectId}`;
      //load notes
      send_request_graphql(
        url,
        ProjectStore.state.production
          ? GET_PRODUCTION_NOTES(projectId)
          : GET_NOTES(projectId)
      )
        .then((res) => {
          var notes = null;

          if (ProjectStore.state.production)
            notes = res.data.publishedProjectById
              ? res.data.publishedProjectById.notes
              : [];
          else notes = res.data.projectById ? res.data.projectById.notes : [];
          notes = notes ? ProjectStore.readNotes(notes) : [];

          CanvasStore.addNodes(extractNodes(notes));
          CanvasStore.addEdges(extractEdges(notes));

          setIsCanvasLoaded(true);
          setIsNotesInfoLoaded(true);
        })
        .then(() => {
          if (notesToggle != null && notesToggle != undefined) {
            setShowNotes(notesToggle);
          }

          // If viewing a pane, we need to open it
          let componentId = queryParams.get("view");
          if (!componentId) return;

          // Else we need to open the pane
          let openedComponent = ProjectStore.canvas.find(
            (comp) => comp.id === componentId
          );
          if (!openedComponent) return;

          setEditComponent({
            componentId: componentId,
            ...openedComponent.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    };

    //Catch diagram updates and save to the store
    useEffect(async () => {
      //necessary for creating links
      ProjectStore.nodes = nodes;
      ProjectStore.edges = edges;
    }, [nodes, edges]);

    useEffect(async () => {
      //catch updates to the component we are targeting to edit.
      if (editComponent != null) {
        //open sidebar
        setOpenComponentPane(true);
        //stop all save events for the canvas
        setPauseSave(true);
        if (
          openTestPanelAfterValidation !== null &&
          openTestPanelAfterValidation !== false
        )
          setOpenTestPanelAfterValidation(null);
      }
    }, [editComponent]);

    const checkUnpublishedChanges = async (
      fromRun = false,
      forceStartId = null,
      publishDialog = false
    ) => {
      if (publishedVersion === "None") {
        setLoadingDialog(false);
        setShowPublishDialogIfNoProductionVersion(true);
      } else {
        const url = `project-service/project/unpublished_changes/${projectId}`;
        return await send_request(url, "", "", "GET")
          .then((res) => {
            if (!res || res.status !== 200) return;

            setChanges(res.data);
            setLoadingDialog(false);

            if (publishDialog) return; // If publish dialog is true, return

            if (res.data && fromRun) {
              setCompForceStartId(forceStartId);
              setShowPublishDialog(true);
            } else {
              // If we have a force start id, redirect with that, else perform normal re-direct
              if (forceStartId === null)
                navigate(`/project/production/${projectId}`);
              else
                navigate(
                  `/project/production/${projectId}?force_start=${forceStartId}`
                );
            }
          })
          .catch((e) => console.log(e));
      }
    };

    const getLatestVersion = async (projectId) => {
      return send_request(
        `project-service/project/last-version/${projectId}`,
        "",
        "",
        "get"
      ).then((res) => {
        if (res && res.data) {
          let { draftVersion, publishedVersion } = res.data;

          if (!draftVersion) draftVersion = `${publishedVersion}`;
          else draftVersion = `${draftVersion}`;
          setDraftVersion(draftVersion);

          if (!publishedVersion) publishedVersion = "None";
          else publishedVersion = `${publishedVersion}`;
          setPublishedVersion(publishedVersion);

          ProjectStore.setLatestVersion(draftVersion, publishedVersion);
        }
      });
    };

    //Saving
    const save = async (fromConditionalWorkflow = false) => {
      if (saving) return; // If saving, return

      setSaving(true);
      //thi function now only saves component position
      const shouldSave =
        (fromConditionalWorkflow || !pauseSave) && reactFlowInstance;
      if (shouldSave) {
        // handleCloseSnackbar(); //close snackbar
        //get the latest node position
        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let saveEvent = createSaveEvent(
          "SAVE_VIEWPORT",
          projectId,
          viewport,
          getElements(reactFlowInstance),
          { projectId: projectId }
        );

        //send event
        await send_request_graphql_mutation(
          `project-service/graphql/project/save/${projectId}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            setSaving(false);
          })
          .catch((error) => {});
      }
    };

    const validateComponents = (validatedCanvas) => {
      nodes.map((element) => {
        validatedCanvas.map((component) => {
          if (
            component.type == "component" &&
            element.type == "component" &&
            component.id == element.id &&
            component.data.error
          ) {
            element.data.error = component.data.error;
          }
        });
      });
      CanvasStore.setNodes(nodes);
    };

    //Publishing
    const onPublish = async () => {
      setLoadPublish(true);

      publish();

      setLoadPublish(false);
    };

    const saveDraftVersion = async () => {
      let versionEstablished = {};

      let url = "authz-service/user-info";
      const json = await send_request(url, null, null, "GET");
      if (json && json.data) versionEstablished["by"] = json.data.displayName;

      url = `project-service/project/save-draft-version/${projectId}`;

      send_request(url, versionEstablished, "", "POST")
        .then(async () => {
          getLatestVersion(projectId);
          setShowSnackbar(true);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const publish = async () => {
      if (saving) return; // If saving, return

      setSaving(true);
      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let versionEstablished = {
        comment: publishComment
          ? newLineToBreak(publishComment)
          : publishComment,
      };

      let url = "authz-service/user-info";
      const json = await send_request(url, null, null, "GET");
      if (json && json.data) versionEstablished["by"] = json.data.displayName;

      let saveEvent = createSaveEvent(
        "PUBLISH",
        projectId,
        viewport,
        getElements(reactFlowInstance),
        {
          versionEstablished: versionEstablished,
        }
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${projectId}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          setSaving(false);
          setLoadPublish(false);
          setPublishDialog(false);
          setPublishConfirmDialog(true);
          setLastPublished(getCurrentTime());
          getLatestVersion(projectId);
        })
        .catch((error) => {
          console.log(error);

          setLoadPublish(false);
          setPublishDialog(false);
        });
    };

    const fireShareDialog = (open) => {
      setOpenShareDialog(open);
    };

    const fireValidateDialog = async (type) => {
      setShowPublishDialogIfNoProductionVersion(false);
      setOpenTestPanelAfterValidation(null);
      setValidatingType(type);
      setValidationLoading(true);

      //save the diagram
      await save().then(async () => {
        // To fix validation not working on initial load
        let result = getElements(reactFlowInstance);
        let newComponents = ProjectStore.readCanvasIntoComponents(result);

        setValidation(true);

        await validate(newComponents)
          .then(() => {
            return ProjectStore.readDiagram(newComponents);
          })
          .then((validatedCanvas) => {
            //revalidate components on the diagram
            validateComponents(validatedCanvas);
            setValidation(false);
          });
      });

      setValidationLoading(false);
      setSaving(false);

      if (ProjectStore.error) setAddressError(true);
      else {
        if (type === "publish") {
          setPublishDialog(true);
          setAddressError(false);
        }
        // else  setOpenTestPanelAfterValidation(openTestPanel)
      }
    };

    const onDonePublish = async () => {
      await checkUnpublishedChanges(false, null, true);
      setNumConfetti(0);
      setPublishConfirmDialog(false);
      setPublishComment("");
    };

    //Readme
    const handleDoNotShowAgain = async () => {
      const data = { readme: readme.readme, showReadmeDialog: false };
      const json = await send_request(
        `project-service/project/${projectId}/readme`,
        data,
        "",
        "POST"
      ).catch((err) => {
        throw err;
      });
      if (json && json.status == 200) {
        setReadme({ showReadmeDialog: false });
        setShowReadmeDialog(false);
      }
    };

    const handleShowReadmeDialog = (currentStep) => {
      if (currentStep > 0) {
        setShowReadmeDialog(false);
      } else {
        setShowReadmeDialog(true);
      }
    };

    //Deploy to marketplace
    const handleShare = () => {
      setNumConfetti(0);
      setPublishConfirmDialog(false);
      setOpenShareDialog(true);
    };

    const fireDeployToMarketDialog = () => {
      return (
        <MarketDeploymentDialog
          onPublish={true}
          open={deployToMarket}
          deploy={(isPublic, isHidden, _callback) => {
            handleDeployToMarket(isPublic, isHidden, _callback);
          }}
          close={() => {
            setDeployToMarket(false);
          }}
          project={() => {
            return workflow;
          }}
          templateId={templateId}
          status={status}
        />
      );
    };

    const handleDeployToMarket = async (isPublic, isHidden, _callback) => {
      const updateCard = await modifyProjectData();

      isPublic == 0 || isPublic == 2 ? (isPublic = true) : (isPublic = false);
      send_request(
        "project-service/template/projecttotemplate",
        {
          projectId: projectId,
          version: workflow.version,
          isPublic: isPublic ? isPublic : false,
          isHidden: isHidden ? isHidden : false,
          author: ProjectStore.state.orgName,
        },
        "",
        "post"
      )
        .then((value) => {
          setStatus(true);
          setTemplateId(value.data);
          setDeployToMarket(false);
          _callback();
        })
        .catch((err) => {
          setStatus(false);
          setDeployToMarket(false);
          console.log(err);
        });
    };

    const modifyProjectData = async () => {
      let updateValue = {};
      updateValue.projectId = projectId;
      updateValue.lastModified = getCurrentTime();
      updateValue.description = workflow.description;
      updateValue.categories = workflow.categories;
      updateValue.imagePath =
        workflow.imagePath != null
          ? workflow.imagePath
          : "/images/blue-blocks2.jpg";
      updateValue.isLatestVersion = true;
      await send_request(
        "project-service/project/details",
        updateValue,
        "",
        "patch"
      ).catch((e) => {
        console.log(e);
      });
    };

    const handleConditionalWorflowPathsRefresh = async () => {
      const refreshCanvasId = ProjectStore.refreshCanvas;
      const id = nodes.find((obj) => obj.id === refreshCanvasId);
      const componentIdsToRemove = toJS(id.data.next || []).map(
        (obj) => obj.componentId
      );

      let filteredObjects = nodes.filter(
        (obj) =>
          !componentIdsToRemove.includes(obj.id) &&
          obj.id !== refreshCanvasId &&
          !(obj.type === "link" && obj.source === id.id)
      );

      let url = `project-service/graphql/project/${projectId}`;
      const nextComponentsQuery = GET_NEXT_COMPONENTS(refreshCanvasId);

      send_request_graphql(url, nextComponentsQuery)
        .then((res) => {
          const diag = ProjectStore.readDiagram(
            res.data.nextComponentsByComponentId
          );
          id.data.next = diag.map((obj) => ({ componentId: obj.id }));

          const links = [];
          if (diag.length > 0) {
            diag.forEach((next) => {
              next.data.parentId = id.id; //set parent id for new components
              const newLink = ProjectStore.createLink(
                id.data.type,
                id.data.fromWorkflowPath,
                id.id,
                next.id,
                id.data.baseColor,
                "componentLink"
              );
              links.push(newLink);
            });
          }

          const ele = applyLayout([...diag, ...links], id.position);

          const finalList = [...filteredObjects, ...edges, ...ele, id];
          CanvasStore.setNodesAndEdges(finalList);
        })
        .finally(() => {
          ProjectStore.refreshCanvas = null; // Remove refresh canvas

          // moving it to finally to ensure the save doesn't trigger the refresh canvas
          // again and create dangling components
          if (!aiComponentBuilderOpen) save(true);
        });
    };

    //Ui stuff
    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
      ProjectStore.savedComponent = false;

      // If the refresh canvas is true, we want to refresh it
      // To update the conditional workflow paths
      if (ProjectStore.refreshCanvas) handleConditionalWorflowPathsRefresh();

      ProjectStore.errorSaving = false;
      setLinkWarning(false);
      setNavigationWarning(false);
    };

    const handleCloseTestPanel = () => {
      setOpenTestPanelAfterValidation(null);
      setOpenTestPanel(false);
      removeForceStartTestParams();
      if (window.location.href.includes("?force_start="))
        navigate(`/project/canvas/${projectId}`);
    };

    const handleTestExpand = () => {
      let expandedWindow = window.open(
        `/test_workflow/${projectId}/${openTestPanel}`,
        "_blank"
      );

      expandedWindow.version = draftVersion;
      expandedWindow.projectName = workflow.name;
      expandedWindow.lastModified = lastModified;
    };

    const handleBack = () => {
      ProjectStore.state.production = false;
      if (parentFolderId) {
        navigate("/project/folder/" + parentFolderId);
      } else {
        navigate("/project");
      }
    };

    const showSuccess = (data, saveTriggerData) => {
      //show snackbar on save
      if (saveTriggerData && saveTriggerData.executeTest) setExecuteTest(true);
    };

    const showCanvasLoadingSkeleton = () => {
      return (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "calc(100vw - 76px)",
          }}
        >
          <Grid
            container
            spacing={1}
            marginTop="8px"
            paddingLeft={"16px"}
            paddingRight={"16px"}
            marginBottom={"24px"}
          >
            <Grid item>
              <Skeleton
                variant={"rectangular"}
                height={25}
                width={"30px"}
                style={{ borderRadius: "4px" }}
              />
            </Grid>
            <Grid item marginRight="16px">
              <Skeleton
                variant={"rectangular"}
                height={25}
                width={"130px"}
                style={{ borderRadius: "4px" }}
              />
            </Grid>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
              return (
                <Grid key={item} item>
                  <Skeleton
                    variant={"rectangular"}
                    height={25}
                    width={"90px"}
                    style={{ borderRadius: "4px" }}
                  />
                </Grid>
              );
            })}
            <Grid item marginLeft="16px">
              <Skeleton
                variant={"rectangular"}
                height={25}
                width={"130px"}
                style={{ borderRadius: "4px" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            paddingLeft={"16px"}
            paddingRight={"16px"}
          >
            <Skeleton
              height={"85vh"}
              width={"100%"}
              variant={"rectangular"}
              style={{ borderRadius: "4px" }}
            />
          </Grid>
        </div>
      );
    };

    const handleSaveProjectName = async (name) => {
      if (saving) return;

      setSaving(true);
      setWorkflow({
        ...workflow,
        name: name,
      });

      ProjectStore.setProjectName(name);

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let saveEvent = createSaveEvent(
        "SAVE_WORKFLOW_NAME",
        projectId,
        viewport,
        getElements(reactFlowInstance),
        {
          name: name,
        }
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${projectId}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          setSaving(false);
        })
        .catch((error) => {});
    };

    const checkDBConnections = (id) => {
      if (ProjectStore.targetDataBaseId.get() === "") return;

      const nodesAndEdges = [...nodes, ...edges];
      let replacedNodesAndEdges = nodesAndEdges;

      //delete the old connection
      let oldDbid = ProjectStore.state.dbIds.get(id.componentId);

      if (oldDbid != undefined) {
        ProjectStore.state.dbIds.delete(id.componentId);

        if (new Set(ProjectStore.state.dbIds.values()).has(oldDbid)) {
          if (id.displayName === "Query Database") {
            replacedNodesAndEdges = nodesAndEdges.filter((node) => {
              return !(
                node.target === id.componentId && node.source === oldDbid
              );
            });
          } else {
            replacedNodesAndEdges = nodesAndEdges.filter((node) => {
              return !(
                node.source === id.componentId && node.target === oldDbid
              );
            });
          }
        } else {
          replacedNodesAndEdges = nodesAndEdges.filter((node) => {
            return node.id != oldDbid;
          });
        }
      }

      let compData = nodesAndEdges.find((node) => {
        return node.id === id.componentId;
      });

      ProjectStore.createDbNodeLogic(
        id,
        ProjectStore.state.dbIds,
        replacedNodesAndEdges,
        ProjectStore.targetDataBaseId.get(),
        compData
      );

      CanvasStore.setNodesAndEdges(replacedNodesAndEdges);
    };

    const removeForceStartTestParams = () => {
      const params = new URLSearchParams(window.location.search);
      params.delete("force_start");
      params.delete("selectedSession");
      navigate({ search: params.toString() });
    };

    const setWorkflowName = (name) => {
      let title = "Untitled";
      if (name !== "" || name !== null) title = name;
      document.title = `${title} | Workflow86`;
      if (workflow.name !== name) {
        // adding this to avoid re render
        setWorkflow({
          ...workflow,
          name: name,
        });
      }
    };

    const handleClosePaneButtonClick = () => {
      if (isInAIDraft) {
        setShowClosingAIDraftDialog(true);
        return;
      } else {
        handleClosePaneSave();
      }
    };

    const closePane = ()=> {
        if (openTestPanel) {
          removeForceStartTestParams();
          setOpenTestPanel(false);
        }
    }

    // Handles the closing pane logic
    // Shared between the close pane butto on the side of the pane
// And the close button in the component pane header
    const handleClosePaneSave = () => {
      if (!childTemplateLoaded) return;

      const id = editComponent;
      setEditComponent(null);
      
      setResetAIStateToInitial(true);

      CanvasStore.syncNodes(); // Sync the dragging nodes

      if (window.location.href.includes("?view="))
        navigate(`/project/canvas/${projectId}`);

      setMode("DEFAULT");

      setPauseSave(false);

      setChildTemplateLoaded(false);

      checkDBConnections(id);
      closePane();
    };

    if (!loading)
      return (
        <CanvasContext.Provider
          value={{
            edit: [editComponent, setEditComponent],
            //Added isNoteClicked to Context as I want to stop all nodes
            // from being selected onHover when this is active
            isNoteClicked:[noteClicked, setNoteClicked],
            testPanel: [openTestPanel, setOpenTestPanel],
            modeInfo: [mode, setMode],
            isCanvasLoaded: [isCanvasLoaded, setIsCanvasLoaded],
            notesLoaded: [notesLoaded, setNotesLoaded],
            canvasInstance: [reactFlowInstance, setReactFlowInstance],
            savingInfo: [saving, setSaving],
            updated: [lastModified, setLastModified],
            workflowVersion: production ? publishedVersion : draftVersion,
            productionVersion: publishedVersion,
            openPublishDialog: [
              showPublishDialogIfNoProductionVersion,
              setShowPublishDialogIfNoProductionVersion,
            ],
            loadDialog: [loadingDialog, setLoadingDialog],
            unpublishedChanges: [checkUnpublishedChanges],
            isCanvasLocked: [isCanvasLocked, setIsCanvasLocked],
            componentInfoHeader: [
              componentInfoForNodeHeader,
              setComponentInfoForNodeHeader,
            ],
            canvasViewport: [viewport, setViewport],
            // Prebuild diagram state
            prebuildPreview: [openPrebuildPreview, setOpenPrebuildPreview],
            mermaidJS: [mermaidJSContent, setMermaidJSContent],
          }}
        >
          {/* Header */}
          <>
            <CanvasHeader aiBuilderOpen={aiBuilderOpen}>
              <DraftCanvasHeader
                saving={saving}
                pauseSave={pauseSave}
                changes={changes}
                production={production}
                firePublish={() => {
                  fireValidateDialog("publish");
                }}
                setOpenTestDialog={() => {
                  setOpenTestPanelAfterValidation(openTestPanel);
                }}
                saveDraftVersion={saveDraftVersion}
                fireTestDialog={setOpenTestTriggerDialog}
                fireShareDialog={fireShareDialog}
                fireRenamePlaceholdersDialog={(isOpen) =>
                  setRenamePlaceholdersDialog(isOpen)
                }
                publishedVersion={publishedVersion}
                draftVersion={draftVersion}
                lastModified={lastModified}
                ProjectStore={ProjectStore}
                saveDraft={() => save()}
                openStartDialog={openStartDialog}
                closeStartDialog={() => {
                  setOpenStartDialog(false);
                }}
                setShowPublishDialogIfNoProductionVersion={
                  setShowPublishDialogIfNoProductionVersion
                }
                openInPane={(compId) => {
                  let openedComponent = nodes.find(
                    (comp) => comp.id === compId
                  );

                  if (!openedComponent) return;

                  setEditComponent({
                    componentId: compId,
                    ...openedComponent.data,
                  });
                }}
                openAccess={openAccess}
                setOpenAccess={setOpenAccess}
                setOpenBrokenPHRename={(bool) => {
                  setAddressError(false);
                  setOpenBrokenPHRename(bool);
                  setRenamePlaceholdersDialog(bool);
                }}
                setRenameGivenPH={(givenPH) => {
                  setAddressError(false);
                  setRenameGivenPH(givenPH);
                  setRenamePlaceholdersDialog(true);
                }}
                setOpenTestPanel={setOpenTestPanel}
                navigate={navigate}
                setShowPublishDialog={setShowPublishDialog}
                setLoadingDialog={setLoadingDialog}
                checkUnpublishedChanges={checkUnpublishedChanges}
                isCanvasLocked={isCanvasLocked}
                workflow={workflow}
                setWorkflowName={setWorkflowName}
                handleSaveProjectName={handleSaveProjectName}
                handleBack={handleBack}
              />
            </CanvasHeader>

             {showAIBuilder && aiBuilderOpen && (
              <AIPanel
                open={aiBuilderOpen}
                resetAIStateToInitial={resetAIStateToInitial}
                setResetAIStateToInitial={setResetAIStateToInitial}
                handleClose={() => {
                  if (callTriggerCloseInChild) {
                    // trigger the handleClose funciton that discards the draft.
                    setTriggerCloseInChild(true);
                    setTimeout(() => {
                      setAiBuilderOpen(false);
                      setShowAIBuilder(false);
                    }, 1000);
                  } else {
                    setAiBuilderOpen(false);
                    setShowAIBuilder(false);
                  }
                }}
                setTriggerCloseInChild={setTriggerCloseInChild}
                setAiBuilderOpen={setAiBuilderOpen}
                setDisplayHelperOpen={setDisplayHelperOpen}
                draftVersion={draftVersion}
                setWorkflowName={setWorkflowName}
                handleSaveProjectName={handleSaveProjectName}
                showAIBuilder={showAIBuilder}
                setShowAIBuilder={setShowAIBuilder}
                getLatestVersion={getLatestVersion}
                refetchComponents={refetchComponents}
                isInAIDraft={isInAIDraft}
                setSelectedHistorySession={setSelectedHistorySession}
                showBuildPrompt={showBuildPrompt}
                setShowBuildPrompt={setShowBuildPrompt}
                isInit={isInit}
                close={() => {
                  setAiComponentBuilderOpen(false);
                  setAiComponentBuilderData("");
                  setOpenSessionHistory(false);
                  setResetHistory(null);
                  setShowAIBuilder(false);
                }}
                projectId={projectId}
                nodes={nodes}
                edges={edges}
                handleSave={null}
                reactFlowInstance={reactFlowInstance}
                setSaving={async () => {
                  setSaving(true);
                  setTimeout(() => {
                    setSaving(false), 3000;
                  });
                }}
                setPaneSkeleton={setPaneSkeleton}
                setAiComponentBuilderData={setAiComponentBuilderData}
                aiComponentBuilderData={aiComponentBuilderData}
                setComponentInstruction={null}
                setOpenTestPanel={setOpenTestPanel}
                startCompTestRun={() => {
                  setTestFromCompPanel(true);
                  setOpenTestPanel(
                    editComponent ? editComponent.componentId : ""
                  );
                }}
                openSessionHistory={openSessionHistory}
                setOpenSessionHistory={setOpenSessionHistory}
                selectedHistorySession={selectedHistorySession}
                setResetHistory={setResetHistory}
                setIsInAIDraft={setIsInAIDraft}
                sidebarState={sidebarState}
                setSidebarState={setSidebarState}
                isExpanded={window.location.href.includes("fullScreen=true")}
                setCompSessionId={setCompSessionId}
                compSessionId={compSessionId}
                CanvasStore={CanvasStore}
                editComponent={editComponent}
                aiComponentBuilderOpen={aiBuilderOpen}
                setAiComponentBuilderOpen={setAiBuilderOpen}
                setTestFromCompPanel={setTestFromCompPanel}
                triggerCloseInChild={triggerCloseInChild}
                setCallTriggerCloseInChild={setCallTriggerCloseInChild}
              />
            )}

            <>
              {showClosingAIDraftDialog && (
                <ComponentAIDraftDiscardDialog
                  handleClosePaneSave={handleClosePaneSave}
                  setShowClosingAIDraftDialog={setShowClosingAIDraftDialog}
                />
              )}
              <>
                <ClosePaneButton
                  editComponent={editComponent}
                  handleClose={handleClosePaneButtonClick}
                  aiComponentBuilderOpen={aiBuilderOpen}
                  openSessionHistory={openSessionHistory}
                  openTestPanel={openTestPanel}
                />
                <ComponentPane
                  isInit={isInit}
                  setIsInit={setIsInit}
                  showSuccess={showSuccess}
                  saving={saving}
                  setSaving={(val) => {
                    setSaving(val);
                  }}
                  openTestPanel={openTestPanel}
                  setOpenTestPanel={setOpenTestPanel}
                  setTestFromCompPanel={setTestFromCompPanel}
                  production={production}
                  open={openComponentPane}
                  pauseSave={pauseSave}
                  updatePauseSave={(newPauseSave) => {
                    if (newPauseSave) setMode("SAVE_PAUSED");
                    else setMode("DEFAULT");
                    setPauseSave(newPauseSave);
                  }}
                  version={production ? publishedVersion : draftVersion}
                  editComponent={editComponent}
                  close={handleClosePaneSave}
                  projectId={projectId}
                  childTemplateLoaded={childTemplateLoaded}
                  setChildTemplateLoaded={(val) => {
                    setChildTemplateLoaded(val);
                  }}
                  aiComponentBuilderOpen={aiBuilderOpen}
                  setAiComponentBuilderOpen={setAiBuilderOpen}
                  paneSkeleton={paneSkeleton}
                  setPaneSkeleton={setPaneSkeleton}
                  aiComponentBuilderData={aiComponentBuilderData}
                  showBuildPrompt={showBuildPrompt}
                  setShowBuildPrompt={setShowBuildPrompt}
                  mode={mode}
                  openSessionHistory={openSessionHistory}
                  setOpenSessionHistory={setOpenSessionHistory}
                  selectedHistorySession={selectedHistorySession}
                  setSelectedHistorySession={setSelectedHistorySession}
                  setResetHistory={setResetHistory}
                  isInAIDraft={isInAIDraft}
                  setShowClosingAIDraftDialog={setShowClosingAIDraftDialog}
                  compSessionId={compSessionId}
                  setShowAIBuilder={setShowAIBuilder}
                  addressError={addressError}
                />
              </>
              {openTestPanel && (
                <TestPanel
                  isOpenWithComponent={
                    editComponent
                      ? config.PANE_WIDTH
                      : showAIBuilder
                      ? config.AI_COMPONENT_BUILDER_PANE_WIDTH
                      : 0
                  }
                  executeTest={executeTest}
                  setExecuteTest={setExecuteTest}
                  showSuccess={showSuccess}
                  open={openTestPanel}
                  version={draftVersion}
                  projectId={projectId}
                  componentId={openTestPanel}
                  compData={nodes.find((comp) => comp.id === openTestPanel)}
                  handleClose={handleCloseTestPanel}
                  handleExpand={handleTestExpand}
                  isExpanded={window.location.href.includes("fullScreen=true")}
                  save={async () => {
                    if (
                      !production &&
                      editComponent &&
                      !editComponent.fromWorkflowPath
                    ) {
                      //saving component
                      if (!ProjectStore.state.production) {
                        ProjectStore.SaveTrigger["triggerValue"] =
                          ProjectStore.SaveTrigger["triggerValue"] === 1
                            ? 0
                            : 1;
                        ProjectStore.SaveTrigger["executeTest"] = true;
                      }
                    }
                  }}
                  handleEdit={(compId) => {
                    let openedComponent = ProjectStore.canvas.find(
                      (comp) => comp.id === compId
                    );
                    if (!openedComponent) return;
                    setEditComponent({
                      componentId: compId,
                      ...openedComponent.data,
                    });
                    setTestFromCompPanel(false);
                    setOpenTestPanel(null);
                  }}
                  childTemplateLoaded={childTemplateLoaded}
                  aiBuilderOpen={aiBuilderOpen}
                />
              )}
            </>

            {mode === "DEFAULT" ? (
              <Sidebar
                setAiBuilderOpen={setAiBuilderOpen}
                setDisplayHelperOpen={setDisplayHelperOpen}
                draftVersion={draftVersion}
                setWorkflowName={setWorkflowName}
                handleSaveProjectName={handleSaveProjectName}
                showAIBuilder={showAIBuilder}
                setShowAIBuilder={setShowAIBuilder}
                getLatestVersion={getLatestVersion}
                refetchComponents={refetchComponents}
                isInAIDraft={isInAIDraft}
                setSelectedHistorySession={setSelectedHistorySession}
                editComponent={editComponent}
              />
            ) : (
              ""
            )}
            <div className={"container"}>
              <CanvasWrapper
                ref={reactFlowWrapper}
                aiBuilderOpen={aiBuilderOpen}
              >
                <Canvas
                  saving={saving}
                  setSaving={(val) => {
                    setSaving(val);
                  }}
                  setLinkWarning={(val) => {
                    setLinkWarning(val);
                  }}
                  setNavigationWarning={(val) => {
                    setNavigationWarning(val);
                  }}
                  workflowId={workflow.projectId}
                  isTemplate={false}
                  mode={mode}
                  reactFlowInstance={reactFlowInstance}
                  setReactFlowInstance={setReactFlowInstance}
                  reactFlowWrapper={reactFlowWrapper}
                  viewport={viewport}
                  showNotes={showNotes}
                  setShowNotes={setShowNotes}
                  componentInfoForNodeHeader={componentInfoForNodeHeader}
                  setShowErrorSnackbar={setShowErrorSnackbar}
                  handleClosePaneButtonClick={handleClosePaneButtonClick}

                />
              </CanvasWrapper>
            </div>
          </>
          {ProjectStore.isCanvasPageTour && (
            <Tour
              steps={CanvasPageTourSteps}
              nextButton={
                <div>
                  <ArrowForward id="_tour_next_btn"></ArrowForward>
                </div>
              }
              isOpen={ProjectStore.isCanvasPageTour}
              onRequestClose={ProjectStore.closeCanvasPageTour}
              getCurrentStep={(currentStep) => {
                ProjectStore.setShowReadmeDialog(currentStep, readmeText);
              }}
            />
          )}
          {/* //remove this snackbar when all requests that trigger it are replaced
        with events */}
          {(showSnackbar || ProjectStore.savedComponent) && (
            <Snackbar
              open={showSnackbar || ProjectStore.savedComponent}
              onClose={handleCloseSnackbar}
              autoHideDuration={1000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              style={{
                boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.5)",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              <Alert
                variant="outlined"
                severity="info"
                icon={<CircularProgress size={24} />}
              >
                Saving changes...
              </Alert>
            </Snackbar>
          )}
          {isCanvasLocked && (
            <Snackbar
              open={isCanvasLocked}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              style={{
                boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.5)",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              <Alert variant="outlined" severity="info" icon={<></>}>
                {isCanvasLocked == true
                  ? "AI is building your workflow..."
                  : isCanvasLocked}
              </Alert>
            </Snackbar>
          )}
          {(displayHelperOpen || validationLoading) && (
            <div
              style={{
                background: validationLoading
                  ? "rgba(0, 0, 0, 0.2)"
                  : "rgba(255, 255, 255, 0)",
                height: "100vh",
                width: "100%",
                position: "fixed",
              }}
            />
          )}
        {linkWarning && (
            <Snackbar
                open={linkWarning}
                onClose={handleCloseSnackbar}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    icon={<></>}
                    onClose={handleCloseSnackbar}
                    variant={"filled"}
                    severity="error"
                >
                    Close the component panel before opening another component
                </Alert>
            </Snackbar>
        )}
          {showErrorSnackbar && (
            <Snackbar
              open
              onClose={() => setShowErrorSnackbar(null)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                icon={<></>}
                onClose={() => setShowErrorSnackbar(null)}
                variant={"filled"}
                severity="error"
              >
                {showErrorSnackbar}
              </Alert>
            </Snackbar>
          )}
          {ProjectStore.errorSaving && (
            <Snackbar
              open={ProjectStore.errorSaving}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                icon={<></>}
                onClose={handleCloseSnackbar}
                variant={"filled"}
                className={"saveError"}
                severity="error"
              >
                ⚠️ Your workflow could not be saved ⚠️ If this error persists,
                please contact us
              </Alert>
            </Snackbar>
          )}
          {saving && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              style={{
                boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.5)",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
              open={saving}
            >
              <Alert
                variant="outlined"
                severity="info"
                icon={<CircularProgress size={24} />}
              >
                Saving changes...
              </Alert>
            </Snackbar>
          )}
          {production && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={production}
            >
              <Alert
                style={{ backgroundColor: "#303030" }}
                variant={"filled"}
                icon={<></>}
              >
                🔒 This is a view of the production workflow. It cannot be
                edited and changes will not be saved.
              </Alert>
            </Snackbar>
          )}
          {/* DIALOGS */}
          {projectId && openShareDialog && (
            <ShareDialog
              workflow={workflow}
              projectId={projectId}
              isOpen={openShareDialog}
              closeDialog={() => {
                setOpenShareDialog(false);
              }}
              draftVersion={draftVersion}
              publishedVersion={publishedVersion}
              getLatestVersion={getLatestVersion}
            />
          )}
          {projectId && renamePlaceholdersDialog && (
            <RenamePlaceholdersDialog
              workflow={workflow}
              projectId={projectId}
              isOpen={renamePlaceholdersDialog}
              closeDialog={() => {
                setRenamePlaceholdersDialog(false);
              }}
              openBrokenPHRename={openBrokenPHRename}
              setOpenBrokenPHRename={setOpenBrokenPHRename}
              renameGivenPH={renameGivenPH}
              setRenameGivenPH={setRenameGivenPH}
            />
          )}
          {loadingDialog && (
            <CustomDialog
              size={"sm"}
              isOpen={loadingDialog}
              contents={
                <Grid
                  container
                  item
                  xs={12}
                  direction={"column"}
                  alignItems={"center"}
                >
                  {runLoading()}
                  {loadingDialog && <p>Switching workflow view</p>}
                  {/* {validationLoading && <p>Checking workflow for issues...</p>} */}
                </Grid>
              }
            />
          )}
          {openTestTriggerDialog && (
            <CustomDialog
              isOpen={openTestTriggerDialog}
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <ScienceIcon /> Start a test run
                </div>
              }
              contents={
                <>
                  <p>
                    You can start a test from any component by clicking the
                    component and then clicking the test run button.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/images/testFromTrigger.svg"
                      style={{ height: "300px", width: "auto" }}
                    />
                  </div>
                </>
              }
              buttons={
                <>
                  <Button
                    onClick={() => {
                      setOpenTestTriggerDialog(false);
                    }}
                    variant={"outlined"}
                    color={"info"}
                    disabled={loading}
                  >
                    Close
                  </Button>
                </>
              }
            ></CustomDialog>
          )}
          {publishDialog && (
            <CustomDialog
              isOpen={publishDialog}
              title={`Publish version ${draftVersion}`}
              zIndex={true}
              contents={
                <Grid container item xs={12} direction={"column"}>
                  <Grid
                    container
                    item
                    xs={12}
                    direction={"row"}
                    alignItems={"center"}
                    className={"frame"}
                  >
                    <Grid xs={5} container itemm direction={"column"}>
                      <p className={"bold"}>Draft</p>
                      <p className={"rmv-padding"}>Version {draftVersion}</p>
                      <Tooltip
                        title={
                          workflow.lastModified
                            ? format(
                                workflow.lastModified,
                                ProjectStore.state.timezone
                              )
                            : "none"
                        }
                      >
                        <div>
                          <p className={"s-text"}>
                            {`Last updated  ${
                              workflow.lastModified
                                ? format(
                                    workflow.lastModified,
                                    ProjectStore.state.timezone,
                                    "relative"
                                  )
                                : "none"
                            }`}
                          </p>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2} className={"arrowIcon"}>
                      <ArrowForward />
                    </Grid>
                    <Grid container item xs={5} direction={"column"}>
                      <p className={"bold"}>Production</p>
                      <p className={"rmv-padding"}>
                        Version {publishedVersion}
                      </p>

                      <Tooltip
                        title={
                          lastPublished
                            ? format(lastPublished, ProjectStore.state.timezone)
                            : "none"
                        }
                      >
                        <div>
                          <p className={"s-text"}>
                            {`Published  ${
                              lastPublished
                                ? format(
                                    lastPublished,
                                    ProjectStore.state.timezone,
                                    "relative"
                                  )
                                : "none"
                            }`}
                          </p>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <WorkflowPublishComment
                    setPublishComment={setPublishComment}
                  />
                  <p className={"bold"} style={{ marginTop: "12px" }}>
                    Have you run a test yet?
                  </p>
                  <p className={"s-text"} style={{ marginTop: "12px" }}>
                    Before publishing, we recommend that you run a test of the
                    draft workflow. Click the test button below to start a test
                    if you have not done so already.
                  </p>
                  <Button
                    style={{
                      background: "#7AC0F8",
                      color: "black",
                      width: 95,
                      marginTop: "12px",
                    }}
                    variant="contained"
                    startIcon={<ScienceIcon />}
                    onClick={() => {
                      setPublishDialog(false);
                      setOpenTestTriggerDialog(true);
                    }}
                  >
                    Test
                  </Button>
                  <p className={"s-text"}>
                    Click Publish to deploy version {draftVersion} of this
                    workflow into production.
                  </p>
                </Grid>
              }
              buttons={
                <>
                  <Button
                    onClick={() => setPublishDialog(false)}
                    disabled={loadPublish}
                    variant={"outlined"}
                    id="publish-dialog-cancel-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onPublish}
                    variant={"contained"}
                    color={"success"}
                    disabled={loadPublish}
                    id="publish-dialog-publish-btn"
                  >
                    Publish
                    {loadPublish && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                  </Button>
                </>
              }
            />
          )}
          {publishConfirmDialog && (
            <WorkflowPublishedDialog
              projectId={projectId}
              publishConfirmDialog={publishConfirmDialog}
              lastPublished={lastPublished}
              publishedVersion={publishedVersion}
              draftVersion={draftVersion}
              setPublishConfirmDialog={setPublishConfirmDialog}
              setOpenStartDialog={setOpenStartDialog}
              handleShare={handleShare}
              onDonePublish={onDonePublish}
              components={ProjectStore.components}
              setOpenAccess={setOpenAccess}
              publishComment={publishComment}
              navigate={navigate}
              setLoadingDialog={setLoadingDialog}
              unpublishedChanges={checkUnpublishedChanges}
            />
          )}
          {fireDeployToMarketDialog()}
          {showReadmeDialog && (
            <div data-tut={"open__readme"}>
              <CustomDialog
                isOpen={showReadmeDialog}
                title={`📖 Read Me for  ${
                  workflow.name ? workflow.name : "Untitled"
                }`}
                zIndex={true}
                size={"md"}
                contents={
                  <>
                    <p className="s-text">
                      <ReadMeDialog
                        contentStyles={{
                          borderRadius: "4px",
                          boxShadow: "none",
                          height: "auto",
                          minHeight: 200,
                          padding: 0,
                          minWidth: "100%",
                        }}
                        availablePlaceholders={[]}
                        html={readmeText}
                        placeholder={[]}
                      />
                    </p>
                  </>
                }
                buttons={
                  <>
                    <Button
                      onClick={
                        ProjectStore.isCanvasPageTour
                          ? ""
                          : handleDoNotShowAgain
                      }
                      variant={"outlined"}
                      color={"info"}
                    >
                      DO NOT SHOW AGAIN
                    </Button>
                    <Button
                      onClick={() => {
                        if (!ProjectStore.isCanvasPageTour) {
                          setReadme({
                            showReadmeDialog: false,
                            readme: readmeText,
                          });
                          setShowReadmeDialog(false);
                        }
                      }}
                      variant={"contained"}
                      color={"primary"}
                    >
                      CLOSE
                    </Button>
                  </>
                }
              />
            </div>
          )}
          {(addressError || validationLoading) && (
            <FixErrorsDialog
              open={addressError || validationLoading}
              fireValidateDialog={fireValidateDialog}
              validationLoading={validationLoading}
              errorsNumber={toJS(ProjectStore.errors).length}
              errorsArray={toJS(ProjectStore.errors)}
              project_id={projectId}
              close={() => {
                setAddressError(false);
                if (testFromCompPanel) {
                  let openedComponent = ProjectStore.canvas.find(
                    (comp) => comp.id === openTestPanel
                  );
                  if (!openedComponent) return;

                  setEditComponent({
                    componentId: openTestPanel,
                    ...openedComponent.data,
                  });
                  setTestFromCompPanel(false);
                }
                setOpenTestPanel(null);
              }}
              handlePublish={() => {
                setAddressError(false);
                setPublishDialog(true);
              }}
              openInPane={(compId) => {
                let openedComponent = nodes.find((comp) => comp.id === compId);

                if (!openedComponent) return;

                setEditComponent({
                  componentId: compId,
                  ...openedComponent.data,
                });
              }}
              validatingType={validatingType}
              setOpenTestDialog={() => {
                setOpenTestPanelAfterValidation(null);
                setAddressError(false);
                setOpenTestPanelAfterValidation(openTestPanel);
              }}
              setOpenBrokenPHRename={(bool) => {
                setOpenBrokenPHRename(bool);
                setRenamePlaceholdersDialog(bool);
              }}
              setRenameGivenPH={(givenPH) => {
                setRenameGivenPH(givenPH);
                setRenamePlaceholdersDialog(true);
              }}
              setNavigationWarning={setNavigationWarning}
              editComponent={editComponent}
            />
          )}
          {showErrorWorkflowDialog && (
            <ErrorWorkflowDialog
              open={showErrorWorkflowDialog}
              setOpen={setShowErrorWorkflowDialog}
              projectId={projectId}
              saveAsNewVersion={() => {
                saveDraftVersion();
                setShowErrorWorkflowDialog(false);
              }}
            />
          )}
          {showPublishDialogIfNoProductionVersion && (
            <CustomDialog
              isOpen={showPublishDialogIfNoProductionVersion}
              title={<div>This workflow has not been published</div>}
              contents={
                <>
                  <p>
                    You will need to publish this workflow into production
                    before running it.
                  </p>
                </>
              }
              buttons={
                <>
                  <ActionButton
                    variant="contained"
                    bgColor="#FFFFFF"
                    textColor="#212121"
                    borderColor="#212121"
                    onClick={() => {
                      setShowPublishDialogIfNoProductionVersion(false);
                    }}
                  >
                    Go Back
                  </ActionButton>
                  <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                      fireValidateDialog("publish");
                    }}
                  >
                    PUBLISH NOW
                  </Button>
                </>
              }
            ></CustomDialog>
          )}
          {showPublishDialog && (
            <CustomDialog
              isOpen={showPublishDialog}
              title={<div>You have unpublished changes</div>}
              contents={
                <>
                  <p>
                    You have changes in your current draft version that have not
                    been deployed to production. If you run this workflow now,
                    it will be running on{" "}
                    <Link
                      to={`/project/production/${projectId}`}
                      style={{ color: "rgba(33, 150, 243, 1)" }}
                      target="_blank"
                    >
                      version {publishedVersion}
                    </Link>{" "}
                    which may not have the changes you have made in your current
                    draft version {draftVersion}. Click Publish to
                    push these changes before running this workflow.
                  </p>
                </>
              }
              buttons={
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowPublishDialog(false);
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    color={"error"}
                    onClick={() => {
                      // Publishes the changes
                      fireValidateDialog("publish");
                      setShowPublishDialog(false);
                    }}
                  >
                    PUBLISH CHANGES
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const hasPublished = publishedVersion !== "None";

                      if (hasPublished) {
                        // Navigate with component open
                        if (compForceStartId)
                          navigate(
                            `/project/production/${projectId}?force_start=${compForceStartId}`
                          );
                        else navigate(`/project/production/${projectId}`);
                      } else {
                        setShowPublishDialogIfNoProductionVersion(true);
                      }

                      setCompForceStartId("");
                      setShowPublishDialog(false);
                    }}
                  >
                    CONTINUE
                  </Button>
                </>
              }
            />
          )}

        </CanvasContext.Provider>
      );
    else return showCanvasLoadingSkeleton();
  })
);

export default inject("ProjectStore")(observer(WorkflowCanvas));
