import React from "react";
import { useState, useEffect } from "react";

// MUI
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { Alert, CircularProgress, Drawer, Grid } from "@mui/material";

// Icons
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {
  Close,
  ErrorRounded,
  OpenInFull,
  PlayCircle,
  Science,
  PlayCircleOutline,
  Autorenew,
  ArrowBack,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PortalModal from "../Portal/PortalModal";

// Util
import { Link } from "react-router-dom";
import TestIframeRender from "./TestIframeRender";
import { send_request } from "../../utils/Request";
import { useLocation, useNavigate } from "react-router";
import AfterRunAlert from "../pages/Canvas/ForceStart/AfterRunAlert";
import ComponentNameFromId from "../ProjectCanvas/ComponentNameFromId";
import InputPlaceholdersBox from "../pages/Canvas/ForceStart/InputPlaceholdersBox";
import TriggerStartComponent from "../pages/Canvas/ForceStart/TriggerComponentUtil/TriggerStartComponent";
import {
  BlackButton,
  CustomGrid,
  DownArrow,
  ItemBox,
  RunIcon,
  Text,
  TriggerDescription,
  TriggerTitle,
} from "../pages/Canvas/ForceStart/CommonStyledComponents";
import config from "../../config";

const StyledAlert = styled(Alert)({
  color: "black",
  backgroundColor: "#FFEACB",
  border: "1px solid #FFB74D",
});

const StyledErrorIcon = styled(ErrorRounded)({
  color: "#FFB74D",
});

const StyledLink = styled(Link)({
  underline: "none",
  color: "#2196F3",
});

const TestPanel = (props) => {
  const isTrigger = checkIfTrigger(props.compData);
  const isFormOrBulkAssessment = checkIfFormTrigger(props.compData);
  const navigate = useNavigate();

  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);

  const [tests, setTests] = useState([]);
  const [placeholders, setPlaceholders] = useState(null);
  const [values, setValues] = useState(null);
  const [inputDataLocation, setInputDataLocation] = useState("manually-set");
  const [isLoading, setIsLoading] = useState(false);

  const [showAfterRunAlert, setShowAfterRunAlert] = useState(null);
  const [successSessionId, setSuccessSessionId] = useState(null);

  const [step, setStep] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loadingInputPHs, setLoadingInputPHs] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.append("force_start", props.componentId);

    // Check if there is an existing session id
    let selectedSessionId = queryParams.get("selectedSession");
    if (selectedSessionId) params.append("selectedSession", selectedSessionId);

    // In relation to the test, if it's a form or bulk assement
    // Set to step 1 (trigger selection menu)
    if (isFormOrBulkAssessment && !selectedSessionId) setStep(1);
    else setStep(2);

    navigate({ search: params.toString() });
  }, []);

  useEffect(async () => {
    if (!props.executeTest) return;

    await executeForceStartTest();

    props.setExecuteTest(false);
  }, [props.executeTest]);

  // Gets the UI to display related to the given step
  const getCurrentStep = () => {
    switch (step) {
      case 1:
        return getTriggerFormPanel();
      case 2:
        return getForceStartTestContent();
      case 3:
        return getTriggerContent();
      default:
        return <></>;
    }
  };

  // Checks if a given component is a trigger
  function checkIfTrigger(compData) {
    try {
      return !compData.data.hasInput;
    } catch (e) {
      console.log(e);
      return true;
    }
  }

  // Checks if a given component is a form/bulk assessment
  function checkIfFormTrigger(compData) {
    try {
      let comp = compData.data;
      return (
        !comp.hasInput &&
        (comp.type === "form" || comp.type === "bulk_assessment")
      );
    } catch (e) {
      console.log(e);
      return true;
    }
  }

  // Function for handling running of the force start test session
  const handleForceStartTest = async () => {
    if (props.isOpenWithComponent) {
      setIsLoading(true);
      await props.save();
    } else {
      await executeForceStartTest();
    }
  };

  const wait = (ms) =>
    new Promise((res) => {
      return setTimeout(res, ms);
    });

  const executeForceStartTest = async () => {
    setIsLoading(true);

    await wait(1000); // Wait 1 second

    let body = {
      inputtedPlaceholders: values,
      projectId: props.projectId,
      componentId: props.componentId,
      componentType: props.compData.data ? props.compData.data.type : "",
      versionToRun: props.version,
      existingSessionIdRanFrom: queryParams.get("selectedSession"),
      isTest: true,
    };

    const url = `project-service/project-session/force_start_workflow`;
    let json = await send_request(url, body, null, "POST");

    if (json && json.data) {
      setSuccessSessionId(json.data);
      setShowAfterRunAlert("success");
    } else {
      // Unsuccessful force-start
      setShowAfterRunAlert("error");
    }

    setIsLoading(false);
  };

  // Add this function to handle opening the modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Add this function to handle closing the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Modify the getTriggerContent function
  const getTriggerContent = () => {
    return (
      <>
        <ItemBox item xs={12} noPadding noShadow>
          <StyledAlert icon={<StyledErrorIcon />}>
            After submitting a test trigger, go to the{" "}
            <StyledLink
              to={`/project/logs/${props.projectId}?test=true`}
              target="_blank"
            >
              test session logs
            </StyledLink>{" "}
            to track the test session
          </StyledAlert>
        </ItemBox>
        <ItemBox item xs={12} noPadding noShadow>
          <Button
            sx={{
              textTransform: "unset !important",
              color: "black",
              marginRight: "8px",
            }}
            size={"small"}
            startIcon={<OpenInNewIcon />}
            onClick={handleOpenModal}
          >
            Browser preview
          </Button>
        </ItemBox>
        <ItemBox item xs={12} noPadding noShadow>
          <TestIframeRender
            version={props.version}
            projectId={props.projectId}
            componentId={props.componentId}
            compData={props.compData}
            tests={tests}
            setTests={setTests}
            isTestPane={true}
            refresh={refresh}
            setRefresh={setRefresh}
            loadingInputPHs={loadingInputPHs}
            setLoadingInputPHs={setLoadingInputPHs}
          />
        </ItemBox>
        <PortalModal
          open={modalOpen}
          onClose={handleCloseModal}
          url={`${config.FORM.FORM_FRONT_END}/form/form_test/${props.componentId}/${tests[0]?.testId}?isLatestDraft=true`}
        />
      </>
    );
  };

  // Gets the standard input placeholder UI
  const getForceStartTestContent = () => {
    return (
      <>
        <ItemBox item xs={12}>
          <InputPlaceholdersBox
            placeholders={placeholders}
            setPlaceholders={setPlaceholders}
            values={values}
            setValues={setValues}
            setIsLoading={setIsLoading}
            projectId={props.projectId}
            componentId={props.componentId}
            version={props.version}
            inputDataLocation={inputDataLocation}
            setInputDataLocation={setInputDataLocation}
            queryParams={queryParams}
            isTrigger={isTrigger}
            isTest={true}
            step={step}
            fetchTestOutput={props.fetchTestOutput}
            setFetchTestOutput={props.setFetchTestOutput}
            loadingInputPHs={loadingInputPHs}
            setLoadingInputPHs={setLoadingInputPHs}
          />
        </ItemBox>
        <Grid item xs={12}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <DownArrow />
          </Grid>
        </Grid>
        <ItemBox item xs={12}>
          <Grid container gap={"16px"}>
            <CustomGrid item xs={12} vertical="center" horizontal="flex-start">
              <RunIcon />
              <Text fontWeight="bold">
                Start test run from{" "}
                <ComponentNameFromId componentId={props.componentId} />
              </Text>
            </CustomGrid>
            <Grid item xs={12}>
              <Text>
                You are starting a test run from{" "}
                <b>
                  <ComponentNameFromId componentId={props.componentId} />
                </b>{" "}
                onwards. It will not run any components before this component.
                This component will be run using the input data specified above.
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                columnGap={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    className={"test-start-btn"}
                    onClick={handleForceStartTest}
                    disabled={
                      isLoading || !placeholders || !props.childTemplateLoaded
                    }
                    startIcon={isLoading ? <CircularProgress size={24} /> : <PlayCircle />}
                  >
                    {isLoading ? "Starting session..." : "TEST RUN"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {showAfterRunAlert && (
              <Grid item xs={12}>
                <AfterRunAlert
                  alertType={showAfterRunAlert}
                  sessionId={successSessionId}
                  projectId={props.projectId}
                  isLoading={isLoading}
                  isTest={true}
                />
              </Grid>
            )}
          </Grid>
        </ItemBox>
      </>
    );
  };

  // Gets the selection panel for the form and bulk assessment triggers
  const getTriggerFormPanel = () => {
    return (
      <>
        <ItemBox item xs={12}>
          <TriggerStartComponent
            component={props.compData.data}
            BlackButton={BlackButton}
            TriggerTitle={TriggerTitle}
            TriggerDescription={TriggerDescription}
            version={props.version}
            isTest={true}
            handleTestOpen={async () => {
              await props.save();
              setStep(3);
            }}
            save={props.save}
            disabled={
                isLoading  || !props.childTemplateLoaded
            }
          />
        </ItemBox>
        <>
          <Grid item xs={12}>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <b>OR</b>
            </Grid>
          </Grid>
          <ItemBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TriggerTitle>Manually trigger this component</TriggerTitle>
              </Grid>
              <Grid item xs={12}>
                <TriggerDescription>
                  You can manually trigger this component specifying its output
                  values
                </TriggerDescription>
              </Grid>
              <Grid item xs={12}>
                <BlackButton
                  variant="contained"
                  onClick={() => setStep(2)}
                  startIcon={<SkipNextIcon />}
                >
                  Manually Trigger
                </BlackButton>
              </Grid>
            </Grid>
          </ItemBox>
        </>
      </>
    );
  };

  const handleFormRefresh = async () => {
    if (step === 2) {
      // This is the force-start panel
      setLoadingInputPHs(true);
      await props.save();
      await wait(1000);
      props.setFetchTestOutput(true);
    } else if (step === 3) {
      // This is the iframe
      setLoadingInputPHs(true);
      await props.save();
      await wait(1000); // Wait 1 second
      setRefresh(true);
    }
  };

  const handleBackToStart = () => {
    setStep(isFormOrBulkAssessment ? 1 : 2);
    setShowAfterRunAlert(null);
    setSuccessSessionId(null);
  };

  return (
    <Drawer
      open={props.open}
      anchor={"right"}
      hideBackdrop={true}
      variant={"permanent"}
      onClose={props.handleClose}
      PaperProps={{
        sx: {
          width: config.TEST_PANE_WIDTH, //TODO switch to 40% if testing with 600px min width
          right: props.aiBuilderOpen ? config.AI_COMPONENT_BUILDER_PANE_WIDTH : "0",
          backgroundColor: "#F8F8F8",
          height: "100%",
          maxHeight: "100%",
          zIndex: 1001,
        },
      }}
    >
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        padding="16px 16px 8px 16px"
      >
        <Grid item>
          {step > 1 && (
            <Button
              sx={{
                textTransform: "unset !important",
                color: "black",
                marginRight: "8px",
              }}
              size={"small"}
              startIcon={<ArrowBack />}
              onClick={handleBackToStart}
            >
              Back
            </Button>
          )}
        </Grid>
        <Grid item>
          {step > 1 && isFormOrBulkAssessment && (
            <>
              <Button
                sx={{
                  textTransform: "unset !important",
                  color: "black",
                  marginRight: "8px",
                }}
                size={"small"}
                startIcon={<Autorenew />}
                onClick={() => handleFormRefresh()}
              >
                Refresh
              </Button>
            </>
          )}
        </Grid>
        <Grid item>
          <Button
            sx={{
              textTransform: "unset !important",
              color: "black",
            }}
            size={"small"}
            startIcon={<Close />}
            onClick={() => props.handleClose()}
          >
            Close
          </Button>
        </Grid>
      </Grid>
      <Grid container gap={2} padding="0px 16px 88px 16px">
        <ItemBox item xs={12}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  columnGap: "10px",
                }}
              >
                <Button
                  size={"small"}
                  color={"info"}
                  variant={"outlined"}
                  className="draftVersionButton"
                >
                  <div className="dot-red" />
                  Draft
                </Button>
                <span className={"bold"}>Running version {props.version}</span>
              </div>
            </Grid>
          </Grid>
        </ItemBox>
        {getCurrentStep()}
      </Grid>
      <PortalModal
        open={modalOpen}
        onClose={handleCloseModal}
        url={`${config.FORM.FORM_FRONT_END}/form/form_test/${props.componentId}/${tests[0]?.testId}?isLatestDraft=true`}
      />
    </Drawer>
  );
};

export default TestPanel;
