import React, { useState, useEffect, useContext } from "react";
import { inject, observer } from "mobx-react";

//mui
import { Grid, CircularProgress, Skeleton } from "@mui/material";

//styles
import { styled } from "@mui/system";
import "../../../util/chatMessageAnimations.css";
import { send_request } from "../../../../../../../utils/Request";
import {
  CheckCircle,
  Error,
  ExpandLess,
  ExpandMore
} from "@mui/icons-material";
import { useInterval } from "../Hooks/useInterval";
import { getStructuredChunk } from "../ChunkRenderer";
import { CanvasContext } from "../../../../WorkflowCanvas";
import {
  applyLayout,
  applyEditLayout,
  moveCollidingComponents,
  convertComponentChunk,
  handleSplitPathsLogic,
  setPositionInCanvasView,
  convertNoteChunk,
  handleNotesHistory,
  handleEditChanges,
  handleBuilderChanges
} from "../../../util/TranslateUtil";
import { delay } from "../../../util/SidebarUtil";
import AnimatedLoadingMessage from "../Messages/AnimatedLoadingMessage";
import {
  extractEdges,
  extractNodes
} from "../../../../../../../utils/CanvasUtil";
import _ from "lodash";
const LoadingContainer = styled(Grid)(({ theme }) => ({
  width: "100%!important",
  background: "#F5F5F5",
  borderRadius: "16px 16px 16px 0px",
  padding: "16px",
  fontSize: "14px",
  marginBottom: "16px",
  wordBreak: "break-word"
}));

const ContentContainer = styled(Grid)(({ isClickable, fullWidth }) => ({
  background: "#FFF",
  boxShadow: "0px 4px 4px 0px #00000040",
  padding: "16px",
  borderRadius: "6px",
  width: fullWidth ? "100%" : "fit-content",
  "&:hover": {
    cursor: isClickable && "pointer",
    color: isClickable && "rgba(0, 0, 0, 0.6)"
  }
}));

const SecondContentContainer = styled(Grid)(({ isClickable, fullWidth }) => ({
  background: "#FFF",
  boxShadow: "0px 4px 4px 0px #00000040",
  padding: "16px",
  borderRadius: "6px",
  marginTop:"16px",
  width: fullWidth ? "100%" : "fit-content",
  "&:hover": {
    cursor: isClickable && "pointer",
    color: isClickable && "rgba(0, 0, 0, 0.6)"
  }
}));

const StyledSkeleton = styled(Skeleton)(({ height, margin }) => ({
  borderRadius: "4px",
  background: "#E8E8E8",
  width: "100%",
  height: height,
  margin: margin
}));

const CenterGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  wordBreak: "break-word"
});

const Open = styled(ExpandMore)({});

const Close = styled(ExpandLess)({});

// These are in seconds
const POLLING_INTERVAL = 1;

const StreamingMessage = inject("CanvasStore")(
  observer(props => {
    const { canvasInstance } = useContext(CanvasContext);
    const { CanvasStore } = props;

    const [reactFlowInstance, setReactFlowInstance] = canvasInstance
      ? canvasInstance
      : "";

    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [segments, setSegments] = useState([]);

    const [openSummary, setOpenSummary] = useState(true);
    const [showTyping, setShowTyping] = useState(false);

    const [animation, setAnimation] = useState(props.showAnimation);

    const [displayStatic, setDisplayStatic] = useState("");

    const [numberOfDrawn, setNumberOfDrawn] = useState(0);
    const [hasError, setHasError] = useState(false);

    let isFirstComponent = true;
    let isFirstDatabase = true;
    let isTitle = true;

    // For loading history
    useEffect(
      () => {
        if (!props.loadingHistory) return;
        let newSegments;

        //this is loading historic notes session
        if (props.loadingHistory.collection) {
          newSegments = handleNotesHistory(props.loadingHistory);
          props.setStreamingStatus("COMPLETED");
        } else if(props.loadingHistory.editChangesDTO){
          newSegments = handleEditChanges(segments, props.loadingHistory);
        } else if(props.loadingHistory.segments){
          newSegments = handleSplitPathsLogic(props.loadingHistory.segments); // Handle split paths logic
        } else if (props.loadingHistory.components && props.loadingHistory.aiSolution) {
          // Handle sync for workflow builder
          newSegments = handleBuilderChanges(props.loadingHistory);
        }

        if (newSegments) setSegments(newSegments);

        setIsSendingRequest(false);
        setShowLoader(false);
        setDisplayStatic(
          props.loadingHistory.wasCancelled ? "CANCELLED" : "COMPLETED"
        );
      },
      [props.loadingHistory]
    );

    useEffect(() => {
      if (displayStatic || props.loadingHistory) return;
      let sessionId = props.sessionId;
      if (!sessionId) return;

      const typingDelay = setTimeout(() => {
        setShowTyping(true);
      }, 500);

      return () => {
        // Clean up, clear the interval
        clearTimeout(typingDelay);
        setSegments([]);
      };
    }, []);

    // For handling completed build
    useEffect(
      () => {
        // Return if displaying static or not yet completed
        if (displayStatic || !props.streamingStatus || props.loadingHistory)
          return;
        setAnimation(false);
        setShowTyping(false);
        setShowLoader(false);

        if (props.streamingStatus === "CANCELLED") handleCompleted("CANCELLED", false);
      },
      [props.streamingStatus]
    );

    useInterval(
      async () => {
        if (displayStatic || !props.sessionId) return;

        const info = await pollSegment();
        if (
          info &&
          info.segments &&
          info.segments.length !== segments.length &&
          openSummary
        ) {
          setTimeout(() => {
            const ele = document.getElementById(
              `preview-bottom-summary-${props.sessionId}`
            );
            if (!ele) return;
            ele.scrollIntoView({ behavior: "smooth" });
          }, 50);
        }
      },
      POLLING_INTERVAL * 1000,
      props.streamingStatus !== ""
    );

    const pollSegment = async () => {
      if (isSendingRequest || displayStatic) return;

      setIsSendingRequest(true);
      setShowLoader(true);

      let url = `project-service/project-ai/assistant/segment/${props.sessionId}`;
      const json = await send_request(url, null, null, "GET");

      await delay(1000);

      if (json && json.data) {
        let res = json.data;

        // If there's an error, handle accordingly
        if (res.aierror && res.aierror.hasError) {
          handleError(res.aierror);
          return;
        }

        if (res.sessionCancelled) {
          setDisplayStatic("CANCELLED");
          setShowLoader(false);
        }

        let newSegments = handleSplitPathsLogic(res.segments); // Handle split paths logic

        setSegments(newSegments);

        // We do this because we know there has been another chunk loaded
        // using lodash for performance
        if (!_.isEqual(newSegments.length, segments.length)) {
          handleDrawingLogic(newSegments);
        }

        if (res.sessionEnd) handleCompleted("COMPLETED", res.skipTranslation);

        setIsSendingRequest(false);

        res.segments = newSegments;
        return res;
      }

      setIsSendingRequest(false);

      return;
    };

    const handleDrawingForEditor = (componentSegments) => {

      let rfiObj = reactFlowInstance.toObject();

      let drawComponents = [];
      let drawLinks = [];
      let increase = 0;
      let lastCompId = null;

      // Create mapping from component id -> component
      let compIdToCompMap = {};
      for (let i = 0; i < rfiObj.nodes.length; i++) {
        const node = rfiObj.nodes[i];
        if (!node) continue;
        compIdToCompMap[node.id] = node;
      }

      let drawCompIdToSegment = {};

      for (let i = 0; i < componentSegments.length; i++) {
        if (i >= numberOfDrawn) {
          const item = convertComponentChunk(componentSegments[i], true, compIdToCompMap, drawCompIdToSegment);

          drawComponents = [...drawComponents, ...item.components];
          drawLinks = [...drawLinks, ...item.links];
          increase++;

          lastCompId = componentSegments[i].segmentPayload.componentId
            ? componentSegments[i].segmentPayload.componentId
            : componentSegments[i].segmentPayload.id; // Set lastCompId
        }
      }

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5)
      };

      const isInitialDrawing = numberOfDrawn <= 0;
      if (isInitialDrawing) props.setViewportOnGenerate(viewport);
      setNumberOfDrawn(numberOfDrawn + increase);


      // Drawing logic here
      const allNodesAndEdges = [...rfiObj.nodes, ...rfiObj.edges]; // Grab all existing nodes & edges on canvas
      const updatedCanvas = applyEditLayout(allNodesAndEdges, drawComponents, drawLinks, compIdToCompMap, drawCompIdToSegment);
      props.setNewDiagram(updatedCanvas); // Set the new diagram

      updateCanvasAndFocus(updatedCanvas, lastCompId);
    }

    const updateCanvasAndFocus = (updatedCanvas, lastCompId) => {
      // Set the canvas
      CanvasStore.setNodes([]);
      CanvasStore.setEdges([]);

      CanvasStore.setNodes(
        extractNodes(updatedCanvas).map(item => {
          return {
            ...item,
            id: String(item.id)
          };
        })
      );

      CanvasStore.setEdges(
        extractEdges(updatedCanvas).map(item => {
          return {
            ...item,
            source: String(item.source),
            target: String(item.target)
          };
        })
      );

      // If we found the last component, we want to set it into focus
      // 1. Find the position from the list of components
      // 2. Set the xOffset and remainingCanvasWidth values
      // 3. Call the function to perform the smooth transition
      if (lastCompId) {
        // Try to find the component from the list of components
        const comp = updatedCanvas.find(
          comp =>
            (comp.type === "component" || comp.type == "note") &&
            comp.id == lastCompId
        );
        if (!comp || !comp.position) return;

        let xOffset = 100;
        let remainingCanvasWidthAsDecimal = 0.67;

        // If the session history side bar is opened
        // Reduce the x offset and and remaining canvas width
        if (props.openSessionHistory) {
          xOffset = 0;
          remainingCanvasWidthAsDecimal = 0.55;
        }

        setPositionInCanvasView(
          comp.position,
          xOffset,
          reactFlowInstance,
          false,
          null,
          remainingCanvasWidthAsDecimal
        );
      }
    }

    /**
     * 
     * In this function, we do the following:
     * 1. Grab the latest list of component segments
     * 2. Convert each component chunk and create each link if the previous component is given
     * 3. Save the viewport if it is the first component being inserted
     * 4. Go over the existing canvas and collect all the currently drawn AI components & links
     * 5. We then combine everything (new drawn components/links, existing ai components/links drawn, etc) and applyLayout to it (i.e: the graphing algorithm)
     * 6. After this, we grab all the non-ai components & links
     * 7. With this, we apply the moveCollidingComponents which will attempt to prevent collisions between existing components on the canvas and the ai components
     * 8. We then remove the ai components/links that are already drawn onto the canvas
     * 9. And then we add our new chunk components to the canvas
     * 
     */
    const handleDrawingLogic = async resSegments => {
      if (props.pauseDrawingLogic) return;
      else {
        // Grab all the component and note segments
        let componentSegments = resSegments.filter(
          segment =>
            segment.segmentType === "PROJECT_COMPONENT" ||
            segment.segmentType === "PROJECT_NOTE"
        );

        if (!componentSegments || componentSegments.length <= 0) return;

        if (props.sidebarState === "WORKFLOW_EDITOR") {
          handleDrawingForEditor(componentSegments);
          return;
        }

        // Grab the components and links that we need to draw
        let drawComponents = [];
        let drawLinks = [];
        let increase = 0;

        let lastCompId = null;

        // Here, we grab the latest components and notes that we haven't drawn on the canvas
        for (let i = 0; i < componentSegments.length; i++) {
          if (i >= numberOfDrawn) {
            let item;
            if (componentSegments[i].segmentType === "PROJECT_NOTE") {
              item = convertNoteChunk(componentSegments[i]);
            } else {
              item = convertComponentChunk(componentSegments[i], false, {}, {});
            }

            drawComponents = [...drawComponents, ...item.components];
            drawLinks = [...drawLinks, ...item.links];
            increase++;

            lastCompId = componentSegments[i].segmentPayload.componentId
              ? componentSegments[i].segmentPayload.componentId
              : componentSegments[i].segmentPayload.id; // Set lastCompId
          }
        }

        // Grab the viewport on generate and save
        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5)
        };

        const isInitialDrawing = numberOfDrawn <= 0;

        if (isInitialDrawing) props.setViewportOnGenerate(viewport);
        setNumberOfDrawn(numberOfDrawn + increase);

        const currentAIComps = [];
        const currentAILinks = [];

        const aiCompIdSet = new Set();

        // Loop over the canvas and add all the pre-existing ai components and ai links
        // Only do this if it is not the initial drawing as we want to clear the previous draft
        const allNodesAndEdges = [...rfiObj.nodes, ...rfiObj.edges];

        if (!isInitialDrawing) {
          for (let canvasItem of allNodesAndEdges) {
            if (canvasItem && canvasItem.data && canvasItem.data.isTemp) {
              currentAIComps.push(canvasItem); // Add the canvas item
              aiCompIdSet.add(canvasItem.id); // Add to comp id set
            } else if (canvasItem.type === "link" && canvasItem.isTemp) {
              currentAILinks.push(canvasItem);
              aiCompIdSet.add(canvasItem.id); // Add to comp id set
            }
          }
        }

        // Put all components and links together
        // We do this because we need the dagre apply layout to consider all nodes
        const allComps = [
          ...currentAIComps,
          ...currentAILinks,
          ...drawComponents,
          ...drawLinks
        ];

        const afterLayout = applyLayout(
          allComps,
          props.viewportOnGenerate ? props.viewportOnGenerate : viewport
        );
        props.setNewDiagram(afterLayout); // Set the new diagram

        // Remove from colliding with real nodes/links on the canvas
        let canvasWithoutAI = [];
        for (let canvasItem of allNodesAndEdges) {
          if (
            !canvasItem ||
            canvasItem.isTemp ||
            (canvasItem.data && canvasItem.data.isTemp)
          )
            continue;
          canvasWithoutAI.push(canvasItem);
        }

        const afterMovingColliding = moveCollidingComponents(
          afterLayout,
          canvasWithoutAI
        );

        // We then need to remove the duplicates
        // i.e: We need to remove the components/links we added previously to "allComps"
        let finalWithoutDuplicates = [];
        for (let newItem of afterMovingColliding) {
          if (aiCompIdSet.has(newItem.id)) continue;
          finalWithoutDuplicates.push(newItem);
        }

        let canvasWithoutPreviousAI = [];

        // Set the canvas normally if it's not the initial drawing
        // Else, we want to remove the previous ai draft
        if (!isInitialDrawing) canvasWithoutPreviousAI = [...allNodesAndEdges];
        else {
          for (let canvasItem of allNodesAndEdges) {
            if (canvasItem && canvasItem.data && canvasItem.data.isTemp)
              continue;
            else if (canvasItem.type === "link" && canvasItem.isTemp) continue;
            else canvasWithoutPreviousAI.push(canvasItem);
          }
        }

        const updatedCanvas = [
          ...canvasWithoutPreviousAI,
          ...finalWithoutDuplicates
        ];

        updateCanvasAndFocus(updatedCanvas, lastCompId); // Set the canvas
      }
    };

    // Called when a workflow has been completely parsed
    // Sets loaders to false and displays the message now as static
    const handleCompleted = (status, skipTranslation) => {
      setIsSendingRequest(false);
      setShowLoader(false);
      setDisplayStatic(status);

      const finalStatus = fetchCompletionStatus(status, skipTranslation);
      props.setStreamingStatus(finalStatus);
    };

    const fetchCompletionStatus = (passedStatus, skipTranslation) => {
      if (!skipTranslation) return passedStatus; // Return status if not skipping

      // We want to skip, handle what state can skip
      switch (props.sidebarState) {
        case "STRATEGY":
        case "DISCOVERY":
        case "WORKFLOW_EDITOR":
          return "SKIP_TRANSLATION";
        default:
          return passedStatus;
      }
    }

    // Handles an error appearing in the streaming
    const handleError = aiError => {
      setHasError(true);
      setOpenSummary(false);
      setIsSendingRequest(false);
      setShowLoader(false);
      setDisplayStatic("ERROR");
      props.setError(aiError);
    };

    const handleTitle = (type, payload) => {

      if (type === "PROJECT_COMPONENT" && isFirstComponent) {
        isFirstComponent = false;
        return (
          <Grid item xs={12} marginTop="16px">
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              Components
            </span>
          </Grid>
        );
      } else if (type === "PROJECT_DATABASE" && isFirstDatabase) {
        isFirstDatabase = false;
        return (
          <Grid item xs={12} marginTop="16px">
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              Databases
            </span>
          </Grid>
        );
      } else if (
        type === "PROJECT_NAME" &&
        isTitle &&
        props.streamingStatus !== "CANCELLED"
      ) {
        isTitle = false;
        let projectName = payload["workflowName"];
        props.setWorkflowName(projectName);
      } else if (
        type === "CHAT_TITLE" &&
        isTitle &&
        props.streamingStatus !== "CANCELLED"
      ) {
        isTitle = false;
        let projectName = payload["chatTitle"];
        props.setWorkflowName(projectName);
      }
    };

    const getIcon = () => {
      if (hasError)
        return (
          <Error style={{ height: "18px", width: "auto", color: "#b00020" }} />
        );

      if (displayStatic === "CANCELLED")
        return (
          <Error style={{ height: "18px", width: "auto", color: "#b00020" }} />
        );

      if (!props.streamingStatus && !displayStatic)
        return <CircularProgress size={14} thickness="4.4" />;
      else if (props.streamingStatus === "CANCELLED")
        return (
          <Error style={{ height: "18px", width: "auto", color: "#b00020" }} />
        );
      else
        return (
          <CheckCircle
            style={{ height: "18px", width: "auto", color: "#55A77A" }}
          />
        );
    };

    const getTitle = () => {
      switch (props.sidebarState) {
        case "STRATEGY":
        case "DISCOVERY": {
          if (!props.streamingStatus && !displayStatic)
            return "Generating answer";
          else return "Developing notes";
        }
        case "WORKFLOW_EDITOR":
          return "Designing changes to meet requirements";
        default:
          return "Building workflow";
      }
    };

    const renderHeader = (sidebarState) => {
      switch (sidebarState) {
        case "WORKFLOW_EDITOR":
          return (
            <>
              <ContentContainer container columnGap={1} isClickable>
                <CenterGrid item>{segments.length > 0 ? 
                  <CheckCircle
                    style={{ height: "18px", width: "auto", color: "#55A77A" }}
                    /> : getIcon()}
                  </CenterGrid>
                <CenterGrid item>
                  <span className={"m-0"}>Syncing with current workflow</span>
                </CenterGrid>
              </ContentContainer>
              {segments.length > 0 ?  
                <SecondContentContainer container columnGap={1} isClickable>
                  <CenterGrid item>{getIcon()}</CenterGrid>
                  <CenterGrid item>
                    <span className={"m-0"}>Designing changes to meet requirements</span>
                  </CenterGrid>
                </SecondContentContainer>
                : ""}
            </>
          );
        default:
          return (
            <>
              <ContentContainer container columnGap={1} isClickable onClick={() => setOpenSummary(!openSummary)}>
                <CenterGrid item>{getIcon()}</CenterGrid>
                <CenterGrid item><span>{getTitle()}</span></CenterGrid>
                <CenterGrid item>{openSummary ? <Open /> : <Close />}</CenterGrid>
              </ContentContainer>
            </>
          );
      }
    }
    const getBuildingWorkflowUI = () => {
      // Function to determine if a segment should go in a new container
      const isNewContainerSegment = (segmentType) => {
        return props.sidebarState === "WORKFLOW_EDITOR" && [
          "COMPONENTS_TO_ADD",
          "COMPONENTS_TO_UPDATE",
          "COMPONENTS_TO_DELETE",
          "DATABASES_TO_CREATE",
          "DATABASES_TO_UPDATE",
          "DATABASES_TO_DISCONNECT",
          // PROJECT_COMPONENT and PROJECT_DATABASE are intentionally omitted
        ].includes(segmentType);
      };
    
      // Group segments into sub-arrays for each ContentContainer
      const containerSegments = [];
      let currentContainer = [];
      
      segments.forEach(segment => {
        if (isNewContainerSegment(segment.segmentType) || currentContainer.length === 0) {
          if (currentContainer.length > 0) {
            containerSegments.push(currentContainer);
          }
          currentContainer = [segment];
        } else {
          currentContainer.push(segment);
        }
      });
      if (currentContainer.length > 0) {
        containerSegments.push(currentContainer);
      }
    
      const isLoaderNeeded = showLoader && !displayStatic;

      const focusObject = {
        rfInstance: reactFlowInstance,
        openSessionHistory: props.openSessionHistory,
        targetZoom: 1.0
      }
    
      return (
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            
            {renderHeader(props.sidebarState)}
          </Grid>
          {openSummary && (
            <>
              {containerSegments.map((segmentsArray, containerIdx) => (
                <Grid item xs={12} key={`container-${containerIdx}`}>
                  <ContentContainer container rowGap={3} fullWidth>
                    {segmentsArray.map((segment, segmentIdx) => (
                      <Grid item xs={12} key={`segment-${segmentIdx}`}>
                        {getStructuredChunk(segment.segmentType, segment.segmentPayload, focusObject)}
                      </Grid>
                    ))}
                    {isLoaderNeeded && containerIdx === containerSegments.length - 1 && (
                      <Grid item xs={12} paddingBottom="8px" id="preview-bottom-summary">
                        <StyledSkeleton variant="rectangular" height="60px" />
                      </Grid>
                    )}
                  </ContentContainer>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      );
    };
    
    

    return (
      <Grid item xs>
        <div className="message-bubble">
          <LoadingContainer container rowGap={2}>
            <Grid item xs={12}>
              {getBuildingWorkflowUI()}
            </Grid>
          </LoadingContainer>
          {!displayStatic && animation && <AnimatedLoadingMessage />}
        </div>
      </Grid>
    );
  })
);

export default StreamingMessage;
