import { computed, observable, decorate, action, toJS } from "mobx";
import {
  JsDemoCode,
  PythonDemoCode,
} from "./CodeComponent/AceEditorPlaceholder";

class CodeStore {
  constructor() {
    this.initialiseEmpty();
  }

  getNodeJSLangName = () => {
    return "NodeJS";
  };

  getPythonLangName = () => {
    return "Python";
  };

  setTemplateData = (data) => {
    this.data = data;
  };

  setCodeData = (component, currentVersion) => {
    this.componentId = component.componentId;
    this.type = component.type;

    if (component.componentData) {
      this.data = component.componentData.data;

      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
      if (this.data.code === "CodePlaceholder") {
        if (this.data.codeLang.includes(this.getNodeJSLangName())) {
          this.data.code = JsDemoCode;
        } else if (this.data.codeLang.includes(this.getPythonLangName())) {
          this.data.code = PythonDemoCode;
        }
      }
      if (!this.data.parameters) {
        this.data.parameters = [];
      }
      if (!this.data.libraries) {
        this.data.libraries = [];
      }
      if (!this.data.availableIps) {
        this.data.availableIps = [];
      }
      if (!this.userHasInternetAccess) {
        this.userHasInternetAccess = false;
      }
    } else {
      if (typeof currentVersion != "number") {
        currentVersion = Number(currentVersion);
      }
      this.data = this.codeData(currentVersion);
      this.lastModified = "";
      if (this.type === "advanced_code") {
        this.name = "Advanced Run Code";
        this.data.enableVpc = true;
      } else {
        this.name = "Run Code";
        this.data.enableVpc = false;
      }
    }
    if (!this.data.placeholders || this.data.placeholders.length === 0) {
      this.data.placeholders = [];
      const newPlaceholder = {
        key: "",
        label: "",
        text: "",
        length: 1,
        style: {},
        type: "text",
      };
      this.data.placeholders.push(newPlaceholder);
    }
  };

  setCodeInitialData = (initialData) => {
    if (!initialData) {
      return;
    }

    if (initialData.data.availableIps) {
      this.data.availableIps = initialData.data.availableIps;
    }
    if (initialData.data.vpcIp) {
      this.data.vpcIp = initialData.data.vpcIp;
    }
  };

  setCodeName = (name) => {
    this.name = name;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };

  deleteChip = (index) => {
    const newChips = [...this.data.parameters];
    newChips.splice(index, 1);
    this.data.parameters = newChips;
  };

  addPlaceholders = (value) => {
    for (let i = 0; i < this.placeholders.length; i++) {
      if (value.key && value.key === this.placeholders[i].key) {
        return;
      }
    }
    this.placeholders.push(value);
  };

  deletePlaceholders = (index) => {
    const newPlaceholders = [...this.placeholders];
    newPlaceholders.splice(index, 1);
    this.placeholders = newPlaceholders;
  };

  deleteLibrary = (index) => {
    const newLibrary = [...this.data.libraries];
    newLibrary.splice(index, 1);
    this.data.libraries = newLibrary;
  };

  deleteCodePlaceholder = (index) => {
    if (this.data.placeholders.length > index && index >= 0) {
      const newPlaceholders = [...this.data.placeholders];
      newPlaceholders.splice(index, 1);
      this.data.placeholders = newPlaceholders;
    }
  };

  isAdvanced = () => {
    return this.type === "advanced_code";
  };

  isEnableVpc = () => {
    return this.data.enableVpc;
  };

  getVpcIp = () => {
    return this.data.vpcIp;
  };

  getAvailableIps = () => {
    return this.data.availableIps;
  };

  getLibraries = () => {
    return this.data.libraries;
  };

  getType = () => {
    return this.data.type;
  };
  isUserHasInternetAccess = () => {
    return this.data.userHasInternetAccess;
  };


  isUserHasInternetAccess = () => {
    return this.data.userHasInternetAccess;
  };

  getNewPlaceholderFromValue = (payload) => {
    const index = payload.index;
    const attribute = payload.attribute;
    let attributeValue = payload.value;
    let newPlaceholder = {
      key: "",
      label: "",
      text: "",
      length: 1,
      style: {},
      type: "text",
    };
    if (this.data.placeholders.length <= index) {
      this.data.placeholders.push(newPlaceholder);
    }

    switch (attribute) {
      case "key":
        this.data.placeholders[index].key = attributeValue;
        break;
      case "label":
        this.data.placeholders[index].label = attributeValue;
        break;
      case "type":
        this.data.placeholders[index].type = attributeValue;
        break;
      case "text":
        this.data.placeholders[index].text = attributeValue;
        break;
      case "length":
        if (typeof value != "number") {
          attributeValue = Number(value);
        }
        this.data.placeholders[index].length = attributeValue;
        break;
      case "style":
        this.data.placeholders[index].style = attributeValue;
        break;
    }

    if (!this.data.placeholders) {
      this.data.placeholders = [];
    }

    return this.data.placeholders;
  };

  codeData = (currentVersion) => {
    const placeholderArray = [];
    const newPlaceholder = {
      key: "",
      label: "",
      text: "",
      length: 1,
      style: {},
      type: "text",
    };
    placeholderArray.push(newPlaceholder);
    return {
      code: PythonDemoCode,
      codeLang: "Python_3_9",
      enableVpc: false,
      vpcIp: "",
      libraries: [],
      parameters: [],
      placeholders: placeholderArray,
      availableIps: [],
      version: currentVersion,
      userHasInternetAccess: false
      };
  };

  setCode = (value, type) => {
    if (value !== undefined) {
      switch (type) {
        case "code":
          this.data.code = value;
          return;
        case "codeLang":
          this.data.codeLang = value;
          return;
        case "parameters":
          if (this.data.parameters) {
            for (let i = 0; i < this.data.parameters.length; i++) {
              if (
                this.data.parameters[i] &&
                this.data.parameters[i] === value
              ) {
                return;
              }
            }
            this.data.parameters.push(value);
          }
          return;
        case "placeholders":
          this.data.placeholders = this.getNewPlaceholderFromValue(value);
          return;
        case "enableVpc":
          this.data.enableVpc = !!value;
          return;
        case "vpcIp":
          this.data.vpcIp = value;
          return;
        case "libraries":
          this.data.libraries = value;
          return;
        case "version":
          if (typeof value != "number") {
            value = Number(value);
          }
          this.data.version = value;
          return;
        case "userHasInternetAccess":
          this.data.userHasInternetAccess = !!value;
          return;

      }
    }
  };

  initialiseEmpty = () => {
    this.name = "Run code";
    this.position = null;
    this.componentId = "";
    this.type = "code";
    this.lastModified = "";
    this.data = this.codeData(0);
    this.error = false;
    this.placeholders = [];
    this.userHasInternetAccess = false;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: this.placeholders,
      userHasInternetAccess: this.userHasInternetAccess
    };
  }
}

decorate(CodeStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable.shallow,
  type: observable,
  lastModified: observable,
  placeholders: observable,
  credentialTokens: observable,
  error: observable,
  setCodeData: action,
  setCode: action,
  setCodeName: action,
  setLastModified: action,
  initialiseEmpty: action,
  deleteCodePlaceholder: action,
  isAdvanced: action,
  isEnableVpc: action,
  getVpcIp: action,
  getType: action,
  template: computed,
});

const codeStore = new CodeStore();
export default codeStore;
