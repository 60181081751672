import React from "react";
//nivo
import { ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";
import { runLoading } from "../../../utils/showLoadingScreen";
//just a placeholder
const data = [
  {
    id: "executed",
    label: "executed",
    value: 468,
    color: "hsl(123, 41%, 45%)"
  },
  {
    id: "error",
    label: "error",
    value: 73,
    color: "hsl(349, 100%, 35%)"
  },
  {
    id: "waiting",
    label: "waiting",
    value: 38,
    color: "hsl(36, 100%, 48%)"
  }
];

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "52px",
        fontWeight: 600
      }}
    >
      {total}
    </text>
  );
};
const DoughnutChart = (props) => {
  if (props.data) {
    return (
      <ResponsivePie
        data={data}
        colors={[
          "hsl(123, 41%, 45%)",
          "hsl(349, 100%, 35%)",
          "hsl(36, 100%, 48%)"
        ]}
        margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
        innerRadius={0.75}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric
        ]}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]]
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        arcLabelsComponent={({ datum, label, style }) => (
          <animated.g
            transform={style.transform}
            style={{ pointerEvents: "none" }}
          >
            <circle fill={style.textColor} cy={6} r={15} />
            <circle
              fill="#ffffff"
              stroke={datum.color}
              strokeWidth={2}
              r={16}
            />
            <text
              textAnchor="middle"
              dominantBaseline="central"
              fill={style.textColor}
              style={{
                fontSize: 10,
                fontWeight: 800
              }}
            >
              {label}
            </text>
          </animated.g>
        )}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000"
                }
              }
            ]
          }
        ]}
      />
    );
  } else return runLoading();
};
export default DoughnutChart;