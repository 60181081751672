import React from "react";
import { Grid, Button } from "@mui/material";
import {
  Check,
  CheckCircleOutline,
  PlayCircle,
  Refresh,
  Replay,
  RestartAlt,
  AutoAwesome,
  SkipNext,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { fetchCorrectButtonWhenProposalDisplayed } from "./ChatStateManager";

const TestRunButton = styled(Button)({
  textTransform: "none",
  paddingLeft: "20px",
  paddingRight: "20px",
  color: "#FFF",
  background: "rgba(255, 88, 0, 1)",
  borderColor: "rgba(255, 88, 0, 1)",
  "&:hover": {
    background: "rgba(255, 88, 0, 0.85)",
    borderColor: "rgba(255, 88, 0, 1)",
    color: "#FFF",
  },
});

function ChatButtons(props) {
  const renderBuildButton = () => {
    switch (props.sidebarState) {
      case "STRATEGY":
      case "DISCOVERY":
        return (
          <Button
            disabled={props.loading}
            className={props.classes.buildButton}
            variant="outlined"
            startIcon={<AutoAwesome />}
            onClick={props.convertIntoWorkflow}
          >
            Convert into a workflow
          </Button>
        );

      default:
        // If no components to build or edit
        // Don't show build button
        if (
          (!props.compForBuilding || props.compForBuilding.length <= 0) &&
          (!props.compForEditing || props.compForEditing.length <= 0)
        )
          return <></>;

        return (
          <Button
            disabled={props.loading}
            className={props.classes.buildButton}
            variant="outlined"
            startIcon={<Check />}
            onClick={props.buildAllComponents}
          >
            {props.buttonText.buildAllComponents.message
              ? props.buttonText.buildAllComponents.message
              : "Build all components now"}
          </Button>
        );
    }
  };

  const getChatButtons = () => {
    if (props.loading) return;

    // If it's at the pre-build stage and we want to show accept proposal
    if (props.isPrebuild && props.prebuildProposal) {
      return (
        <div>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            gap="10px"
            style={{ padding: "16px 0px 0px 0px" }}
          >
            <Grid item>
              {fetchCorrectButtonWhenProposalDisplayed(
                props.sidebarState,
                props.classes,
                props.loading,
                props.insert,
                props.handleBuildWithProposal,
                props.prebuildProposal
              )}
            </Grid>

            <Grid item>
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<Refresh />}
                onClick={props.regenerate}
              >
                Regenerate
              </Button>
            </Grid>

            {props.sidebarState === "WORKFLOW_EDITOR" ? (
              <Grid item>
                <Button
                  disabled={props.loading}
                  className={props.classes.newWorkflowButton}
                  variant="outlined"
                  startIcon={<RestartAlt />}
                  onClick={props.restart}
                >
                  {props.buttonText.restart.message
                    ? props.buttonText.restart.message
                    : "Restart"}
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  disabled={props.loading}
                  className={props.classes.newWorkflowButton}
                  variant="outlined"
                  startIcon={<RestartAlt />}
                  onClick={props.restart}
                >
                  {props.buttonText.restart.message
                    ? props.buttonText.restart.message
                    : "Restart"}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      );
    }
    // If it's at the prebuild stage and we are showing the textfield
    if (
      props.isPrebuild &&
      props.showTextfield &&
      props.messages &&
      props.messages.length > length
    ) {
      return (
        <div>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            gap="10px"
            style={{ padding: "16px 0px 0px 0px" }}
          >
            {props.showConclude && props.conclude && renderBuildButton()}
            {props.showConclude && props.conclude && (
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                onClick={props.conclude}
              >
                End Chat{" "}
              </Button>
            )}
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<Refresh />}
              onClick={props.regenerate}
            >
              Regenerate
            </Button>
            {!props.showConclude && (
              <Button
                disabled={props.loading}
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<SkipNext />}
                onClick={props.skipQuestions}
              >
                Skip questions
              </Button>
            )}
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<RestartAlt />}
              onClick={props.restart}
            >
              {props.buttonText.restart.message
                ? props.buttonText.restart.message
                : "Restart"}
            </Button>
          </Grid>
        </div>
      );
    }

    const notLoadingAndShowingComponentSidebarButtons =
      !props.loading && props.showComponentSidebarButtons;
    const notHidingButtonsAndShowingButtonGroup =
      !props.hideButtons && props.showButtonGroup;

    if (
      !notHidingButtonsAndShowingButtonGroup &&
      !notLoadingAndShowingComponentSidebarButtons
    )
      return "";

    return (
      <div>
        <Grid
          container
          display="flex"
          justifyContent="flex-end"
          gap="10px"
          style={{ padding: "16px 0px 0px 0px" }}
        >
          {props.hideInsert && (
            <Button
              disabled={props.loading}
              className={props.classes.buildButton}
              variant="contained"
              startIcon={<Check />}
              onClick={props.insert}
            >
              {props.buttonText.insert.message
                ? props.buttonText.insert.message
                : "Insert"}
            </Button>
          )}
          {props.showBuildButtons &&
            props.buildAllComponents &&
            renderBuildButton()}
          {props.showBuildButtons && props.buildComponentsIndividually && (
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<CheckCircleOutline />}
              onClick={props.buildComponentsIndividually}
            >
              {props.buttonText.buildComponentsIndividually.message
                ? props.buttonText.buildComponentsIndividually.message
                : "Build all components individually"}
            </Button>
          )}
          {props.showConclude && props.conclude && (
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<CheckCircleOutline />}
              onClick={props.conclude}
            >
              {props.buttonText.conclude.message
                ? props.buttonText.conclude.message
                : "Conclude the session"}
            </Button>
          )}
          {props.showConclude && (
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<Replay />}
              onClick={props.rebuild}
            >
              {props.buttonText.rebuild.message
                ? props.buttonText.rebuild.message
                : "Rebuild all components"}
            </Button>
          )}
          {!props.hideInsert && props.isCompBuilder && !props.cancelled && (
            <TestRunButton
              disabled={props.loading}
              startIcon={<PlayCircle />}
              variant="outlined"
              onClick={props.startCompTestRun}
            >
              Start test run
            </TestRunButton>
          )}
          {props.restart && (
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<RestartAlt />}
              onClick={props.restart}
            >
              {props.buttonText.restart.message
                ? props.buttonText.restart.message
                : "Restart"}
            </Button>
          )}
          {props.regenerate && !props.buildInserted && (
            <Button
              disabled={props.loading}
              className={props.classes.newWorkflowButton}
              variant="outlined"
              startIcon={<Refresh />}
              onClick={props.regenerate}
            >
              {props.buttonText.regenerate.message
                ? props.buttonText.regenerate.message
                : "Regenerate"}
            </Button>
          )}
        </Grid>
      </div>
    );
  };

  return getChatButtons();
}

export default ChatButtons;
