import React from "react";
import { Fade, Grid, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Settings, Output, Storage, VpnKey } from "@mui/icons-material";
import "./initialMessages.css"; // Import the CSS file
import ContextChips from "./ContextChips"; // Import the ContextChips component

const EditComponentMessage = ({
  classes,
  sidebarState,
  setSidebarState,
  messageSent,
}) => {
  const contextChips = [
    { 
      label: "All settings in this component",
      tooltip: "I will translate all current settings and content in the current component.",
      icon: <Settings fontSize="small" />
    },
    { 
      label: "Outputs from all connected components",
      tooltip: "I am aware of all placeholders from upstream components connected into this component.",
      icon: <Output fontSize="small" />
    },
    { 
      label: "Connected and used databases",
      tooltip: "I am aware of any databases actively being used by a component in this workflow, or connected but not actively used. I can choose to use these databases if appropriate.",
      icon: <Storage fontSize="small" />
    },
    { 
      label: "Secrets and credentials",
      tooltip: "I will be aware of any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
      icon: <VpnKey fontSize="small" />
    }
  ];

  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <span className={"bold"}>
                <img
                  src="/svgs/edit_square.svg"
                  className="autoAwesomeIcon"
                />
                Edit this Component
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} className="messagePadding">
            I am currently operating in edit mode. This means I will only make
            specific changes according to your instructions or requirements, and
            leave everything else in the component unchanged.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            Be specific about what you want to change and what you do not want
            me to change. By default, I will only apply changes to what you have
            instructed and leave everything else the same.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>ℹ️ Context</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
               I will have access to the following context when editing this component:
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <ContextChips chips={contextChips} />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

EditComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
};

export default withStyles(AIBuilderStyles())(EditComponentMessage);
