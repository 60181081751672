import React, { useState, useEffect } from "react";

// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, Slide } from "@mui/material";

import RenameSourceAndReferences from "./RenameSourceAndReferences";
import FixBrokenPlaceholders from "./FixBrokenPlaceholders";
import { DropdownLabel } from "./RenameStyledComponents";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RenamePlaceholdersDialog({ isOpen, closeDialog, projectId, openBrokenPHRename, setOpenBrokenPHRename, renameGivenPH, setRenameGivenPH }) {
    const [isInitialSelection, setIsInitialSelection] = useState(true);
    const [renameOption, setRenameOption] = useState("");

    useEffect(() => {
        if (openBrokenPHRename) {
            // Need to open broken PH replace if given
            setRenameOption("broken_references");
            setIsInitialSelection(false);
        } else if (renameGivenPH !== null) {
            // Need to open on selected PH
            setRenameOption("source_and_references");
            setIsInitialSelection(false);
        }

        return () => {
            // Clean up
            setIsInitialSelection(true);
            setRenameOption("");
            setRenameGivenPH(null);
            setOpenBrokenPHRename(false);
        };
    }, []);

    const getStartButtons = () => {
        return (
            <>
                <Button variant="outlined" onClick={closeDialog}>
                    Close
                </Button>
                <Button variant="contained" onClick={() => setIsInitialSelection(false)}>
                    Next
                </Button>
            </>
        );
    };

    const getSelectDropdown = () => {
        return (
            <>
                <DialogTitle>Rename placeholder</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <DropdownLabel>I want to</DropdownLabel>
                        <Select value={renameOption} onChange={(e) => setRenameOption(e.target.value)} size="small" fullWidth style={{ zIndex: 2000 }}>
                            <MenuItem disabled value="">
                                <em>Select replacement option</em>
                            </MenuItem>
                            <MenuItem value={"source_and_references"}>Rename the source and references to a placeholder</MenuItem>
                            <MenuItem value={"broken_references"}>Fix/rename broken placeholders</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>{getStartButtons()}</DialogActions>
            </>
        );
    };

    const getRelevantOption = () => {
        if (renameOption === "source_and_references") return <RenameSourceAndReferences projectId={projectId} closeDialog={closeDialog} renameGivenPH={renameGivenPH} />;
        else if (renameOption === "broken_references") return <FixBrokenPlaceholders projectId={projectId} closeDialog={closeDialog} />;
    };

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen ? isOpen : false} TransitionComponent={Transition} onClose={closeDialog} disableEnforceFocus={true}>
            {isInitialSelection ? getSelectDropdown() : getRelevantOption()}
        </Dialog>
    );
}

export default RenamePlaceholdersDialog;
