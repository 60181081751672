import React, {useContext, useEffect, useState, useCallback} from "react";

// React flow
import ReactFlow, {Background, ReactFlowProvider, Controls, ControlButton} from "reactflow";
import 'reactflow/dist/style.css';

// Util
import uuid from "uuid";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {getCanvasWithAINotesBorderSet, isAIEnabled} from "../../../utils/AIUtil";
import {createSaveEvent} from "../../../utils/createSaveEvent";
import {send_request, send_request_graphql_mutation} from "../../../utils/Request";

// Canvas
import "./Canvas.css";
import {CanvasContext} from "./WorkflowCanvas";
import {areSetsEqual, CANVAS_MIN_ZOOM_OUT, CanvasMinimap, DEFAULT_VIEWPORT, edgeTypes, extractEdges, extractNodes, getElements, isInvalidBranchPathsConnection, isSameLink, isValidConnection, nodeTypes, proOptions, } from "../../../utils/CanvasUtil";

// Custom components
import {CustomDialog} from "../../Component/Dialog";
import CanvasWithRFInstance from "./CanvasWithRFInstance";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import W86FloatingPane from "../../W86Components/W86FloatingPane";
import {SAVE_EVENT, COPY_SELECTION_SAVE_EVENT} from "./CanvasQueries";
import {ComponentFactory} from "../../ProjectCanvas/ComponentRegistry";

// MUI
import {withStyles} from "@mui/styles";
import {Close, Send} from "@mui/icons-material";
import {Button, Grid, Select, MenuItem, Autocomplete, CircularProgress, TextField, Tooltip, Fade} from "@mui/material";
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';

// MobX
import {inject, observer} from 'mobx-react';
import TemplateCanvas from "./CanvasVersions/TemplateCanvas";
import {PRODUCTION_CANVAS_VIEW_MODE, VERSION_CANVAS_VIEW_MODE, TEMPLATE_CANVAS_MODE, PROGRESS_CANVAS_MODE, LINK_TYPE, NOTE_TYPE, DEFAULT_CANVAS_MODE, PRODUCTION_CANVAS_MODE, COMPONENT_ICON_ALT, COMPONENT_NODE_ID} from "../../../utils/CanvasConstants";
import {progressNodeTypes, prodNodeTypes} from "./ReactFlowUtil/CustomNodes/ComponentNodeTypes";


const styles = {
  controls: {
    marginLeft: "16px",
  },
  minimap: {
    position: "absolute",
    float: "left",
    left: "5vw",
  },
  canvas: {
    position: "absolute!important",
  },
  aiIconBtn: {
    height: "18px",
    width: "auto",
    paddingRight: "8px"
  },
  gridCenterItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeSelectedButton: {
    height: "18px",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.65)"
    }
  },
  sendButton: {
    height: "100%"
  },
  sendIcon: {
    height: "20px",
    width: "auto"
  },
  selectItem: {
  }
};

const Canvas = inject('CanvasStore')(observer((props) => {
  const [reactFlowInstance, setReactFlowInstance] = useState({});
  const [showMapAZap, setShowMapAZap] = useState(false); // Shows the MapAZap dialog
  const [mapAZapPosition, setMapAZapPosition] = useState(null); // To store the MapAZaps position
  const [moreComponents, setMoreComponents] = useState(false);

  // this stat handles copy menu
  const [copyComponents, setCopyComponents] = useState(false);
  const [loadCopy, setLoadCopy] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState("");
  const [actionType, setActionType] = useState("copy_to_current_workflow");
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [showCopyResults, setCopyResults] = useState(false);
  const [copyDestination, setCopyDestination] = useState("");
  const [copyDestinationLink, setCopyDestinationLink] = useState("");

  const [disable, setDisable] = useState(false);

  // Showing Minimap
  const [showMinimap, setShowMinimap] = useState(false);

  //Check if Notes has been clicked
  const { isNoteClicked } = useContext(CanvasContext);
  const [noteClicked, setNoteClicked] = isNoteClicked? isNoteClicked : useState(false);

  //Handle Drag and Drop
  const onInit = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);

    if (props.mode === DEFAULT_CANVAS_MODE || props.mode === PRODUCTION_CANVAS_MODE || props.mode === PROGRESS_CANVAS_MODE) {
      props.setReactFlowInstance(_reactFlowInstance);
    } else {
      //needs to load elements array
      _reactFlowInstance.fitView();
      setTimeout(() => _reactFlowInstance.fitView(), 300);
    }

    if (props.viewport != null) {
      _reactFlowInstance.setViewport(props.viewport);
    }
  };

  //This will select the component when hovering mouse over node. Doing this so
  //it will trigger a shadow on the component node and open up the component options
  const handleOnNodeMouseEnter = (node, addSelectedNodes) => {
    const isNodeSelected = selectedNodes.some(selectedNode => selectedNode.id === node.id);
    const canSelectMoreNodes = selectedNodes.length <= 1;

    if (node && !isNodeSelected && canSelectMoreNodes && !noteClicked) {
      addSelectedNodes([node.id]);
    }
  };

  //For All Canvas except for Draft canvas
  //Opposite of handleOnNodeMouseEnter, will deselect component when mouse is hovered away
  const handleOnNodeMouseLeave = (node, resetSelectedElements, getNodes, setNodes) => {
    const isNodeSelected = node && selectedNodes.some((selectedNode) => selectedNode.id === node.id);

    if (isNodeSelected && !isSelecting) {
      deselectedNode(node, getNodes(), setNodes);
    }
  };

  const deselectedNode = (nodeToDeselect, allNodes, setNodeMethod) => {
    setNodeMethod(allNodes.map(singleNode => {
      return {...singleNode, selected:false}
    }))
  }

  /**
   *
   * @param selection Object that contains a list nodes and edges that has been selected
   * @param allowCopy True to enable copy feature, false to disable it
   */
  const handleSelectionChange = (selection, allowCopy) => {
    const newSelectedNodes = selection.nodes;
    const shouldOpenCopyMenu = (newSelectedNodes.length > 1 || (newSelectedNodes.length === 1 && isSelecting)) && allowCopy;
    const newSelectedComponents = [];
    const selectedNotesArr = [];

    // Single pass through selection.nodes to gather components and notes
    newSelectedNodes.forEach((item) => {
      if (item.type === "component" && !item.data.fromWorkflowPath) {
        newSelectedComponents.push(item.id);
      }
      if (item.type === NOTE_TYPE || (item.type === LINK_TYPE && item.label === undefined)) {
        selectedNotesArr.push(item.id);
      }
    });

    //If no nodes are selected nothing needs to be done here
    //Closing copy components since nothing is selected
    if (!(selection && newSelectedNodes)) {
      setCopyComponents(false);
      return;
    }

    if (areSetsEqual(newSelectedNodes, selectedNodes)) {
      return;
    }

    setSelectedNodes(newSelectedNodes);
    setCopyComponents(shouldOpenCopyMenu);

    if (selectedNodes.length === 0 || !allowCopy) {
      return;
    }

    if (!areSetsEqual(newSelectedComponents, selectedComponents)) {
      setSelectedComponents(newSelectedComponents);
    }

    if (!areSetsEqual(selectedNotesArr, selectedNotes)) {
      setSelectedNotes(selectedNotesArr);
    }
  };



  const navigate = useNavigate();

  if (props.mode === PROGRESS_CANVAS_MODE) {
    return (
      <ReactFlowProvider>
        <ReactFlow
          nodes={props.nodes}
          edges={props.edges}
          nodeTypes={progressNodeTypes}
          edgeTypes={edgeTypes}
          arrowHeadColor={"#868686"}
          defaultViewport={props.viewport ? props.viewport : DEFAULT_VIEWPORT}
          onInit={onInit}
          nodesDraggable={false}
          minZoom={CANVAS_MIN_ZOOM_OUT}
          proOptions={proOptions}
          deleteKeyCode={null}
          showMinimap = {showMinimap}
          setShowMinimap = {setShowMinimap}
          onPaneClick={props.handleCloseRerunModal}
        >
          <Controls className={props.classes.controls}>
            <Tooltip title={"Toggle Minimap"} placement={"right-end"}>
              <span>
                  <ControlButton
                      onClick={() => {
                          setShowMinimap(!showMinimap);
                      }}
                  >
                      <BrandingWatermarkIcon/>
                  </ControlButton>
              </span>
            </Tooltip>
          </Controls>
          {showMinimap && (
            <CanvasMinimap/>
            )}
          <Background variant="dots" gap={16} color="#868686" />
        </ReactFlow>
      </ReactFlowProvider>
    );
  } else if (props.mode === TEMPLATE_CANVAS_MODE || props.isTemplate) {

    const { CanvasStore } = props; // extract canvas store
    const { nodes, edges } = CanvasStore;
    
    return (
      <ReactFlowProvider>
        <TemplateCanvas
            onInit={onInit}
            nodes={props.nodes}
            edges={props.edges}
            mode={props.mode}
            showMinimap={showMinimap}
            setShowMinimap={setShowMinimap}
            switchShowMinimap={() => {
              setShowMinimap(!showMinimap);
            }}
            switchShowNotesProd={() => {
              props.setShowNotesProd(!props.showNotesProd);
            }}
            showNotesProd={props.showNotesProd}
            viewport={props.viewport}
            classes={props.classes}
            handleOnNodeMouseEnter={handleOnNodeMouseEnter}
            handleOnNodeMouseLeave={handleOnNodeMouseLeave}
            handleOnPaneClick={props.handleClosePane}
            handleSelectionChange={handleSelectionChange}
            handleRun={props.handleRun}
            handleOnNodeClick={(event, node) => {
              const componentNode = event.target.closest(`#${COMPONENT_NODE_ID}`);
              
              if (componentNode && node.type !== NOTE_TYPE) {
                props.handleOpenPane(event, node);
              }
            }}

        />
      </ReactFlowProvider>
    );
  } else if (
    props.mode === VERSION_CANVAS_VIEW_MODE ||
    props.mode === PRODUCTION_CANVAS_VIEW_MODE
  ) {
    return (
      <ReactFlowProvider>
        <ReactFlow
          nodes={props.nodes}
          edges={props.edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={prodNodeTypes}
          edgeTypes={edgeTypes}
          arrowHeadColor={"#868686"}
          defaultViewport={props.viewport ? props.viewport : DEFAULT_VIEWPORT}
          onInit={onInit}
          nodesDraggable={false}
          minZoom={CANVAS_MIN_ZOOM_OUT}
          proOptions={proOptions}
          deleteKeyCode={null}
          showMinimap = {showMinimap}
          setShowMinimap = {setShowMinimap}
        >
          <Controls className={props.classes.controls}>
              <Tooltip title={"Toggle Minimap"} placement={"right-end"}>
                <span>
                    <ControlButton
                        onClick={() => {
                            setShowMinimap(!showMinimap);
                        }}
                    >
                        <BrandingWatermarkIcon/>
                    </ControlButton>
                </span>
              </Tooltip>
          </Controls>
          {showMinimap && (
            <CanvasMinimap/>
          )}
          <Background variant="dots" gap={16} color="#868686" />
        </ReactFlow>
      </ReactFlowProvider>
    );
  } else {

    const { CanvasStore } = props; // extract canvas store
    const { nodes, edges } = CanvasStore;

    //using context to update canvas state
    const { edit } = useContext(CanvasContext);
    const [editComponent, setEditComponent] = edit;
    //record last modified value for the canvas
    const { updated, isCanvasLocked } = useContext(CanvasContext);
    const [lastModified, setLastModified] = updated ? updated : useState({});
    const [canvasLocked, setCanvasLocked] = isCanvasLocked ? isCanvasLocked : useState(false);

    //canvas mode
    const [mode, setMode] = useState(props.mode);

    useEffect(() => {
      setMode(props.mode);
    }, [props.mode]);

    //Handle links draw
    const onConnect = (params) => {
      //pause save is here to disable canvas events when component pane is open
      let source = nodes.find((node) => params.source == node.id);
      let target = nodes.find((node) => params.target == node.id);

      if (mode === DEFAULT_CANVAS_MODE) {

        // If it's an invalid branch path connection, return
        if (isInvalidBranchPathsConnection(source, target, props.setShowErrorSnackbar)) {
          return;
        }

        // We need to check if the target is a trigger component (i.e: it doesnt have an input)
        // If it is, just return as the user is trying to connect an input of another component
        // To the input of a trigger component
        if ((source.type !== NOTE_TYPE && target.type !== NOTE_TYPE) && target.data && !target.data.hasInput) return;

        // Check that a link doesn't exist already
        const index = edges.findIndex((e) => isSameLink(e, params));
        if (index !== -1) return; // If the link already exists, return

        //this a regular component link
        if (params.source != params.target) {
          if (
            source.data &&
            source.data.hasOutput == true &&
            source.type == "component" &&
            target.type == "component"
          ) {
            createComponentLink(params, source, target);
          } else if (source.type == NOTE_TYPE) {
            //this is a note link
            if (source.type == NOTE_TYPE && target.type == NOTE_TYPE) {
              createNoteLink(params, source, target);
            }
          }
        }
      } else if (mode === "SAVE_PAUSED") {
        //preventing links from connecting
        //show a banner
        props.setLinkWarning(true);
      }
    };

    // TODO: ======= UPDATED ======
    const createComponentLink = async (params, source, target) => {
      if (!props.saving) {
        props.setSaving(true);
        //style component link
        params.style = {
          strokeWidth: "3px",
          stroke: source.data.fromWorkflowPath?"#868686":source.data.baseColor,
        };

        params.type = LINK_TYPE;

        params.markerEnd = {
          type: "arrowclosed",
          color: "#868686",
        };
        params.id = uuid();
        params.label = "componentLink";

        //add it to frontend
        CanvasStore.setEdges([...edges, params]);

        //record viewport
        let rfiObj = props.reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let eventData = {
          sourceComponentId: source.id,
          targetComponentId: target.id,
          targetType: target.data.type,
        };

        let saveEvent = createSaveEvent(
          "ADD_LINK",
          props.workflowId,
          viewport,
          getElements(props.reactFlowInstance),
          eventData
        );

        //send event
        return await send_request_graphql_mutation(
          `project-service/graphql/project/save/${props.workflowId}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            props.setSaving(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const createNoteLink = async (params, source, target) => {
      if (props.saving) return;

      props.setSaving(true);

      //this is a note, notes can only connect to notes
      params.style = {
        strokeWidth: "3px",
        stroke: "rgba(0,0,0,0.3)",
      };

      params.type = LINK_TYPE;
      params.markerEnd = {
        type: "arrowclosed",
        color: "#868686",
      };
      params.id = uuid();

      CanvasStore.setEdges([...edges, params]);

      let rfiObj = props.reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = {
        arrowHeadType: "arrowclosed",
        id: params.id,
        source: source.id,
        sourceHandle: params.sourceHandle,
        style: {
          strokeWidth: "3px",
          stroke: "rgba(0,0,0,0.3)",
        },
        target: target.id,
        targetHandle: params.targetHandle,
        type: LINK_TYPE,
      };

      let saveEvent = createSaveEvent(
        "ADD_NOTE_LINK",
        props.workflowId,
        viewport,
        getElements(props.reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${props.workflowId}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          props.setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    };

    const onDragOver = (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };

    const onDrop = (event) => {
      // moving here to stop drop behaviour in firefox for
      // prod and draft view. For template and ProgressView
      // drag and drop is disabled
      event.preventDefault();

      if (mode === DEFAULT_CANVAS_MODE) {
        if (!props.pauseState) {
          //pause save is here to disable canvas events when component pane is open

          //find out current position
          const position = reactFlowInstance.screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          });

          // If it's a note
          if (
            event.dataTransfer.getData("application/reactflow") === NOTE_TYPE
          ) {
            // If it's a mapAZap, open dialog, else create note
            if (event.dataTransfer.getData("type") === "map_a_zap") {
              setMapAZapPosition(position); // Set the position
              setShowMapAZap(true); // Show the dialog
            } else {
              //this is a note, send create note event
              addNewNote(event, position); //
            }
          } else {
            //this is adding a new component to the canvas
            addNewComponent(event, position);
          }
        }
      }
    };

    const addNewComponent = async (event, position) => {
      if (props.saving) return; // If already saving, return

      props.setSaving(true);

      //check for the amount of components on the canvas already
      if (getNodesComponentCount() < 250) {
        //use data from sidebar to determine type of the node
        const type = event.dataTransfer.getData("type");
        //create a node using template
        const template = ComponentFactory[type];
        //this a new node for the frontend
        const newNode = {
          id: uuid(),
          type: "component",
          position,
          data: {
            type: type,
            label: template.displayName,
            displayName: template.displayName,
            logo: template.logo,
            hasInput: template.hasInput ? template.hasInput : false,
            hasOutput: template.hasOutput ? template.hasOutput : false,
            baseColor: template.baseColor,
          },
        };

        //add it to the nodes
        CanvasStore.setNodes([...CanvasStore.nodes, newNode]);

        let rfiObj = props.reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        //create event for the new component
        let eventData = {
          componentId: newNode.id,
          type: newNode.data.type,
          viewData: {
            x: position.x,
            y: position.y,
          },
        };

        let saveEvent = createSaveEvent(
          "ADD_COMPONENT",
          props.workflowId,
          viewport,
          getElements(props.reactFlowInstance),
          eventData
        );
        //send event
        return await send_request_graphql_mutation(
          `project-service/graphql/project/save/${props.workflowId}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            props.setSaving(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else setMoreComponents(true); //there are too many components on the canvas, show dialog
    };

    const addNewNote = async (event, position) => {
      if (props.saving) return; // If already saving, return

      props.setSaving(true);

      //grab data
      const noteData = {
        width: event.dataTransfer.getData("width"),
        height: event.dataTransfer.getData("height"),
        color: event.dataTransfer.getData("color"),
        content: event.dataTransfer.getData("content"),
      };

      //create note
      const newNote = {
        id: uuid(),
        type: NOTE_TYPE,
        position,
        data: {
          content: noteData.content,
          color: noteData.color,
          width: parseInt(noteData.width) ? parseInt(noteData.width) : 400,
          height: parseInt(noteData.height) ? parseInt(noteData.height) : 400,
        },
      };

      //add it to the nodes
      CanvasStore.setNodes([...nodes, newNote]);

      let rfiObj = props.reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = {
        note: newNote,
      };
      //create event for the new note
      let saveEvent = createSaveEvent(
        "ADD_NOTE",
        props.workflowId,
        viewport,
        getElements(props.reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${props.workflowId}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          if (!props.showNotes) {
            props.setShowNotes(true);
          }
          //insert delay here
          props.setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getNodesComponentCount = () => {
      return nodes.filter((c) => c.type === "component").length;
    };

    const handleNavigateIntoComponent = (event, node) => {

      if (node && node.data && node.data.isTemp === true) {
        return;
      }

      if (node && node.type === NOTE_TYPE && node.data.content === undefined) {
        //"not saving anything as content is not loaded";
        return;
      }

      if (mode === "SAVE_PAUSED" && editComponent != null && editComponent.componentId !== node.id) {
        //preventing links from connecting
        //show a banner
        props.setNavigationWarning(true);
        return;
      }

      new Promise((fulfill, reject) => {
        CanvasStore.setNodes(reactFlowInstance.getNodes());
        fulfill();
      }).then(async () => {
        if (node.type == "component" && !editComponent) {
          if(node.data.fromWorkflowPath)
          {
            let parent = getElements(reactFlowInstance) //find latest position of note
                .find((x) => x.id === node.data.parentId);
            node.data.componentId = parent.id;
            setEditComponent(parent.data);
          } else {
            //this just adds componentId that we need for the pane to work
            node.data.componentId = node.id;
            setEditComponent(node.data);
          }
        }
      });
    };

    const handleSelectionStart = (selection) => {
      setIsSelecting(true);
      setCopyResults(false);

    };

    const handleSelectionEnd = (selection) => {
      setIsSelecting(false);
    };

    const handleOnNodeClick = (event, node) => {
      if (!node) return;
      if (editComponent) {
        if (editComponent.componentId !== node.componentId){
          props.handleClosePaneButtonClick();
          //Set this to true because there seems to be a delayed for when this gets set to true
          //ProjectStore.savedComponent is used to check if a save is in progress
          ProjectStore.savedComponent = true;
        }
        return;
      }
      if (!ProjectStore?.savedComponent){
        handleNavigateIntoComponent(event, node);
      }
    }

    const handleBuildAIFromSelectedNotes = async () => {
      if (!selectedNotes || selectedNotes.length <= 0) {
        setLoadCopy(false);
        return;
      }

      closeCopy(); // Close the menu
      setCanvasLocked("Collecting notes for AI"); // Set to locked

      // Set border around notes sent to AI
      const updatedNodes = getCanvasWithAINotesBorderSet(nodes, new Set(selectedNotes));
      CanvasStore.setNodes(updatedNodes);

      const dto = {
        projectId: ProjectStore.project_id,
        noteIds: selectedNotes
      };

      // Send request to create ai session for the notes
      const url = `project-service/project-ai/assistant/create_ai_session_for_notes`;
      const json = await send_request(url, dto, null, "POST");

      if (json && json.data) {
        const { projectId, chatSessionId, threadId } = json.data;
        navigate(`/project/canvas/${projectId}?chatSession=${chatSessionId}&threadId=${threadId}&startBuild=true`);
      }

      setCanvasLocked(false);
    }


    //For Draft Canvas Only
    const handleOnNodeMouseLeaveDraft = (node,resetSelectedElements, getNodes, setNodes) => {
      // Exit early if the node is undefined/null, not part of the selected nodes, or if the user is currently selecting.
      if (!node || !selectedNodes.some((selectedNode) => selectedNode.id === node.id) || isSelecting) {
        return;
      }

      // Determine if the node is of type NOTE_TYPE.
      const isNote = node.type === NOTE_TYPE;

      // Reset the selected elements if the node is not a note or if it is a note but hasn't been clicked.
      if (!isNote || (isNote && !noteClicked)) {
        resetSelectedElements();
      }
    };

    //these methods handle selection actions
    const requestCopy = async () => {
      if (props.saving) return; // If saving, return
      setLoadCopy(true);

      // This is a notes -> ai call
      if (actionType === "build_from_selected_notes") {
        await handleBuildAIFromSelectedNotes();
        return;
      }

      let copyToId = "";

      if (actionType == "copy_to_current_workflow")
        copyToId = props.workflowId;
      else {
        copyToId = workflow.projectId;
      }

      const selectedSet = [...new Set(selectedComponents)]; // Remove any duplicates

      if (selectedSet.length > 0 || selectedNotes.length > 0) {
        let rfiObj = props.reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let eventData = {
          projectId: copyToId,
          components: selectedSet,
          notes: selectedNotes,
        };

        //create event for the new note
        let saveEvent = createSaveEvent(
          "COPY_SELECTION",
          props.workflowId,
          viewport,
          getElements(props.reactFlowInstance),
          eventData
        );

        //send event
        await send_request_graphql_mutation(
          `project-service/graphql/project/save/${props.workflowId}`,
          COPY_SELECTION_SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (
              actionType == "copy_to_current_workflow" ||
              workflow == props.workflowId
            ) {
              let components = response
                ? response.data.saveEvent.copiedSelection
                : null;
              let notes = response
                ? response.data.saveEvent.copiedNotes
                : null;

              if (components) {
                let newDiagram = ProjectStore.readDiagram(components);

                //add it to the nodes/edges
                CanvasStore.addNodes(extractNodes(newDiagram));
                CanvasStore.addEdges(extractEdges(newDiagram));
              }

              if (notes) {
                notes.map((note) => {
                  if (note.type == NOTE_TYPE) {
                    delete note.style;
                    delete note.source;
                    delete note.sourceHandle;
                    delete note.target;
                    delete note.targetHandle;
                    delete note.arrowHeadType;
                  } else if (note.type == LINK_TYPE) {
                    if(note.data?.label){
                      let newData = note.data.label;
                       note.data = newData
                    }else
                      delete note.data;
                    delete note.position;
                  }
                });
                //add it to the nodes/edges
                CanvasStore.addNodes(extractNodes(notes));
                CanvasStore.addEdges(extractEdges(notes));
              }
            }
            //insert delay here
            props.setSaving(false);

            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            if (actionType == 'copy_to_another_workflow'){
              setCopyDestinationLink(workflow.projectId);
              setCopyDestination(workflow.projectName);
              setCopyResults(true);
            }

          })
          .catch((error) => {
            closeCopy();
            console.log(error);
          });

        //handle updating canvas if we create a new component
      }

      closeCopy();
    };

    const handleSelectWorkflowType = async (action) => {
      setActionType(action);
      setDisable(true);

      //load workflows here to select where to copy
      if (action == "copy_to_another_workflow" && workflows.length == 0) {
        await send_request(
          `project-service/project/get_workflow_names/${props.projectId}`
        )
          .then((res) => {
            if (res && res.status == 200) {
              setWorkflows(res.data);
            }
          })
          .catch((e) => {
            console.log(e);
            closeCopy();
          });
      } else setLoadCopy(false);
    };

    const handleSelectWorkflow = async (choice) => {
      if (
        choice == "select_workflow" &&
        actionType == "copy_to_another_workflow"
      )
        setLoadCopy(true);
      else setLoadCopy(false);

      setWorkflow(choice);

      setDisable(false);
    };

    const closeCopy = () => {
      setWorkflow("select_workflow");
      setActionType("copy_to_current_workflow");
      setCopyComponents(false);
      setWorkflows([]);
      setSelectedComponents([]);
      setSelectedNotes([]);
      setLoadCopy(false);
    };

    const handleSelectionContextMenu = (event) => {
      event.preventDefault();

      if (selectedComponents.length > 0 || selectedNotes.length > 0) {
        setCopyComponents(true); //open copy components dialog
      }
    };

    const connectionStatus = useCallback(
      (connection) => {
        return isValidConnection(connection, nodes, edges);
      },
      [nodes, edges],
    );

    return (
      <>
        <ReactFlowProvider>
          <CanvasWithRFInstance
              handleOnNodeClick={(event, node) => {
                const componentNode = event.target.closest(`#${COMPONENT_NODE_ID}`);

                if (componentNode && node.type !== NOTE_TYPE) {
                  handleOnNodeClick(event, node);
                }
              }}
            handleNavigateIntoComponent={handleNavigateIntoComponent}
            onConnect={onConnect}
            onDrop={onDrop}
            onInit={onInit}
            handleSelectionChange={handleSelectionChange}
            handleSelectionContextMenu={handleSelectionContextMenu}
            handleSelectionStart={handleSelectionStart}
            handleSelectionEnd={handleSelectionEnd}
            onDragOver={onDragOver}
            canvasLocked={canvasLocked}
            viewport={props.viewport}
            classes={props.classes}
            showNotes={props.showNotes}
            setShowNotes={props.setShowNotes}
            showMapAZap={showMapAZap}
            setShowMapAZap={setShowMapAZap}
            mapAZapPosition={mapAZapPosition}
            isValidConnection={connectionStatus}
            showMinimap = {showMinimap}
            setShowMinimap = {setShowMinimap}
            handleOnNodeMouseEnter={handleOnNodeMouseEnter}
            handleOnNodeMouseLeave={handleOnNodeMouseLeaveDraft}
            handleOnPaneClick={props.handleClosePaneButtonClick}

          />
          {copyComponents && (
            <W86FloatingPane
              customStyles={{
                position: "absolute",
                bottom: "48px",
                left: "50%",
                width: "500px",
                transform: "translateX(-50%) !important"
              }}
              content={(
                <Fade timeout={1000} in={true}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={1} className={props.classes.gridCenterItem} onClick={() => setCopyComponents(false)}>
                          <Close className={props.classes.closeSelectedButton} />
                        </Grid>
                        <Grid item xs={9} className={props.classes.gridCenterItem} paddingLeft="8px" paddingRight="8px">

                          <Select
                            fullWidth
                            size="small"
                            value={actionType}
                            disableUnderline={true}
                            variant={"filled"}
                            className={props.classes.selectItem}
                            onChange={(event) =>
                              handleSelectWorkflowType(event.target.value)
                            }
                          >
                            {isAIEnabled(ProjectStore.state.clientId) && selectedNotes && selectedNotes.length > 0 && <MenuItem value={"build_from_selected_notes"}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <img src="/svgs/ai-stars.svg" className={props.classes.aiIconBtn} /> Build a workflow from selected notes
                              </div>
                            </MenuItem>}
                            <MenuItem value={"copy_to_current_workflow"}>
                              Copy and paste into the current workflow
                            </MenuItem>
                            <MenuItem value={"copy_to_another_workflow"}>
                              Copy and paste into another workflow
                            </MenuItem>
                          </Select>

                        </Grid>
                        <Grid item xs={2} className={props.classes.gridCenterItem}>
                          <Button
                            className={props.classes.sendButton}
                            variant="contained" size="small" disabled={canvasLocked || loadCopy || !actionType || (actionType == "copy_to_another_workflow" && !workflow)} onClick={requestCopy}>
                              {loadCopy && (
                                <CircularProgress size={24} className="buttonProgress" />
                              )}
                            <Send className={props.classes.sendIcon} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {actionType == "copy_to_another_workflow" && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={9} paddingLeft="8px" paddingRight="8px">
                            <Autocomplete
                              freeSolo
                              size="small"
                              options={workflows}
                              loading={workflows.length > 0 ? false : true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  variant="outlined"
                                  placeholder="Select workflow to paste into"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.projectId}>
                                  {option.projectName
                                    ? option.projectName
                                    : option.projectId}
                                </li>
                              )}
                              getOptionLabel={(option) =>
                                option.projectName
                                  ? option.projectName
                                  : option.projectId
                                  ? option.projectId
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleSelectWorkflow(newValue);
                                }
                              }}
                              value={""}
                            />
                          </Grid>
                          <Grid item xs={2}></Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Fade>
              )}
            />
          )}

          {showCopyResults && (
            <W86FloatingPane
              customStyles={{
                position: "absolute",
                bottom: "48px",
                left: "50%",
                width: "278px",
                transform: "translateX(-50%) !important"
              }}
              content={(
                <Fade timeout={1000} in={true}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={11} className={props.classes.gridCenterItem} paddingLeft="8px" paddingRight="8px">
                          <p style={{fontFamily: 'Open Sans', fontSize: '14px', margin: 0}}>
														Copied to <Link
																				to={`/project/canvas/${copyDestinationLink}`}
																				reloadDocument={true}
																				style={{ color: 'rgba(33, 150, 243, 1)' }}
																			>
																				{copyDestination}
																			</Link>
													</p>
                        </Grid>
                        <Grid item xs={1} className={props.classes.gridCenterItem} onClick={() => setCopyResults(false)}>
                          <Close className={props.classes.closeSelectedButton} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fade>
              )}
            />
                            )}
        </ReactFlowProvider>

        {moreComponents && <CustomDialog
          isOpen={moreComponents}
          title={
            "You have reached the maximum of 250 components for this canvas."
          }
          buttons={
            <Button
              onClick={() => {
                setMoreComponents(false);
              }}
              variant={"contained"}
              color={"primary"}
            >
              Close
            </Button>
          }
        />}
      </>
    );
  }
}));

export default withStyles(styles)(Canvas);