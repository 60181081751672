import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  InputAdornment
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import Button from "@mui/material/Button";
import "./MarketplacePage.css";

class LinkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>
          <h5>Share this template</h5>
        </DialogTitle>
        <DialogContent>
          <p className={"s-text"}>
            Share this template using the link below. Users may be required to
            log in before they can view and dowload this template if it is a
            private template.{" "}
          </p>
          <TextField
            size={"small"}
            fullWidth
            variant={"outlined"}
            value={`app.workflow86.com/template/${this.props.id}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard
                    text={`app.workflow86.com/template/${this.props.id}`}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <FileCopyIcon className="custom-icon-btn" />
                  </CopyToClipboard>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"info"}
            onClick={() => {
              window.open(`/template/${this.props.id}`);
            }}
            endIcon={<LaunchOutlinedIcon />}
          >
            open
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              this.props.handleClose();
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default LinkDialog;
