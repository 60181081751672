import React, {useMemo, useRef} from 'react';
import {Handle, Position} from 'reactflow';
import {Button, Tooltip} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DEFAULT_CANVAS_MODE, PRODUCTION_CANVAS_MODE, PRODUCTION_CANVAS_VIEW_MODE, SAVE_PAUSE_MODE} from '../../../../../../../utils/CanvasConstants';
import AddIcon from "@mui/icons-material/Add";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import '../ComponentNode.css';
import * as PropTypes from "prop-types";
import {ComponentNodeBodyView} from "./ComponentNodeBodyView";

const StyledHandle = styled(Handle)(() => ({
  backgroundColor: "#000000",
}));

const StyledWorkflowPathButton = styled(Button)(({ color }) => ({
  backgroundColor: color.bg,
  border: `1px solid ${color.border}`,
}));

const ComponentNodeView = ({
  classes, 
  data, 
  selected, 
  isSelectedComponent,
  isSelectedForceStart = false,
  mode,
  saving, 
  addLinkedConditionalPath,
  openTestPanel,
  forceStartComponent,
  editComponent,
}) => {
  const hasInput = useMemo(() => data.hasInput, [data.hasInput]);
  const hasOutput = useMemo(() => data.hasOutput, [data.hasOutput]);
  const isDraftCanvas = mode === DEFAULT_CANVAS_MODE;
  const isProdCanvas = mode === PRODUCTION_CANVAS_MODE || mode === PRODUCTION_CANVAS_VIEW_MODE;
  const isSavePauseMode = mode === SAVE_PAUSE_MODE;
  const ref = useRef(null);



  const svgSelectedClass = useMemo(() => {
    const isTrue = selected || isSelectedComponent || isSelectedForceStart;
    return isTrue ? classes.selectedIcon : "";
  }, [selected, isSelectedComponent, isSelectedForceStart, classes.selectedIcon]);

  const getOutput = (data) => {
    switch (data.type) {
      case "branch_paths":
        return (
          <>
            <StyledHandle
              className={classes.port}
              position="right"
              type="source"
              isConnectable={!data.isTemp}
            />
            {isDraftCanvas && (
              <Tooltip
                title={
                  <span className={classes.addPathTooltipTitle}>
                    Add a Conditional Path
                  </span>
                }
                placement="bottom"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 8],
                        },
                      },
                    ],
                  },
                }}
              >
                <Button
                  variant="outlined"
                  disabled={saving}
                  className={classes.addPathButton}
                  onClick={(event) => {
                    addLinkedConditionalPath(data)
                  }}
                  id="add-conditional-path-button"
                >
                  <AddIcon className={classes.addPathIcon} />
                  <AltRouteIcon className={classes.addPathIcon} />
                </Button>
              </Tooltip>
            )}
          </>
        );

      case "conditional_workflow_path":
        return (
          <StyledHandle
            className={classes.port}
            position="right"
            type="source"
            isConnectable={false}
          />
        );

      default:
        return (
          <Handle
            className={classes.port}
            position={Position.Right}
            type="source"
            isConnectable={!data.isTemp}
          />
        );
    }
  };

  const nodeDisplayNameClass = `bold ${
      ((editComponent && data.componentId === editComponent.componentId) ||
          (openTestPanel && data.componentId === openTestPanel)) &&
      (isDraftCanvas || isSavePauseMode) &&
      classes.selectedText
  } ${
      (selected ||
          (forceStartComponent &&
              !selected &&
              data.componentId === forceStartComponent.componentId)) &&
      isProdCanvas &&
      classes.selectedText
  }`;

  return (
  <>
    <div className={classes.nodeView} id="node-view">
      {hasInput ? (
        data.fromWorkflowPath ? (
          <StyledHandle
            className={classes.port}
            type="target"
            position="left"
            isConnectable={false}
          />
        ) : (
          <Handle
            className={classes.port}
            type="target"
            position={Position.Left}
            isConnectable={!data.isTemp}
          />
        )
      ) : (
        <div className={classes.filler} />
      )}
      {data.fromWorkflowPath ? (
        <StyledWorkflowPathButton
          size="small"
          variant="outlined"
          className="draftVersionButton"
          color={data.color}
        >
          <span style={{ paddingLeft: "5px" }}>{data.label}</span>
        </StyledWorkflowPathButton>
      ) : (
        <ComponentNodeBodyView
          baseColor={data.baseColor}
          selectedClass={svgSelectedClass}
          data={data}
          classes={classes}
        />
      )}
      {hasOutput ? (
        getOutput(data)
      ) : (
        <div className={classes.filler} />
      )}
      <Handle
        type={"target"}
        position={Position.Top}
        isConnectable={true}
        style={{ display: "none"}}
      />
      <Handle
        type={"source"}
        position={Position.Top}
        isConnectable={true}
        style={{ display: "none"}}
      />
    </div>
  </>
  );
};

export default ComponentNodeView;