import React, { useState, useEffect } from "react";
//mui
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  CircularProgress,
  Autocomplete
} from "@mui/material";
//dialogs
import { CustomDialog } from "../Component/Dialog";
//grapgl
import { DASHBOARD, WIDGET } from "./DashboardQueries";
//utils
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { runLoading, showLoadingScreen } from "../../utils/showLoadingScreen";
import { send_request, send_request_graphql } from "../../utils/Request";
// react-router
import { useNavigate, useParams } from "react-router";
//import widgets
import StackedBarChart from "./charts/StackedBarChart";
import DoughnutChart from "./charts/DoughnutChart";
//css
import DashboardStyles from "./DashboardStyles";
import { withStyles } from "@mui/styles";
//websocket
import { over } from "stompjs";
import SockJS from "sockjs-client";
import config from "../../config";
import { Auth } from "aws-amplify";
const styles = DashboardStyles;
var stompClient = null;

const Dashboard = (props) => {
  //style
  const classes = props.classes;
  //state
  const [createWidget, setCreateWidget] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [dashboardName, setDashboardName] = useState("Untitled");
  const [loadButton, setLoadButton] = useState(false);
  const [loadAddButton, setLoadAddButton] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [loading, setLoading] = useState(true);
  //state for new widget
  const [widgetName, setWidgetName] = useState("");
  const [widgetType, setWidgetType] = useState("SESSION_PROGRESS");
  const [widgetWorkflow, setWidgetWorkflow] = useState({});
  // const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [numSessions, setNumSessions] = useState(5);
  //get url params and navigation with react-router
  const params = useParams();
  const navigate = useNavigate();
  const dashboardId = params.id;
  //websocket

  //on load
  useEffect(async () => {
    //init websocket

    //get dashboard info and load widgets
    await send_request_graphql(
      `dashboard/dashboard/${dashboardId}`,
      DASHBOARD(dashboardId)
    )
      .then(async (response) => {
        //get workflow names for constructing new widgets
        await send_request(`project-service/project/getProjectsNames`, "", {})
          .then((response) => {
            setWorkflows(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
        //connect to a websocket
        await setTimeout(connect(response), 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const connect = async (response) => {
    let Sock = new SockJS(config.DASHBOARD_SERVICE_WEBSOCKET);
    stompClient = over(Sock);
    stompClient.reconnect_delay = 5000;

    let header = {};
    try {
      header["Authorization"] = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();

      stompClient.connect(
          header,
          function () {
            if (response.data.dashboard) {
              setWidgetFromDb(response.data.dashboard);
              setLoading(false);
            }
          },
          onError
      );
      return response;
    } catch (err) {
      if (err === "No current user" || (err.code && err.code === "NotAuthorizedException"))//In case no user found exception is thrown
      {
        Auth.signOut().then(r => console.log("graceful sign out on UI"));
      }
    }

  };
  useEffect(() => {
    // componentWillUnmount
    return () => {
      // Anything in here is fired on component unmount.
      if (stompClient && stompClient.connected) stompClient.disconnect();
    };
  }, []);
  const setWidgetFromDb = (dashboard) => {
    let savedDashboard = dashboard;

    if (savedDashboard.name) setDashboardName(savedDashboard.name);

    setWidgets(savedDashboard.widgetsInfo);
  };

  //log websocket errors
  const onError = (err) => {
    console.log(err);
  };

  //update widgets
  const handleReloadWidget = (widgetId) => {};
  //delete
  const handleDeleteWidget = async (widgetId) => {
    await send_request(
      `dashboard/widget/delete/${dashboardId}/${widgetId}`,
      "",
      "",
      "POST"
    )
      .then(() => {
        let result = widgets.filter((item) => {
          return item.id != widgetId;
        });
        setWidgets(result);
      })
      .catch((e) => {
        console.log(e); 
      });
  };
  //load widgets
  const determineWidgetByType = (widget, key) => {
    let workflow = workflows.find((item) => {
      return item.projectId == widget.widgetData.projectId;
    });
    let projectName = workflow.projectName
      ? workflow.projectName
      : workflow.projectId;
    switch (widget.type) {
      case "DOUGHNUT":
        return (
          <Grid
            key={key}
            container
            item
            xs={3}
            className={classes.doughnut}
            direction={"column"}
          >
            <Card>
              <CardContent className={classes.graph}>
                <Grid container item direction={"row"} alignItems={"center"}>
                  <p>{widget.name}</p>
                  <i
                    className={`material-icons ${classes.icons}`}
                    onClick={() => {
                      handleReloadWidget(widget.id);
                    }}
                  >
                    replay
                  </i>
                  <i
                    className={`material-icons ${classes.icons}`}
                    onClick={() => {
                      handleDeleteWidget(widget.id);
                    }}
                  >
                    delete
                  </i>
                </Grid>{" "}
                <DoughnutChart type={widget.type} data={widget.widgetData} />
              </CardContent>
            </Card>
          </Grid>
        );

      case "SESSION_PROGRESS":
        return (
          <Grid
            className={classes.stackedBar}
            key={widget.id}
            container
            item
            xs={12}
            direction={"column"}
          >
            <Card>
              <CardContent className={classes.graph}>
                <StackedBarChart
                  projectName={projectName}
                  stompClient={stompClient}
                  widget={widget}
                  handleDeleteWidget={(widgetId) => {
                    handleDeleteWidget(widgetId);
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        );
      default:
        return (
          <Grid key={key} container item xs={3}>
            <Card>{runLoading()}</Card>;
          </Grid>
        );
    }
  };

  //create new widget
  const handleCreateWidget = async () => {
    setLoadButton(true);
    setCreateWidget(false);

    let newWidgetData = {
      projectId: widgetWorkflow.projectId,
      sessionNumber: numSessions
    };

    let newWidget = {
      diagramType: "STACKED_BAR",
      name: widgetName,
      widgetData: newWidgetData,
      type: widgetType
    };

    await send_request(
      `dashboard/widget/create/${dashboardId}`,
      newWidget,
      "",
      "POST"
    )
      .then(async (res) => {
        let widgetId = res.data;

        if (widgets.indexOf(widgetId) == -1) {
          await send_request_graphql(
            `dashboard/widget/${widgetId}`,
            WIDGET(widgetId)
          )
            .then((response) => {
              if (response.data.widget) {
                let result = widgets;
                result.push(response.data.widget);
                setWidgets(result);
              }
            })
            .then(() => {
              handleCancelCreateWidget();
              setLoadButton(false);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelCreateWidget = () => {
    setWidgetName("");
    setWidgetType("SESSION_PROGRESS");
    setWidgetWorkflow({});
    setLoadButton(false);
    setCreateWidget(false);
  };

  if (!loading)
    return (
      <Grid container item xs={12} direction={"column"}>
        {/* Header */}
        <Grid
          className={classes.button}
          container
          item
          xs={5}
          direction={"row"}
          alignItems={"center"}
        >
          <i
            onClick={() => {
              navigate("/dashboards");
            }}
            className="arrowBack material-icons"
          >
            arrow_back
          </i>

          <h3>{dashboardName}</h3>
        </Grid>
        <Grid container item xs={2}>
          <Button
            variant={"contained"}
            color={"info"}
            disabled={loadAddButton}
            className={classes.button}
            onClick={() => {
              setLoadAddButton(true);
              if (ProjectStore.state.tier != "FREE") {
                setCreateWidget(true);
              } else if (widgets.length == 1) {
                //stop from creating here
                setUpgrade(true);
              } else if (widgets.length == 0) {
                setCreateWidget(true);
              }
              setLoadAddButton(false);
            }}
          >
            {loadAddButton && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
            <i className={`material-icons ${classes.icon}`}>
              add_circle_outline
            </i>{" "}
            Add Widget
          </Button>
        </Grid>
        {/* Widgets */}
        <Grid xs={12} container item direction={"row"}>
          {widgets.map((widget, key) => {
            return determineWidgetByType(widget, key);
          })}
        </Grid>

        {/* Create a new widget dialog */}
        <CustomDialog
          isOpen={createWidget}
          size={"sm"}
          title={"Add dashboard widget"}
          contents={
            <Grid direction={"column"} container item xs={12}>
              <p>Title</p>
              <TextField
                fullWidth={true}
                size={"small"}
                placeholder={"Type title for dashboard widget here"}
                value={widgetName}
                onChange={(event) => {
                  setWidgetName(event.target.value);
                }}
              />
              <p>Select widget</p>
              <Select
                value={widgetType}
                variant={"filled"}
                onChange={(event) => {
                  setWidgetType(event.target.value);
                }}
              >
                <MenuItem value={"SESSION_PROGRESS"}>
                  Stacked bar chart of workflow progress
                </MenuItem>
                {/* <MenuItem value={"DOUGHNUT"}>
                Doughnut chart of workflow status
              </MenuItem> */}
              </Select>
              <p>Select workflow to track</p>
              <Autocomplete
                renderInput={(params) => {
                  return <TextField {...params} variant="outlined" />;
                }}
                value={widgetWorkflow ? widgetWorkflow : "Select workflow"}
                options={workflows}
                size={"small"}
                getOptionLabel={(option) => {
                  if (option.projectName) return option.projectName;
                  else if (option.projectId) return option.projectId;
                  else return "Select workflow";
                }}
                onChange={(event, inputValue) => {
                  setWidgetWorkflow(inputValue);
                }}
              />
              {/* {selectedWorkflow && (
              <>
                <p>Number of sessions to display</p>
                <Select
                  value={numSessions}
                  variant={"filled"}
                  onChange={(event) => {
                    setNumSessions(event.target.value);
                  }}
                >
                  <MenuItem value={5}>Last 5 sessions</MenuItem>
                  <MenuItem value={10}>Last 10 sessions</MenuItem>
                  <MenuItem value={25}>Last 25 sessions</MenuItem>
                </Select>
              </>
            )} */}
            </Grid>
          }
          buttons={
            <>
              <Button
                variant={"outlined"}
                color={"info"}
                disabled={loadButton}
                onClick={() => {
                  handleCancelCreateWidget();
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                disabled={loadButton}
                onClick={() => {
                  //disable buttons
                  handleCreateWidget();
                }}
              >
                Done
                {loadButton && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
            </>
          }
        />
        <CustomDialog
          isOpen={upgrade}
          title={"Upgrade to add more dashboard widgets"}
          contents={
            <p className={"s-text"}>
              You are currently on the free tier plan which limits you to one
              dashboard widget. Upgrade to a paid plan to add unlimited
              dashboard widgets.{" "}
            </p>
          }
          buttons={
            <>
              <Button
                variant={"outlined"}
                onClick={() => {
                  setUpgrade(false);
                  setLoadButton(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color={"success"}
                onClick={() => {
                  setUpgrade(false);
                  setLoadButton(false);
                  navigate("/organization");
                }}
              >
                Upgrade
              </Button>
            </>
          }
        />
      </Grid>
    );
  else return showLoadingScreen();
};

export default withStyles(styles)(Dashboard);
