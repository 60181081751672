import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import Button from "@mui/material/Button";
import drift from "react-driftjs";
import "./stylesheet/helpDialog.css";
import ProjectStore from "../ProjectCanvas/ProjectStore";

class HelpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  redirectToDocs = () => {
    //use production for prod docs
    let url = "https://d19qhpu3oxg7wh.cloudfront.net/";
    window.open(url, "_blank");
  };
  redirectToCalendar = () => {
    let url = "https://cal.com/workflow86/30min";
    window.open(url, "_blank");
  };
  redirectToLiveChat = () => {
    window.intercom
  };
  triggerProductTour = () => {
    this.props.close();
    let url = window.location.pathname;
    let n = url.includes("project");
    if (url.includes("canvas")){
      ProjectStore.openCanvasPageTour();
    }
    else if (url.includes("project")){
      ProjectStore.openProjectPageTour();
    } else if (url.includes("marketplace")) {
      ProjectStore.openMarketplacePageTour();
    } else if (url.includes("portal")) {
      ProjectStore.openPortalPageTour();
    }else if (url.includes("credential_store")) {
      ProjectStore.openCredentialPageTour();
    }
    else if (url.includes("repository")) {
      ProjectStore.openRepositoryPageTour()
    }
    else{
      ProjectStore.openWelcomePageTour();
    }
    
    
  };
  handleClose = () => {
    this.props.close();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        close={this.state.handleClose}
        className={"help-dialog"}
      >
        <DialogTitle>
          <h5>How can we help?</h5>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"row"} wrap={"nowrap"}>
            <Card className={"help-card"}>
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h5 className={"help-title"}>Read the docs</h5>
                <CardMedia
                  className={"help-image-css"}
                  image={"/images/helpDocs.png"}
                  title={"Documentation"}
                />
                <p className={"s-text help-paragraph"}>
                  Read our documentation which contains detailed guides
                  about how to use each part of the platform.{" "}
                </p>
              </Grid>
              <Grid item className={"help-select"}>
                <Button  variant={"contained"}  color={"primary"} onClick={this.redirectToDocs}>
                  Select
                </Button>
              </Grid>
            </Card>
            <Card className={"help-card"}>
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h5 className={"help-title"}>Chat with someone</h5>
                <CardMedia
                  className={"help-image-css"}
                  image={"/images/helpChat.png"}
                  title={"Live Chat"}
                />
                <p className={"s-text help-paragraph"}>
                  Chat with someone from our team using our live chat messenger.{" "}
                </p>
              </Grid>
              <Grid item className={"help-select"}>
                <Button variant={"contained"}  color={"primary"} id="chat_with_someone" >
                  {" "}
                  Select
                </Button>
              </Grid>
            </Card>
            <Card className={"help-card"}>
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h5 className={"help-title"}>Book a 1:1 session</h5>
                <CardMedia
                  className={"help-image-css"}
                  image={"/images/undraw_Designer_by46.png"}
                  title={"Live Chat"}
                />
                <p className={"s-text help-paragraph"}>
                  Book a 1:1 session with our team. We can answer questions or
                  guide you through how to build a workflow to your
                  requirements.
                </p>
              </Grid>
              <Grid item className={"help-select"}>
                <Button variant={"contained"}  color={"primary"} onClick={this.redirectToCalendar}>
                  Select
                </Button>
              </Grid>
            </Card>
            <Card className={"help-card"}>
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h5 className={"help-title"}>Run the product tour</h5>
                <CardMedia
                  className={"help-image-css"}
                  image={"/images/undraw_active_support_6rwo.png"}
                  title={"Live Chat"}
                />
                <p className={"s-text help-paragraph"}>
                  Run (or re-run) the product tour which will guide you through all the essential parts of the platform.
                </p>
              </Grid>
              <Grid item className={"help-select"}>
                <Button variant={"contained"}  color={"primary"} onClick={this.triggerProductTour}>
                  Select
                </Button>
              </Grid>
            </Card>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color={"primary"}  onClick={this.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default HelpDialog;
