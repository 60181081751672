import React from "react";
import { Fade, Grid, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ContextChips from "./ContextChips.jsx";
import "./initialMessages.css";
import { Settings, Storage, VpnKey } from "@mui/icons-material";

const WorkflowEditorMessage = ({ classes }) => {
    const contextChips = [
        {
            label: "All settings and components in this workflow",
            tooltip: "I will have access to the entire workflow, including all components and settings. I can modify any part of the workflow as needed.",
            icon: <Settings fontSize="small" />
        },
        {
            label: "Active and connected databases",
            tooltip: "I am aware of any databases actively being used by a component in this workflow, or connected but not actively used. I can choose to use these databases if appropriate. I will try to reuse an existing database before creating a new one if possible.",
            icon: <Storage fontSize="small" />
        },
        {
            label: "Secrets and credentials",
            tooltip: "I will be aware of any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
            icon: <VpnKey fontSize="small" />
        }
    ];

    return (
        <Fade in timeout={1000}>
            <div className={classes.gpt}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="messagePadding">
                        <Typography>
                            <span className="bold">✍️ Edit this workflow</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="messagePadding">
                        <Typography>
                            Describe the changes you would like to make to the current workflow
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="messagePadding">
                        <Typography>
                            <b>ℹ️ Context</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="messagePadding">
                        <Typography>
                            I will have access to the following context when editing the workflow:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="messagePadding">
                        <ContextChips chips={contextChips} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
};

WorkflowEditorMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(AIBuilderStyles())(WorkflowEditorMessage);
