import React, { useContext, useState, useEffect } from "react";

// Util
import { Handle } from "reactflow";
import { send_request } from "../../../../../utils/Request";

// Canvas
import { CanvasContext } from "../../WorkflowCanvas";

// MUI
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Error } from "@mui/icons-material";
import {ComponentNodeBodyView} from "./ComponentNodes/ComponentNodeUtil/ComponentNodeBodyView";
import NodeSettingsMenuBar from "./ComponentNodes/ComponentNodeUtil/NodeSettingsMenuBar";

const styles = () => ({
    nodeInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "74px",
    },
    settings: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "rgba(64, 64, 64, 1)",
        borderRadius: "8px",
        height: "40px",
    },
    nodeWrapper: {
        width: "250px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    node: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    rmvMargin: {
        margin: "0px!important",
        fontSize: "12px",
    },
    port: {
        position: "relative !important",
        transform: "unset !important",
        right: "unset",
        height: "15px",
        width: "15px",
        backgroundColor: "#868686",
        " &:hover": {
            backgroundColor: "#2196f3",
        },
        " &:active": {
            backgroundColor: "#2196f3",
        },
    },
    filler: {
        width: "15px",
    },
    title: {
        fontSize: "8px",
    },
    nodeInfoBackground: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: "8px",
        marginTop: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    downsize: {
        height: "16px",
    },
    selectedText: {
        color: "#2196F3",
    },
    selectedIcon: {
        filter: "drop-shadow(6px 6px 0px rgba(0, 0, 0, 0.25))",
    },
    centerGrid: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    labelText: {
        maxWidth: "250px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});
//modes allow different buttons to be displayed under different nodes

const DBNode = ({ id, data, isConnectable, classes, selected }) => {
    const getDatabaseName = async () => {
        let url = `database/get_database_name/${id}`;
        const json = await send_request(url, null, null, "GET");
        if (json && json.data) {
            return json.data;
        }
    };


    useEffect(() => {
        if (data.label === "Database") {
            getDatabaseName().then((res) => {
                data.label = res;
            });
        }
    }, [data]);
    {
        //use context to grab and set to diagram
        const { edit } = useContext(CanvasContext);
        const [editComponent, setEditComponent] = edit ? edit : useState({});

        const openInNewTab = () => {
            if (editComponent)
                //if component pane is open
                setEditComponent(null); //we unmount the component which triggers a save, closes the pane and dumps observables

            //Redirect to Component Page Url
            window.open(`${data.newTabUrl}/${id}`, "_blank");
        };

        const nodeSettingsMenuItems = [
            {
                title: "Open database in a new tab",
                icon: "open_in_new",
                onClick: openInNewTab
            },
        ]

        return (
            <div
                draggable={false}
                onDragOver={(event) => {
                    event.preventDefault();
                }}
            >
                <div className={classes.nodeWrapper}>
                    {data.error && <Error className={`error-text ${classes.downsize}`}></Error>}
                    <div className={classes.node}>
                        <ComponentNodeBodyView
                            baseColor={"#FFFFFF"}
                            data={data}
                            classes={classes}
                        />
                    </div>
                </div>
                <NodeSettingsMenuBar
                    selected={selected}
                    classes={classes}
                    data={data}
                    actions={nodeSettingsMenuItems}
                />
                <Handle type={"target"} position="top" isConnectable={true} style={{ display: "none" }} />
                <Handle type={"source"} position="top" isConnectable={true} style={{ display: "none" }} />
            </div>
        );
    }
};
export default withStyles(styles)(DBNode);
