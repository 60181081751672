import {action, computed, decorate, observable, toJS} from "mobx";
import {v4 as uuidv4} from "uuid";
import {DemoJson} from "./Components/AceEditorPlaceholder";

const mode = "CURL";

class APIResReqStore {
  constructor() {
    this.initialiseRequestEmpty();
    this.initialiseSampleEmpty();
    this.initialiseResponseEmpty();
  }

  setRequestTemplate = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.requestData = component.componentData.requestData;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
      if(!this.requestData.setupMode){
        this.requestData.setupMode = mode
      }
    } else {
      this.requestData = {
        request_type: "POST",
        data_type: "JSON",
        endpoint_url: "",
        body: DemoJson,
        headers: [],
        curl: "",
        setupMode: mode,
        skipSample: true,
        rawResponsePlaceholderName: "apiResponse" + Math.floor(1000 + Math.random() * 9000),
      };
      this.lastModified = "";
      this.name = "";
    }
  };

  updateRequestData(data) {
    if (data && data.curl) {
      this.requestData.curl = data.curl;
      this.requestData.setupMode = data.setupMode;
      this.requestData.skipSample = data.skipSample;
    }
  }

  setSampleTemplate = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.sampleData = component.componentData.sampleData;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
      if(!this.sampleData.setupMode){
        this.sampleData.setupMode = mode
      }
    } else {
      this.sampleData = {
        request_type: "POST",
        data_type: "JSON",
        endpoint_url: "",
        body: DemoJson,
        headers: [],
        curl: "",
        setupMode: mode,
      };
    }
  };

  updateSampleData(data) {
    if (data) {
      this.sampleData.curl = data.curl;
      this.sampleData.setupMode = data.setupMode;
    }
  }

  setRequestType = (requestType) => {
    this.requestData.request_type = requestType;
  };

  setDataType = (dataType) => {
    this.requestData.data_type = dataType;
  };

  setEndPointUrl = (endPointUrl) => {
    this.requestData.endpoint_url = endPointUrl;
  };

  setSampleRequestType = (requestType) => {
    this.sampleData.request_type = requestType;
  };

  setSampleDataType = (dataType) => {
    this.sampleData.data_type = dataType;
  };

  setSampleEndPointUrl = (endPointUrl) => {
    this.sampleData.endpoint_url = endPointUrl;
  };

  setSampleApiReqBody = (Body) => {
    this.sampleData.body = Body;
  };

  setRequestName = (name) => {
    this.name = name;
  };

  setApiReqBody = (Body) => {
    this.requestData.body = Body;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };

  setSkipSample = (skipSample) => {
    this.requestData.skipSample = skipSample;
  };

  isSkipSample = () => {
    return this.requestData.skipSample;
  };

  headerField = () => {
    return {
      header_id: uuidv4(),
      header_name: "",
      header_value: "",
    };
  };

  addCustomHeaders = () => {
    const newHeader = this.headerField();
    this.requestData.headers.push(newHeader)
  }

  addSampleCustomHeaders = () => {
    const newHeader = this.headerField();
    this.sampleData.headers.push(newHeader)
  }

  setEndpointPlaceholders = (placeholders) => {
    this.endpointPlaceholders = placeholders;
  }

  storeError = (obj) => {
    this.placeholderError = obj;
  }

  initialiseRequestEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.componentId = "";
    this.type = "request";
    this.lastModified = "";
    this.data = obj;
    this.endpointPlaceholders = [];
    this.requestData = {
      request_type: "POST",
      data_type: "JSON",
      endpoint_url: "",
      body: "",
      headers: [],
      curl: "",
      setupMode: mode,
      skipSample: true,
      rawResponsePlaceholderName: "apiResponse" + Math.floor(1000 + Math.random() * 9000),
    };
    this.placeholderError = []
  };

  initialiseSampleEmpty = () => {
    this.sampleData = {
      request_type: "POST",
      data_type: "JSON",
      endpoint_url: "",
      body: "",
      headers: [],
      curl: "",
      setupMode: mode,
    };
  };

  initialiseResponseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "response";
    this.lastModified = "";
    this.responseData = obj;
    this.error = false;
    this.json = ""
  };

  deleteCustomHeader = (placeholder) =>{
    let placeholders = toJS(this.requestData.headers);
    let index = placeholders.findIndex((x) => x.key === placeholder.key);
    this.requestData.headers.splice(index, 1);
  }

  get requestTemplate() {
    return {
      componentId: this.componentId,
      name: this.name,
      requestData: toJS(this.requestData),
      type: this.type,
      lastModified: this.lastModified,
      endpointPlaceholders: toJS(this.endpointPlaceholders)
    };
  }

  get sampleTemplate() {
    return {
      componentId: this.componentId,
      name: this.name,
      sampleData: toJS(this.sampleData),
      type: this.type,
      lastModified: this.lastModified,
    };
  }

  /*-------------------------------------------------------------------------------------------------------------*/
  /*-------------------------------------------------------------------------------------------------------------*/
  /*-------------------------------------------------------------------------------------------------------------*/

  setResponseTemplate = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.responseData = component.componentData.responseData;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;

    } else {
      const obj = this.componentData();
      this.responseData = obj;
      this.lastModified = "";
      this.name = "";
    }
  };

  keyAuto = () => {
    return "api" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };

  setField = (value, index, type) => {
    switch (type) {
      case "sample":
        this.responseData.json = value;
        return;
      case "key":
        this.responseData[index].key = value;
        return;
      case "type":
        this.responseData.type = value;
        this.responseData.samplePlaceholders = [];
        return;
      case "content":
        this.responseData[index].content = value;
        return;
    }
  };

  componentData = () => {
    const obj = this.dataField();
    return {
      type: "MANUALLY",
      json: "",
      samplePlaceholders: [
        obj
      ],
      placeholders: [

      ],
      timeout: 120
    };
  };

  dataField = () => {
    return {
      key: this.keyAuto(),
      sampleKey: "",
      sampleData: "",
      checked: true
    };
  };


  addRow = () => {
    const obj = this.dataField();
    this.responseData.samplePlaceholders.push(obj);
  };

  jsonData = (key, value) => {
    return {
      key: key,
      sampleKey: key,
      sampleData: value,
      checked: true
    };
  };

  addJsonData = (obj) => {
    this.responseData.samplePlaceholders.push(obj);
  };

  clearField = () => {
    this.responseData.samplePlaceholders = [];
  };

  deleteRow = (placeholder) => {
    let placeholders = toJS(this.responseData.samplePlaceholders)
    let index = placeholders.findIndex(x => x.key === placeholder.key);
    this.responseData.samplePlaceholders.splice(index, 1);
  }

  removeKeyInPlaceholder = (placeholderKey) => {
    let placeholders = toJS(this.responseData.placeholders)
    let index = placeholders.findIndex(x => x.key === placeholderKey);
    this.responseData.placeholders.splice(index, 1);
  }

  addKeyInPlaceholder = (placeholderKey, placeholderType) => {
    const placeholderObject = {
      key: placeholderKey,
      type: placeholderType,
      length: 1
    }
    this.responseData.placeholders.push(placeholderObject);
  }

  addComponentData = () => {
    const obj = this.componentData();
    this.responseData.push(obj);
  };

  deleteComponent = (index) => {
    this.responseData = this.data.splice(index, 1);
  };

  onChangeField = (value,placeholder,type) => {
    let placeholders = toJS(this.responseData.samplePlaceholders)
    let index = placeholders.findIndex(x => x.key === placeholder.key);
    switch (type) {
      case "content":
        this.responseData.samplePlaceholders[index].sampleKey = value;
        this.responseData.samplePlaceholders[index].sampleData = "";
        return;
      case "json" :
        this.responseData.json = value
        return;
      case "key" :
        this.responseData.samplePlaceholders[index].key = value;
        return;
      case "timeout" :
        this.responseData.timeout = value
    }
  }

  clearField = () => {
    this.responseData.samplePlaceholders = []
  }

  get responseTemplate() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      responseData: toJS(this.responseData),
      type: this.type,
      lastModified: this.lastModified,
      json: this.json,
    };
  }

  setAPIName = (name) => {
    this.name = name;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };

  setSetupRequestMode = (mode) => {
    this.requestData.setupMode = mode;
  }

  setSetupSampleMode = (mode) => {
    this.sampleData.setupMode = mode;
  }

  setRequestCurl = (curl) => {
    this.requestData.curl = curl;
  }

  setSampleCurl = (curl) => {
    this.sampleData.curl = curl;
  }

  setRawResponsePlaceholder = (placeholderName) => {
    this.requestData.rawResponsePlaceholderName = placeholderName;
  }
}
decorate(APIResReqStore, {
  componentId: observable,
  position: observable,
  name: observable,
  responseData: observable,
  requestData: observable,
  sampleData: observable,
  json: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  placeholderError: observable,
  setComponentName: action,
  addComponentData: action,
  setComponentData: action,
  setLastModified: action,
  setField: action,
  setFocus: action,
  setRequestTemplate: action,
  setRequestType: action,
  setDataType: action,
  setEndPointUrl: action,
  setSampleTemplate: action,
  setSampleRequestType: action,
  setSampleDataType: action,
  setSampleEndPointUrl: action,
  setRequestName: action,
  setApiReqBody: action,
  setSampleApiReqBody: action,
  setRawResponsePlaceholder: action,
  deleteComponent: action,
  initialiseEmpty: action,
  requestTemplate: computed,
  responseTemplate: computed,
  sampleTemplate: computed,
  endpointPlaceholders: observable,
  setEndpointPlaceholders: action,
  setSetupMode: action,
  setCurl: action,
});

const apiResReq = new APIResReqStore();
export default apiResReq;
