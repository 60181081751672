import React, { useState, useEffect } from "react";

// MUI
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
// Util
import QuestionAnswer from "../StaticMessages/QuestionAnswer";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
  gridItem: {
    marginBottom: "16px",
  },
});

function QuestionAnswerMessage({
  content,
  historyAnswers,
  prebuildQuestionAnswers,
  setPrebuildQuestionAnswers,
  messages,
  classes,
  dismissContainerRef,
}) {
  const [disabled, setDisabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (disabled) return; // If these are disabled, don't do anything

    // Take a look at the historyAnswers
    // & if they're provided, disable editing
    if (historyAnswers) {
      setDisabled(true);
      return;
    }

    // If it's the first load, skip the initial check of loading
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    setDisabled(true);
  }, [messages.length]);

  return (
    <Grid className={`${classes.gpt} message-bubble`}>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <b>❓ Additional questions</b>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          {content.comment ? (
            content.comment
          ) : (
            <span>
              I need some more information so I can provide my best response:
            </span>
          )}
        </Grid>
        <Grid item xs={12}>
          {content.questions && (
            <Grid container rowGap={2}>
              {content.questions.map((question, idx) => {
                return (
                  <QuestionAnswer
                    index={idx}
                    key={idx}
                    question={question}
                    disabled={disabled}
                    prebuildQuestionAnswers={prebuildQuestionAnswers}
                    setPrebuildQuestionAnswers={setPrebuildQuestionAnswers}
                    historyAnswers={historyAnswers}
                    dismissContainerRef={dismissContainerRef}
                  />
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

QuestionAnswerMessage.propTypes = {
  content: PropTypes.object.isRequired,
  historyAnswers: PropTypes.array,
  prebuildQuestionAnswers: PropTypes.array.isRequired,
  setPrebuildQuestionAnswers: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  dismissContainerRef: PropTypes.object,
};

export default withStyles(styles)(QuestionAnswerMessage);
