import React, { useState, useEffect } from 'react';

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { CircularProgress, Tooltip } from "@mui/material";

import "../stylesheet/dashboardPage.css";

import { send_request } from "../../../utils/Request";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import "../Achievement/AchievementStyle.css";

function TimeSavedCard() {

  const [loading, setLoading] = useState(true);
  const [minutesSaved, setMinutesSaved] = useState(null);
  const [daysSaved, setDaysSaved] = useState(null);
 
  useEffect(() => {
    // Make API call to get count
    send_request("authz-service/clientstats/get-client-session-data", "", "", "get")
		.then((res) => {	
			let totalComponentSessionRun = res.data.totalComponentSessionRun;
			let minsSaved = calculateMinutesSaved(totalComponentSessionRun)

			setMinutesSaved(minsSaved);
			setDaysSaved(calculateDaysSaved(minsSaved));
			setLoading(false);
		})
		.catch((err) => {
			console.log(err);
      setMinutesSaved(0);
      setDaysSaved(0);
      setLoading(false);
		})
  }, [])

  const calculateMinutesSaved = (total) => {
      return  (total * 5);
  }

  const calculateDaysSaved = (minsSaved) => {
    let days = minsSaved / 480;
    return Math.round(days * 10) / 10;
  }

  let minutesSavedComponent = loading ? (<span><CircularProgress size={24}/></span>) : (minutesSaved);
  let daysSavedComponent = loading ? (<span style={{ margin: "0px 5px" }}><CircularProgress size={8}/></span>) : (daysSaved);

  let data = (<Card className="cardStyleTimeSaved">
        <CardContent style={{ padding: "32px 32px 0px 32px" }}>
          <Typography
            variant="h4"
            component="p"
            className="cardHeader">
            Your team has saved
          </Typography>
        </CardContent>
        <CardMedia
          className="cardImage"
          image="../images/undraw_Good_team.png" />
        <CardContent className="textContent">
          <FadeIn transitionDuration="1000">
            <Typography
              variant="h0"
              component="p"
              className="activeNumber">
              {minutesSavedComponent.toLocaleString()}
            </Typography>
          </FadeIn>
          <Typography className="cardDescription">
            minutes saved by using Workflow86 <br/>
            That is equivalent to {daysSavedComponent.toLocaleString()} working days
          </Typography>
        </CardContent>
      </Card>);


  return data;
}

export default TimeSavedCard;
