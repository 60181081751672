import React, {useCallback, useState} from "react";

// Util
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import MapAZap from "../../ProjectCanvas/MapAZap/MapAZap";
import HideNotesIcon from "../../../utils/Icons/HideNotesIcon";
import {edgeTypes, proOptions, CANVAS_MIN_ZOOM_OUT, DEFAULT_VIEWPORT, isValidConnection, extractEdges, extractNodes, CanvasMinimap} from "../../../utils/CanvasUtil";
import ReactFlow, { Background, Controls, ControlButton, ConnectionMode, applyNodeChanges, applyEdgeChanges, useOnSelectionChange,useStoreApi} from "reactflow";
import {applyDagreLayouting} from "./AIBuilder/util/TranslateUtil";
import ConnectionLine from "./ReactFlowUtil/CustomEdges/ConnectionLine";


// MUI
import { Description } from "@mui/icons-material";
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { Tooltip } from "@mui/material";
import ArrangeIcon from "../../../utils/Icons/ArrangeIcon";
import {draftNodeTypes, prodNodeTypes} from "./ReactFlowUtil/CustomNodes/ComponentNodeTypes";

const CanvasWithRFInstance = inject("CanvasStore")(
    observer((props) => {
        const { CanvasStore } = props; // extract canvas store
        const { draggingNodes, edges, nodes } = CanvasStore;
        const store = useStoreApi();
        const { addSelectedNodes,resetSelectedElements, getNodes, setNodes} = store.getState();

        const onNodesChange = useCallback((changes) => {
            if (changes[0].type === "select") {
                CanvasStore.setNodesFromDraggingNodesFn((ns) => applyNodeChanges(changes, ns));
                return;
            }

            CanvasStore.setDraggingNodesFn((ns) => applyNodeChanges(changes, ns));
            return;
        }, []);

        const connectionStatus = useCallback(
            (connection) => {
              return isValidConnection(connection, draggingNodes, edges);
            },
            [draggingNodes, edges],
          );
  

        const onEdgesChange = useCallback((changes) => {
            CanvasStore.setEdgesFn((es) => applyEdgeChanges(changes, es));
            return;
        }, []);

        return (
            <ReactFlow
                onNodeClick={(event, node) => {
                  props.handleOnNodeClick(event, node);
                }}
                connectionMode={ConnectionMode.Loose}
                onConnect={props.onConnect}
                nodes={draggingNodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={draftNodeTypes}
                edgeTypes={edgeTypes}
                onDrop={props.onDrop}
                onInit={props.onInit}
                onNodeDragStop={() => {
                    // Sync the dragging nodes with the nodes on drag stop
                    CanvasStore.syncNodes();
                }}
                onNodeMouseEnter={(event,node) => {
                    props.handleOnNodeMouseEnter(node,addSelectedNodes);
                }}
                onNodeMouseLeave={(event,node) => {
                    props.handleOnNodeMouseLeave(node,resetSelectedElements, getNodes, setNodes);

                }}
                onSelectionDragStop={() => {
                    // Sync the dragging nodes with the nodes on selection drag stop
                    CanvasStore.syncNodes();
                }}
                onSelectionChange={ (selection) => {
                    props.handleSelectionChange(selection, true);
                }}
                onSelectionContextMenu={props.handleSelectionContextMenu}
                onSelectionStart={props.handleSelectionStart}
                onSelectionEnd={props.handleSelectionEnd}
                onPaneClick={props.handleOnPaneClick}
                arrowHeadColor={"#868686"}
                onDragOver={props.onDragOver}
                selectNodesOnDrag={false}
                elementsSelectable={!props.canvasLocked}
                nodesDraggable={!props.canvasLocked}
                minZoom={CANVAS_MIN_ZOOM_OUT}
                defaultViewport={props.viewport ? props.viewport : DEFAULT_VIEWPORT}
                nodeDragThreshold={5}
                proOptions={proOptions}
                deleteKeyCode={null}
                isValidConnection={connectionStatus}
                zoomOnDoubleClick={false}
                connectionLineComponent={ConnectionLine}
                snapToGrid={true}
                snapGrid={[25,25]}
            >
                <Background variant="dots" gap={16} color="#868686" />
                <Controls className={props.classes.controls}>
                    <Tooltip title={"Auto Arrange"} placement={"right-end"}>
                        <span>
                            <ControlButton
                                onClick={(event) => {                                  
                                    const applyDagreResult = applyDagreLayouting(nodes,edges);
                                    CanvasStore.setNodesAndEdges(applyDagreResult);
                                }}
                            >
                                <ArrangeIcon/>
                            </ControlButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={"Toggle Minimap"} placement={"right-end"}>
                        <span>
                            <ControlButton
                                onClick={(event) => {                                  
                                    props.setShowMinimap(!props.showMinimap);
                                }}
                            >
                                <BrandingWatermarkIcon/>
                            </ControlButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={props.showNotes ? "Hide Notes" : "Show Notes"} placement={"right-end"}>
                        <span>
                            <ControlButton
                                onClick={(event) => {
                                    props.setShowNotes(!props.showNotes);
                                }}
                            >
                                {props.showNotes ? <HideNotesIcon /> : <Description />}
                            </ControlButton>
                        </span>
                    </Tooltip>
                </Controls>
                <MapAZap showMapAZap={props.showMapAZap} setShowMapAZap={props.setShowMapAZap} position={props.mapAZapPosition} />
                {props.showMinimap && (
                    <CanvasMinimap/>

                )}
            </ReactFlow>
        );
    })
);

export default CanvasWithRFInstance;
