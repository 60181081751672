import React, { useContext, useState, useRef, useCallback, useEffect } from "react";

// Util
import { Handle } from "reactflow";
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
import { hexToPastel } from "../../../../../../utils/HexToPastelUtil";

// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";

// Custom components
import NodeDescription from "../../../NodeDescription";
import NodeSettingsMenuBar from './ComponentNodeUtil/NodeSettingsMenuBar';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';

// MUI
import { Tooltip, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

// Icons
import Error from "@mui/icons-material/Error";

// MobX
import { inject, observer } from "mobx-react";
import InstructionsInfo from "../../../InstructionInfo";
import { COMPONENT_ICON_ALT } from "../../../../../../utils/CanvasConstants";

import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";

import { CAN_OPEN_IN_NEW_TAB } from "../../../../../../utils/CanvasConstants";

const VersionCanvasComponent = inject("CanvasStore")(
    observer(({ id, data, classes, selected, CanvasStore }) => {

      const { modeInfo } = useContext(CanvasContext);
      if (modeInfo === undefined) {
        return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
      }

      const parentRef = useRef(null);
      const ref = useRef(null);
      const { nodes } = CanvasStore;
      const { edit } = useContext(CanvasContext);


      // State hooks
      const [editComponent, setEditComponent] = edit ? edit : useState({});
      const [isSelectedComponent, setIsSelectedComponent] = useState(false);
      const [svgSelectedClass, setSvgSelectedClass] = useState("");
      const hasInput = data.hasInput;
      const hasOutput = data.hasOutput;
      const [mode, setMode] = modeInfo;

      // Effect hooks
      useEffect(() => {
        const dataComponentId = (data && data.componentId) ? data.componentId : id;
        setIsSelectedComponent((editComponent && dataComponentId === editComponent.componentId));
      }, [editComponent, data, id]);


      useEffect(() => {
        const isTrue = selected || isSelectedComponent;
        setSvgSelectedClass(isTrue ? classes.selectedIcon : "");
      }, [isSelectedComponent, selected, classes.selectedIcon]);



      // Handlers
      const handlePreview = useCallback(() => {
        ProjectStore.state.production = true;
        ProjectStore.state.canvasView = false;
        if (edit && !editComponent) {
          setEditComponent({ ...data, componentId: id });
        }
      }, [edit, editComponent, data, id]);

      const handleInstructionChange = useCallback((newInstruction) => {
        const updatedCanvas = nodes.map((component) =>
            component.id === id
                ? { ...component, data: { ...component.data, instruction: newInstruction } }
                : component
        );
        CanvasStore.setNodes(updatedCanvas);
      }, [nodes, id, CanvasStore]);

      const nodeSettingsMenuItems = [
        {
          title: "View component",
          icon: "preview",
          onClick: handlePreview
        }
      ];

      // Render methods
      return (
          <div
              draggable={false}
              ref={parentRef}
              onDragOver={(event) => {
                event.preventDefault();
              }}
          >
            <div className={classes.nodeWrapper}>
              {data.error && (
                  <Error className={`error-text ${classes.downsize}`}></Error>
              )}
              <ComponentNodeView
                classes={classes}
                data={data}
                selected={selected}
                isSelectedComponent={isSelectedComponent}
                mode={mode}
                editComponent={editComponent}
              />

              <NodeSettingsMenuBar
                  selected={selected}
                  actions={nodeSettingsMenuItems}
                  classes={classes}
                  data={data}
              />

              {data.instructions &&
                  (data.instructions.buildInstruction ||
                      data.instructions.editInstruction) && (
                      <InstructionsInfo
                          data={data}
                          parent={parentRef}
                          textRef={ref}
                      />
                  )}
            </div>
          </div>
      );
    })
);

export default withStyles(styles)(VersionCanvasComponent);
